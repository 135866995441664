import { AssetLog } from '../asset-logs/AssetLog.entity'
import { AssetType } from '../asset-types/AssetType.entity'

export interface CreateBatchAssetDto {
  asset: CreateAssetDto
  quantity: number
}

export interface CreateAssetDto {
  id?: string
  type: Partial<AssetType>
  status: AssetStatus
  currentLocation: AssetLocation
  currentCycle: number
  quantity: number
}

export interface Asset {
  id: string
  reference: string
  type: AssetType
  status: AssetStatus
  currentLocation: CurrentAssetLocation
  currentCycle: number
  quantity: number
  lastAssetLogRegistered: AssetLog
}

export interface CurrentAssetLocation {
  type: AssetLocation
  reference: string
}

export enum AssetLocation {
  xiclo = 'warehouse',
  partner = 'partner',
  customer = 'customer',
  cleaner = 'cleaner',
  repairShop = 'repairShop',
}

export enum AssetStatus {
  Tracked = 'tracked',
  Untracked = 'untracked',
  Lost = 'lost',
  Sold = 'sold',
  Broken = 'broken',
  Terminated = 'terminated',
}

export interface QRFile {
  reference: string
  file: string
}

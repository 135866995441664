import { User } from '../users/User.entity'

export enum PayUState {
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
}

export enum PaymentMethodType {
  CreditCard = 'CARD',
}

export interface PaymentMethod {
  id: string
  brand: string
  type: PaymentMethodType
  cardHolder: string
  expiresAt: Date
  isMain: boolean
  createdAt: string
  lastFourNumbers: string
  bin: string
}

export interface Payment {
  id: string
  amount: number
  orderId: number
  transactionId: string
  paymentNetworkResponseErrorMessage?: string
  paymentNetworkResponseCode: string
  authorizationCode?: string
  pendingReason?: string
  responseCode: string
  state: PayUState
  paymentMethod: PaymentMethod
  user: User
  createdAt: Date
}

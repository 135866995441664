import { Heading } from '@chakra-ui/layout'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import UsersList from '../features/users/UsersList'

const Users = () => {
  return (
    <>
      <Heading mb="5">
        <FormattedMessage
          id="users"
          defaultMessage="Users"
          description="Users screen title"
        />
      </Heading>
      <UsersList />
    </>
  )
}

export default Users

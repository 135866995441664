import { ChakraProvider } from '@chakra-ui/react'
import React, { FC } from 'react'
import { IntlProvider } from 'react-intl'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter as Router } from 'react-router-dom'
import localization from '../lang'
import theme from '../theme'
import AuthProvider from './AuthProvider'

type Locale = 'en' | 'es'

const navigatorLanguage = navigator.language
// Removes cases like "en-US" or "es-CO"
const currentLocale: Locale = navigatorLanguage.substring(
  0,
  navigatorLanguage.indexOf('-')
) as Locale

const queryClient = new QueryClient()

const AppProviders: FC = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <IntlProvider
        messages={localization[currentLocale]}
        locale={currentLocale}
        defaultLocale="en">
        <Router>
          <AuthProvider>
            <ChakraProvider theme={theme}>{children}</ChakraProvider>
          </AuthProvider>
        </Router>
      </IntlProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default AppProviders

import { DownloadIcon } from '@chakra-ui/icons'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal'
import {
  Box,
  Button,
  Divider,
  HStack,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react'
import moment from 'moment'
import React, { useMemo, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Column } from 'react-table'
import ReactToPrint from 'react-to-print'
import { useAssetsByIds } from '../assets/queries'
import SortableDataTable from '../common/SortableDataTable'
import { DeliveryOrder } from './DeliveryOrder.entity'
import TemplatePdfDeliveryOrder from './template/TemplatePdfDeliveryOrder'

export type DeliveryOrderModalProps = {
  deliveryOrder?: Partial<DeliveryOrder> | undefined
  isOpenDeliveryOrder: boolean
  onCloseDeliveryOrder: () => void
}

const DeliveryOrderModal = ({
  deliveryOrder: deliveryOrder,
  isOpenDeliveryOrder,
  onCloseDeliveryOrder,
}: DeliveryOrderModalProps) => {
  const assets = useAssetsByIds(deliveryOrder?.assets?.toString() ?? '')
  const intl = useIntl()
  const { isOpen, onOpen } = useDisclosure()
  const [selectedAssetType, setSelectedAssetType] = useState<any>()
  const assetTypes = getAssetTypes()
  const componentRef = useRef(null)

  const columnsAssets = useMemo<Column<any>[]>(
    () => [
      {
        Header: intl.formatMessage({
          id: 'asset-types.brand',
          description: 'Brand attribute of an asset type',
          defaultMessage: 'Brand',
        }),
        accessor: (assetType) => assetType.type.brand.name,
      },
      {
        Header: intl.formatMessage({
          id: 'asset-types.ticker',
          description: 'Ticker attribute of an asset type',
          defaultMessage: 'Ticker',
        }),
        accessor: (assetType) => assetType.type.ticker,
      },
      {
        Header: intl.formatMessage({
          id: 'asset-types.type',
          description: 'Type attribute of an asset type',
          defaultMessage: 'Type',
        }),
        accessor: (assetType) => assetType.type.type,
      },
      {
        Header: intl.formatMessage({
          id: 'asset-types.containerType',
          description: 'Container type attribute of an asset type',
          defaultMessage: 'Container Type',
        }),
        accessor: (assetType) => assetType.type.containerType,
      },
      {
        Header: intl.formatMessage({
          id: 'asset-types.volume',
          description: 'Volume attribute of an asset type',
          defaultMessage: 'Volume',
        }),
        accessor: (assetType) => assetType.type.volume,
      },
      {
        Header: intl.formatMessage({
          id: 'asset-types.quantity',
          description: 'Quantity attribute of an asset type',
          defaultMessage: 'Quantity',
        }),
        accessor: (assetType) => assetType.quantity,
      },
    ],
    []
  )

  const formatDate = (date: Date) => {
    let d = moment(date)
    return `${d.format('DD/MM/YYYY')} - ${d.format('H:mm:ss')}`
  }

  function getAssetTypes(): object[] {
    let summary = assets?.data?.reduce((group: any, product) => {
      const { id } = product.type
      if (group[id]) {
        group[id].quantity = group[id].quantity + 1
        group[id].references.push(product.reference)
      } else {
        group[id] = {
          type: product.type,
          quantity: 1,
          references: [product.reference],
        }
      }

      return group
    }, {})

    if (summary) {
      return Object.values(summary)
    }

    return []
  }

  const detailAssetType = (assetType: any) => {
    setSelectedAssetType(assetType.references)
    onOpen()
  }

  const onClose = () => {
    setSelectedAssetType(undefined)
  }

  const references = () => {
    return selectedAssetType.map((reference: string) => {
      return <ListItem key={reference}>{reference}</ListItem>
    })
  }

  const namePDF = () => {
    return `${deliveryOrder?.targetBranch?.name}  ${moment(new Date()).format(
      'DD-MM-YY'
    )}`
  }

  return (
    <>
      <Modal
        isOpen={isOpenDeliveryOrder}
        onClose={onCloseDeliveryOrder}
        size={'l'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <FormattedMessage
              id="delivery-orders"
              description="Delivery Orders screen title"
              defaultMessage="Delivery Orders"
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack marginBottom={3}>
              <Box width="200px" padding="2">
                <Text>
                  <FormattedMessage
                    id="delivery-orders.warehouse"
                    description="Warehouse attribute of a Delviery order"
                    defaultMessage="Warehouse"
                  />
                </Text>
              </Box>
              <Box
                border="1px"
                borderRadius="5"
                borderColor="gray.600"
                padding="2"
                flex="1">
                {deliveryOrder?.warehouse?.name}
              </Box>
            </HStack>
            <HStack marginBottom={3}>
              <Box width="200px" padding="2">
                <Text>
                  <FormattedMessage
                    id="delivery-orders.target.branch"
                    description="Target branch attribute of a Delivery Order"
                    defaultMessage="Target branch"
                  />
                </Text>
              </Box>
              <Box
                border="1px"
                borderRadius="5"
                borderColor="gray.600"
                padding="2"
                flex="1">
                {deliveryOrder?.targetBranch?.name}
              </Box>
            </HStack>
            <HStack marginBottom={3}>
              <Box width="200px" padding="2">
                <Text>
                  <FormattedMessage
                    id="delivery-orders.status"
                    description="Status attribute of an Delivery order"
                    defaultMessage="Status"
                  />
                </Text>
              </Box>
              <Box
                border="1px"
                borderRadius="5"
                borderColor="gray.600"
                padding="2"
                flex="1">
                {deliveryOrder?.status}
              </Box>
            </HStack>
            <HStack marginBottom={3}>
              <Box width="200px" padding="2">
                <Text>
                  <FormattedMessage
                    id="delivery-orders.signedby.user.signedBy"
                    description="Signed by attribute of a delivery order"
                    defaultMessage="Signed by"
                  />
                </Text>
              </Box>
              <Box
                border="1px"
                borderRadius="5"
                borderColor="gray.600"
                padding="2"
                flex="1"
                height={10}>
                {deliveryOrder?.signedBy?.name}
              </Box>
              <Box
                border="1px"
                borderRadius="5"
                borderColor="gray.600"
                padding="2"
                flex="1"
                height={10}>
                {deliveryOrder?.signedBy?.email}
              </Box>
            </HStack>
            <HStack marginBottom={3}>
              <Box width="200px" padding="2">
                <Text>
                  <FormattedMessage
                    id="delivery-orders.createdAt"
                    description="CreatedAt attribute of an delivery order"
                    defaultMessage="Created"
                  />
                </Text>
              </Box>
              <Box
                border="1px"
                borderRadius="5"
                borderColor="gray.600"
                padding="2"
                flex="1">
                {formatDate(deliveryOrder?.createdAt!)}
              </Box>
            </HStack>
            <HStack marginBottom={3}>
              <Box width="200px" padding="2">
                <Text>
                  <FormattedMessage
                    id="delivery-orders.updatedAt"
                    description="Updated attribute of an delivery order"
                    defaultMessage="Updated"
                  />
                </Text>
              </Box>
              <Box
                border="1px"
                borderRadius="5"
                borderColor="gray.600"
                padding="2"
                flex="1">
                {formatDate(deliveryOrder?.updatedAt!)}
              </Box>
            </HStack>
            <Stack spacing="3">
              <Box
                border="1px"
                borderRadius="5"
                borderColor="gray.600"
                padding="2"
                flex="1">
                <Box flex="1" textAlign="left">
                  <Text fontSize="2xl" align={'center'} marginBottom={2}>
                    <FormattedMessage
                      id="assets"
                      description="Assets title"
                      defaultMessage="Assets"
                    />
                  </Text>
                  <Divider />
                </Box>
                <SortableDataTable
                  data={assetTypes ?? []}
                  columns={columnsAssets}
                  currentPage={0}
                  paginationAuto={false}
                  totalCount={2}
                  enableFilters={false}
                  onRowSelected={detailAssetType}
                />
              </Box>
            </Stack>
            <Box flex="1" textAlign="center">
              <ReactToPrint
                documentTitle={namePDF()}
                trigger={() => (
                  <Button leftIcon={<DownloadIcon />} colorScheme="teal" my="3">
                    <FormattedMessage
                      id="delivery-orders.download.order"
                      description="Download orders label"
                      defaultMessage="Download order"
                    />
                  </Button>
                )}
                content={() => componentRef.current}
              />
              <Box hidden>
                <TemplatePdfDeliveryOrder
                  innerRef={componentRef}
                  warehouse={deliveryOrder?.warehouse?.name}
                  targetBranch={deliveryOrder?.targetBranch?.name}
                  assetTypes={assetTypes}
                  id={deliveryOrder?.id}
                  createdAt={deliveryOrder?.createdAt}
                />
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      {selectedAssetType ? (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <FormattedMessage
                id="delivery-orders.references"
                description="References title of a delivery order"
                defaultMessage="References"
              />
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody marginBottom={5}>
              <UnorderedList>{references()}</UnorderedList>
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : null}
    </>
  )
}

export default DeliveryOrderModal

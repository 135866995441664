import { useDisclosure } from '@chakra-ui/hooks'
import React, { useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router'
import { Column } from 'react-table'
import { useBranchesList } from '../branches/queries'
import { Filter } from '../common/Filter.entity'
import SortableDataTable, {
  NumberColumnFilter,
} from '../common/SortableDataTable'
import EditUserModal from './EditUserModal'
import { useUsersCount, useUsersList } from './queries'
import { User } from './User.entity'

const UsersList = () => {
  const [filters, setFilters] = useState<Filter[]>([])
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)

  const { data, isLoading } = useUsersList(filters, currentPage, pageSize)
  const userCountQuery = useUsersCount(filters)

  const branchesList = useBranchesList([], 0, 50)

  const location: any = useLocation()
  const { isOpen, onOpen } = useDisclosure()
  const [selectedUser, setSelectedUser] = useState<User>()
  const intl = useIntl()

  const setPage = useCallback(
    (pageIndex: number, pageSize: number) => {
      setCurrentPage(pageIndex)
      setPageSize(pageSize)
    },
    [currentPage, pageSize]
  )

  const columns = useMemo<Column<User>[]>(
    () => [
      {
        Header: intl.formatMessage({
          id: 'users.email',
          description: 'Email user',
          defaultMessage: 'Email',
        }),
        accessor: 'email',
      },
      {
        Header: intl.formatMessage({
          id: 'users.name',
          description: 'Name user',
          defaultMessage: 'Name',
        }),
        accessor: 'name',
      },
      {
        Header: intl.formatMessage({
          id: 'users.numAssets',
          description: 'Number of assets currently held by a user',
          defaultMessage: '# of Assets',
        }),
        Filter: NumberColumnFilter,
        accessor: (user) => user.assets.length,
        id: 'numAssets',
      },
      {
        Header: intl.formatMessage({
          id: 'users.createdAt',
          description: 'Date in which the user was registered',
          defaultMessage: 'Registration Date',
        }),
        accessor: (user) => {
          const d = new Date(user.createdAt)
          return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
        },
        id: 'createdAt',
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  )

  const editUser = (user: User) => {
    setSelectedUser(user)
    onOpen()
  }

  const onClose = () => {
    setSelectedUser(undefined)
  }

  const getStateFilters = () => {
    const email = location?.state?.email
    return email ? [{ id: 'email', value: email }] : []
  }

  return (
    <>
      <SortableDataTable
        data={data?.data ?? []}
        columns={columns}
        setPage={setPage}
        setFilters={setFilters}
        loading={isLoading}
        currentPage={currentPage}
        onRowSelected={editUser}
        totalCount={userCountQuery.data}
        sizePage={pageSize}
        fontSize="smaller"
        paginationAuto={true}
        filterAuto={true}
        filtersInit={getStateFilters()}
        enableFilters
      />
      {selectedUser ? (
        <EditUserModal
          user={selectedUser}
          branches={branchesList.data?.data ?? []}
          isOpen={isOpen}
          onClose={onClose}
        />
      ) : null}
    </>
  )
}

export default UsersList

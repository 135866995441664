import { useQuery } from 'react-query'
import { Filter } from '../common/Filter.entity'
import PaginatedResult from '../common/PaginatedResult.entity'
import useXicloClient, { XicloResource } from '../common/useXicloClient'
import { Pickup } from './Pickup.entity'

export const pickupsKeys = {
  all: ['pickups'] as const,
  count: (filters: Filter[]) =>
    [...pickupsKeys.all, 'count', { filters }] as const,
  lists: () => [...pickupsKeys.all, 'list'] as const,
  list: (filters: Filter[], page: number, limit: number) =>
    [...pickupsKeys.lists(), { filters, page, limit }] as const,
}

const findAll = async (filters: Filter[], page: number, limit: number) => {
  const offset = limit * page

  let params: { [key: string]: any } = {
    limit,
    offset,
  }

  filters.forEach(({ id, value }) => {
    params[id] = value
  })

  const client = await useXicloClient(XicloResource.Pickups)
  const { data } = await client.get<PaginatedResult<Pickup>>('/all', {
    params,
  })

  return data
}

export const usePickupsList = (filters: Filter[], page: number, limit = 10) => {
  return useQuery<PaginatedResult<Pickup>, Error>(
    pickupsKeys.list(filters, page, limit),
    () => findAll(filters, page ?? 0, limit),
    { keepPreviousData: true }
  )
}

const count = async (filters: Filter[]) => {
  let params: { [key: string]: any } = {}

  filters.forEach(({ id, value }) => {
    params[id] = value
  })

  const client = await useXicloClient(XicloResource.Pickups)
  const { data } = await client.get<number>('/count', {
    params,
  })

  return data
}

export const usePickupCount = (filters: Filter[]) => {
  const countAllQuery = useQuery<number, Error>(
    pickupsKeys.count(filters),
    () => count(filters)
  )

  return countAllQuery
}

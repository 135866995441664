import { useDisclosure } from '@chakra-ui/react'
import moment from 'moment'
import React, { useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Column } from 'react-table'
import { Filter } from '../common/Filter.entity'
import SortableDataTable, {
  DateColumnFilter,
  NumberColumnFilter,
  SelectColumnFilter,
} from '../common/SortableDataTable'
import { DeliveryOrder, DeliveryOrderStatus } from './DeliveryOrder.entity'
import DeliveryOrderModal from './DeliveryOrderModal'
import { useDeliveryOrderCount, useDeliveryOrdersList } from './queries'

const DeliveryOrdersList = () => {
  const intl = useIntl()
  const status = Object.values(DeliveryOrderStatus)
  const { isOpen, onOpen } = useDisclosure()
  const [filters, setFilters] = useState<Filter[]>([])
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const { data, isLoading } = useDeliveryOrdersList(
    filters,
    currentPage,
    pageSize
  )
  const deliveryOrderCount = useDeliveryOrderCount(filters)
  const [selectedDeliveryOrder, setSelectedDeliveryOrder] =
    useState<DeliveryOrder>()

  const setPage = useCallback(
    (pageIndex: number, pageSize: number) => {
      setCurrentPage(pageIndex)
      setPageSize(pageSize)
    },
    [currentPage, pageSize]
  )

  const columns = useMemo<Column<DeliveryOrder>[]>(
    () => [
      {
        Header: intl.formatMessage({
          id: 'delivery-orders.warehouse',
          description: 'Warehouse attribute of a delivery order',
          defaultMessage: 'Warehouse',
        }),
        accessor: (deliveryOrder) => deliveryOrder.warehouse.name,
        id: 'warehouse',
      },
      {
        Header: intl.formatMessage({
          id: 'delivery-orders.target.branch',
          description: 'Target branch attribute of a delivery order',
          defaultMessage: 'Target branch',
        }),
        accessor: (deliveryOrder) => deliveryOrder.targetBranch.name,
        id: 'targetBranch',
      },
      {
        Header: intl.formatMessage({
          id: 'delivery-orders.status',
          description: 'Status attribute of a delivery order',
          defaultMessage: 'Status',
        }),
        accessor: 'status',
        Filter: SelectColumnFilter,
        listOptions: status,
        id: 'status',
      },
      {
        Header: intl.formatMessage({
          id: 'delivery-orders.number.assets',
          description: 'Number of assets attribute of a delivery order',
          defaultMessage: 'Number of assets',
        }),
        Filter: NumberColumnFilter,
        accessor: (deliveryOrder) => deliveryOrder.assets.length,
        id: 'noAssets',
      },
      {
        Header: intl.formatMessage({
          id: 'delivery-orders.signedby.user.signedBy',
          description: 'Signed by attribute of a delivery order',
          defaultMessage: 'Signed by',
        }),
        accessor: (deliveryOrder) => deliveryOrder.signedBy?.name,
        id: 'user',
      },
      {
        Header: intl.formatMessage({
          id: 'delivery-orders.signedby.email.signedBy',
          description: 'Signed by attribute of a delivery order',
          defaultMessage: 'Email signed by',
        }),
        accessor: (deliveryOrder) => deliveryOrder.signedBy?.email,
        id: 'email',
      },
      {
        Header: intl.formatMessage({
          id: 'delivery-orders.createdAt',
          description: 'CreatedAt attribute of a delivery order',
          defaultMessage: 'Created',
        }),
        accessor: (deliveryOrder) => {
          let date = moment(deliveryOrder.createdAt)
          return `${date.format('DD/MM/YYYY')}`
        },
        id: 'createdAt',
        locale: intl.locale,
        Filter: DateColumnFilter,
      },
    ],
    []
  )

  const detailDeliveryOrder = (deliveryOrder: DeliveryOrder) => {
    setSelectedDeliveryOrder(deliveryOrder)
    onOpen()
  }

  const onClose = () => {
    setSelectedDeliveryOrder(undefined)
  }

  return (
    <>
      <SortableDataTable
        data={data?.data ?? []}
        columns={columns}
        setPage={setPage}
        setFilters={setFilters}
        loading={isLoading}
        currentPage={currentPage}
        totalCount={deliveryOrderCount.data}
        sizePage={pageSize}
        fontSize="smaller"
        paginationAuto={true}
        filterAuto={true}
        enableFilters
        onRowSelected={detailDeliveryOrder}
      />
      {selectedDeliveryOrder ? (
        <DeliveryOrderModal
          deliveryOrder={selectedDeliveryOrder}
          isOpenDeliveryOrder={isOpen}
          onCloseDeliveryOrder={onClose}
        />
      ) : null}
    </>
  )
}

export default DeliveryOrdersList

import { Button } from '@chakra-ui/button'
import { FormControl, FormLabel } from '@chakra-ui/form-control'
import { DownloadIcon } from '@chakra-ui/icons'
import { Stack } from '@chakra-ui/layout'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal'
import {
  Box,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react'
import { Select } from '@chakra-ui/select'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useAssetTypesList } from '../asset-types/queries'
import { Asset, AssetLocation, AssetStatus } from './Asset.entity'
import { useCreateAsset, useUpdateAsset } from './queries'

export type AssetModalProps = {
  asset?: Asset
  isOpen: boolean
  onClose: () => void
  downloadInSVG?: (reference: string) => void
}

const AssetModal = ({
  asset,
  isOpen,
  onClose,
  downloadInSVG,
}: AssetModalProps) => {
  const intl = useIntl()

  const newAssetTitleMessage = intl.formatMessage({
    id: 'assets.new',
    description: 'New asset title message',
    defaultMessage: 'New Asset',
  })

  const title = asset ? asset.reference : newAssetTitleMessage
  const assetStatuses = Object.values(AssetStatus)
  const assetLocations = Object.values(AssetLocation)
  const assetTypes = useAssetTypesList()
  const [value, setValue] = React.useState(1)

  const updateAssetMutation = useUpdateAsset()
  const createAssetMutation = useCreateAsset()

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Asset>({ defaultValues: asset })

  const onSubmit: SubmitHandler<any> = (data) => {
    data.currentCycle = Number(data.currentCycle)
    data.type = assetTypes.data?.find(
      (assetType) => assetType.id === data.type.id
    )
    if (data.currentLocation.reference == '')
      data.currentLocation.reference = undefined

    if (asset) {
      updateAssetMutation.mutate(data)
    } else {
      createAssetMutation.mutate({ asset: data, quantity: data.quantity })
    }
  }

  const downloadQR = (reference: string) => {
    if (downloadInSVG) {
      downloadInSVG(reference)
    }
  }

  useEffect(() => {
    if (createAssetMutation.isSuccess || updateAssetMutation.isSuccess) {
      reset(asset)
      onClose()
    }
  }, [
    createAssetMutation.isSuccess,
    updateAssetMutation.isSuccess,
    asset?.type.brand,
  ])

  return (
    <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Stack spacing="3">
              {asset ? (
                <FormControl
                  id="reference"
                  isRequired
                  isReadOnly
                  isInvalid={errors.reference !== undefined}>
                  <FormLabel>
                    <FormattedMessage
                      id="assets.formlabel.reference"
                      description="Assets reference form label"
                      defaultMessage="Reference"
                    />
                  </FormLabel>
                  <Input {...register('reference')} name="reference" />
                </FormControl>
              ) : null}
              <FormControl
                id="currentLocationType"
                isRequired
                isInvalid={errors.currentLocation !== undefined}>
                <FormLabel>
                  <FormattedMessage
                    id="assets.formlabel.currentLocation"
                    description="Asset current location form label"
                    defaultMessage="Current Location"
                  />
                </FormLabel>
                <Select {...register('currentLocation.type')}>
                  {assetLocations.map((assetLocation) => (
                    <option key={assetLocation} value={assetLocation}>
                      {_.capitalize(assetLocation)}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                id="currentLocationReference"
                isInvalid={errors.currentCycle !== undefined}>
                <FormLabel>
                  <FormattedMessage
                    id="assets.formlabel.currentLocationReference"
                    description="Asset current location reference form label"
                    defaultMessage="Current Location Reference"
                  />
                </FormLabel>
                <Input {...register('currentLocation.reference')}></Input>
              </FormControl>
              <FormControl
                id="currentCycle"
                isRequired
                isInvalid={errors.currentCycle !== undefined}>
                <FormLabel>
                  <FormattedMessage
                    id="assets.formlabel.currentCycle"
                    description="Asset current cycle form label"
                    defaultMessage="Current Cycle"
                  />
                </FormLabel>
                <NumberInput name="currentCycle" min={0} defaultValue={0}>
                  <NumberInputField {...register('currentCycle')} />
                </NumberInput>
              </FormControl>
              <FormControl id="assetType" isRequired>
                <FormLabel>
                  <FormattedMessage
                    id="assets.formlabel.assetType"
                    description="Asset type form label"
                    defaultMessage="Asset Type"
                  />
                </FormLabel>
                <Select {...register('type.id')}>
                  {assetTypes.data?.map((assetType) => (
                    <option key={assetType.id} value={assetType.id}>
                      {`(${assetType.id}) ${assetType.brand.ticker}, ${assetType.ticker}, ${assetType.type}, ${assetType.containerType} (${assetType.dimension.height}, ${assetType.dimension.width}, ${assetType.dimension.length})`}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl id="status" isRequired>
                <FormLabel>
                  <FormattedMessage
                    id="assets.formlabel.status"
                    description="Asset status form label"
                    defaultMessage="Status"
                  />
                </FormLabel>
                <Select {...register('status')}>
                  {assetStatuses.map((status) => (
                    <option key={status} value={status}>
                      {_.capitalize(status)}
                    </option>
                  ))}
                </Select>
              </FormControl>
              {!asset ? (
                <FormControl id="quantity" isRequired>
                  <FormLabel>
                    <FormattedMessage
                      id="assets.formlabel.quantity"
                      description="Asset quantity form label"
                      defaultMessage="Quantity"
                    />
                  </FormLabel>
                  <NumberInput
                    type="number"
                    defaultValue={1}
                    min={1}
                    {...register('quantity')}
                    onChange={(value) => setValue(parseInt(value))}>
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
              ) : null}
            </Stack>
          </ModalBody>
          <ModalFooter>
            {asset ? (
              <Box flex="1" textAlign="left">
                <Button
                  colorScheme="teal"
                  fontSize="md"
                  isLoading={
                    createAssetMutation.isLoading ||
                    updateAssetMutation.isLoading
                  }
                  leftIcon={<DownloadIcon />}
                  onClick={() => downloadQR(asset.reference)}>
                  <FormattedMessage
                    id="assets.download.qr"
                    description="Download QR label"
                    defaultMessage="Download QR"
                  />
                </Button>{' '}
              </Box>
            ) : null}
            <Button
              type="submit"
              colorScheme="teal"
              fontSize="md"
              isLoading={
                createAssetMutation.isLoading || updateAssetMutation.isLoading
              }>
              <FormattedMessage
                id="assets.button.save"
                description="Assets save button"
                defaultMessage="Save"
              />
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default AssetModal

import { Heading } from '@chakra-ui/layout'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import OrdersList from '../features/orders/OrdersList'

const Orders = () => {
  return (
    <>
      <Heading>
        <FormattedMessage
          id="orders"
          defaultMessage="Orders"
          description="Orders screen title"
        />
      </Heading>
      <OrdersList />
    </>
  )
}

export default Orders

import { DownloadIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import es from 'date-fns/locale/es'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import ReactSelect from 'react-select'
import ReactToPrint from 'react-to-print'
import { Branch } from '../branches/Branch.entity'
import { useBranchesList } from '../branches/queries'
import { Filter } from '../common/Filter.entity'
import { Partner } from '../partners/Partner.entity'
import { usePartnersList } from '../partners/queries'
import { CreateInvoiceDto, Invoice } from './Invoice.entity'
import { useCreateInvoice } from './queries'
import TemplatePdfCyclesInvoice from './templates/TemplatePdfCycles'
import TemplatePdfOrdersInvoice from './templates/TemplatePdfOrders'

export type InvoiceModalProps = {
  invoice?: Partial<Invoice> | undefined
  isOpenInvoice: boolean
  onCloseInvoice: () => void
}

const InvoiceModal = ({
  invoice: invoice,
  isOpenInvoice,
  onCloseInvoice,
}: InvoiceModalProps) => {
  const intl = useIntl()
  const [partnerFilters, setPartnerFilters] = useState<Filter[]>([])
  const [branchFilters, setBranchFilters] = useState<Filter[]>([])
  const [beginDate, setBeginDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const partnersQuery = usePartnersList(partnerFilters, 0, 50)
  const branchesQuery = useBranchesList(branchFilters, 0, 50)
  const createInvoice = useCreateInvoice()
  const ordersComponentRef = useRef(null)
  const cyclesComponentRef = useRef(null)

  registerLocale('es', es)

  const formatDate = (date: Date) => {
    let d = moment(date)
    return `${d.format('DD/MM/YYYY')}`
  }

  const namePDF = () => {
    return `${invoice?.partner?.name}  ${moment(invoice?.beginDate).format(
      'DD-MM-YY'
    )} - ${moment(invoice?.endDate).format('DD-MM-YY')}`
  }

  useEffect(() => {
    if (createInvoice.isSuccess) {
      onCloseInvoice()
    }
  }, [createInvoice.isSuccess])

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<CreateInvoiceDto>({ defaultValues: invoice })

  const onSubmit: SubmitHandler<CreateInvoiceDto> = (data) => {
    if (data) {
      if (!data.beginDate) data.beginDate = new Date()
      if (!data.endDate) data.endDate = new Date()
      createInvoice.mutate(data)
    }
  }

  const onPartnerSelected = (partner: Partner) => {
    setValue('partnerId', partner.id ?? '')
  }

  const onBranchSelected = (branch: Branch) => {
    setValue('branchId', branch.id ?? '')
  }

  const onSetBeginDate = (date: Date) => {
    setBeginDate(date)
    setValue('beginDate', date)
  }

  const onSetEndDate = (date: Date) => {
    setEndDate(date)
    setValue('endDate', date)
  }

  return (
    <>
      <Modal isOpen={isOpenInvoice} onClose={onCloseInvoice} size={'xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <FormattedMessage
              id="invoices"
              defaultMessage="Invoices"
              description="Invoices screen title"
            />
          </ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              <FormControl
                id="partner"
                isRequired
                isInvalid={errors.partnerId !== undefined}>
                <FormLabel>
                  <FormattedMessage
                    id="invoices.partner.name"
                    description="Name attribute of an invoice partner"
                    defaultMessage="Partner"
                  />
                </FormLabel>
                <ReactSelect
                  cacheOptions
                  defaultOptions
                  isClearable
                  defaultValue={
                    invoice?.partner && {
                      value: invoice.partner,
                      label: invoice.partner.name,
                    }
                  }
                  isDisabled={invoice != undefined}
                  onChange={(option) => {
                    if (option?.value) {
                      onPartnerSelected(option.value)
                    }
                  }}
                  onInputChange={(value) => {
                    setPartnerFilters([{ id: 'name', value }])
                  }}
                  options={
                    partnersQuery.data?.data.map((partner) => ({
                      value: partner,
                      label: partner.name,
                    })) ?? []
                  }
                  styles={{
                    input: (provided, state) => ({
                      ...provided,
                      color: 'inherit',
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      background: 'inherit',
                      border: '1px solid var(--chakra-colors-whiteAlpha-300)',
                      borderColor: 'inherit',
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: 'inherit',
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      backgroundColor: 'var(--chakra-colors-gray-700)',
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: 'var(--chakra-colors-gray-700)',
                      cursor: 'pointer',
                    }),
                  }}
                />
              </FormControl>
              <FormControl
                id="branch"
                isRequired
                isInvalid={errors.branchId !== undefined}>
                <FormLabel>
                  <FormattedMessage
                    id="invoices.branch.name"
                    description="Name attribute of an invoice branch"
                    defaultMessage="Branch"
                  />
                </FormLabel>
                <ReactSelect
                  cacheOptions
                  defaultOptions
                  isClearable
                  defaultValue={
                    invoice?.branch && {
                      value: invoice.branch,
                      label: invoice.branch.name,
                    }
                  }
                  isDisabled={invoice != undefined}
                  onChange={(option) => {
                    if (option?.value) {
                      onBranchSelected(option.value)
                    }
                  }}
                  onInputChange={(value) => {
                    setPartnerFilters([{ id: 'name', value }])
                  }}
                  options={
                    branchesQuery.data?.data.map((branch) => ({
                      value: branch,
                      label: branch.name,
                    })) ?? []
                  }
                  styles={{
                    input: (provided, state) => ({
                      ...provided,
                      color: 'inherit',
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      background: 'inherit',
                      border: '1px solid var(--chakra-colors-whiteAlpha-300)',
                      borderColor: 'inherit',
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: 'inherit',
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      backgroundColor: 'var(--chakra-colors-gray-700)',
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: 'var(--chakra-colors-gray-700)',
                      cursor: 'pointer',
                    }),
                  }}
                />
              </FormControl>
              <FormControl
                id="beginDate"
                isRequired
                isInvalid={errors.beginDate !== undefined}>
                <FormLabel>
                  <FormattedMessage
                    id="invoices.beginDate"
                    description="BeginDate attribute of an invoice"
                    defaultMessage="Begin"
                  />
                </FormLabel>
                <ReactDatePicker
                  dateFormat="dd/MM/yyyy"
                  onChange={onSetBeginDate}
                  todayButton={'Today'}
                  selected={
                    invoice != undefined
                      ? new Date(invoice?.beginDate ?? new Date())
                      : beginDate
                  }
                  locale={'es'}
                  className="chakra-input css-xpongc css-esakc6 css-1c6j008"
                  disabled={invoice != undefined}
                />
              </FormControl>
              <FormControl
                id="endDate"
                isRequired
                isInvalid={errors.endDate !== undefined}>
                <FormLabel>
                  <FormattedMessage
                    id="invoices.endDate"
                    description="EndDate attribute of an invoice"
                    defaultMessage="End"
                  />
                </FormLabel>
                <ReactDatePicker
                  dateFormat="dd/MM/yyyy"
                  onChange={onSetEndDate}
                  todayButton={'Today'}
                  selected={
                    invoice != undefined
                      ? new Date(invoice?.endDate ?? new Date())
                      : endDate
                  }
                  locale={'es'}
                  className="chakra-input css-xpongc css-esakc6 css-1c6j008"
                  disabled={invoice != undefined}
                />
              </FormControl>
              {invoice && (
                <>
                  <HStack>
                    <Box flex="1" textAlign="center">
                      <ReactToPrint
                        documentTitle={namePDF()}
                        trigger={() => (
                          <Button
                            leftIcon={<DownloadIcon />}
                            colorScheme="teal"
                            my="3">
                            <FormattedMessage
                              id="invoices.download.orders"
                              description="Download Orders invoices label"
                              defaultMessage="Download Orders"
                            />
                          </Button>
                        )}
                        content={() => ordersComponentRef.current}
                      />
                      <Box hidden>
                        <TemplatePdfOrdersInvoice
                          innerRef={ordersComponentRef}
                          id={invoice?.id}
                          numOrders={invoice?.data?.numOrders ?? 0}
                          unitPriceOrder={invoice?.data?.unitPriceOrder ?? 0}
                          beginDate={invoice?.beginDate!}
                          endDate={invoice?.endDate!}
                          partnerName={invoice?.partner?.name ?? ''}
                          branchName={invoice?.branch?.name ?? ''}
                          nit={invoice?.partner?.nit ?? ''}
                        />
                      </Box>
                    </Box>
                    <Box flex="1" textAlign="center">
                      <ReactToPrint
                        documentTitle={namePDF()}
                        trigger={() => (
                          <Button
                            leftIcon={<DownloadIcon />}
                            colorScheme="teal"
                            my="3">
                            <FormattedMessage
                              id="invoices.download.cycles"
                              description="Download Cycles invoices label"
                              defaultMessage="Download Cycles"
                            />
                          </Button>
                        )}
                        content={() => cyclesComponentRef.current}
                      />
                      <Box hidden>
                        <TemplatePdfCyclesInvoice
                          innerRef={cyclesComponentRef}
                          id={invoice?.id}
                          details={invoice?.data?.details!}
                          beginDate={invoice?.beginDate!}
                          endDate={invoice?.endDate!}
                          partnerName={invoice?.partner?.name ?? ''}
                          branchName={invoice?.branch?.name ?? ''}
                          nit={invoice?.partner?.nit ?? ''}
                        />
                      </Box>
                    </Box>
                  </HStack>
                </>
              )}
            </ModalBody>
            {!invoice && (
              <ModalFooter>
                <Button
                  type="submit"
                  colorScheme="teal"
                  fontSize="md"
                  isLoading={status === 'loading'}>
                  <FormattedMessage
                    id="invoices.button.save"
                    description="Invoices save button"
                    defaultMessage="Save"
                  />
                </Button>
              </ModalFooter>
            )}
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}

export default InvoiceModal

import { useQuery } from 'react-query'
import { Filter } from '../common/Filter.entity'
import PaginatedResult from '../common/PaginatedResult.entity'
import useXicloClient, { XicloResource } from '../common/useXicloClient'
import { DeliveryOrder } from './DeliveryOrder.entity'

export const deliveryOrdersKeys = {
  all: ['delivery-orders'] as const,
  count: (filters: Filter[]) =>
    [...deliveryOrdersKeys.all, 'count', { filters }] as const,
  lists: () => [...deliveryOrdersKeys.all, 'list'] as const,
  list: (filters: Filter[], page: number, limit: number) =>
    [...deliveryOrdersKeys.lists(), { filters, page, limit }] as const,
}

const findAll = async (filters: Filter[], page: number, limit: number) => {
  const offset = limit * page

  let params: { [key: string]: any } = {
    limit,
    offset,
    filters,
  }

  filters.forEach(({ id, value }) => {
    params[id] = value
  })

  const client = await useXicloClient(XicloResource.DeliveryOrders)
  const { data } = await client.get<PaginatedResult<DeliveryOrder>>('', {
    params,
  })

  return data
}

export const useDeliveryOrdersList = (
  filters: Filter[],
  page: number,
  limit = 10
) => {
  return useQuery<PaginatedResult<DeliveryOrder>, Error>(
    deliveryOrdersKeys.list(filters, page, limit),
    () => findAll(filters, page ?? 0, limit),
    { keepPreviousData: true }
  )
}

const count = async (filters: Filter[]) => {
  let params: { [key: string]: any } = {}

  filters.forEach(({ id, value }) => {
    params[id] = value
  })

  const client = await useXicloClient(XicloResource.DeliveryOrders)
  const { data } = await client.get<number>('/count/total', {
    params,
  })

  return data
}

export const useDeliveryOrderCount = (filters: Filter[]) => {
  const countAllQuery = useQuery<number, Error>(
    deliveryOrdersKeys.count(filters),
    () => count(filters)
  )

  return countAllQuery
}

import { useDisclosure } from '@chakra-ui/hooks'
import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Column } from 'react-table'
import SortableDataTable from '../common/SortableDataTable'
import { Brand } from './Brand.entity'
import BrandModal from './BrandModal'

export type BrandsListProps = {
  brands: Brand[]
}

const BrandsList = ({ brands }: BrandsListProps) => {
  const intl = useIntl()
  const { isOpen, onOpen } = useDisclosure()
  const [selectedBrand, setSelectedBrand] = useState<Brand>()

  const columns = useMemo<Column<Brand>[]>(
    () => [
      {
        Header: intl.formatMessage({
          id: 'brands.name',
          description: 'Name attribute of a Brand',
          defaultMessage: 'Name',
        }),
        accessor: 'name',
      },
      {
        Header: intl.formatMessage({
          id: 'brands.ticker',
          description: 'Ticker attribute of a Brand',
          defaultMessage: 'Ticker',
        }),
        accessor: 'ticker',
      },
    ],
    []
  )

  const editBrand = (brand: Brand) => {
    setSelectedBrand(brand)
    onOpen()
  }

  const onClose = () => {
    setSelectedBrand(undefined)
  }

  return (
    <>
      <SortableDataTable
        data={brands}
        columns={columns}
        onRowSelected={editBrand}
        currentPage={0}
        totalCount={brands.length}
        paginationAuto={true}
        filterAuto={true}
      />
      {selectedBrand ? (
        <BrandModal brand={selectedBrand} isOpen={isOpen} onClose={onClose} />
      ) : null}
    </>
  )
}

export default BrandsList

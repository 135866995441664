import { AddIcon } from '@chakra-ui/icons'
import { Heading } from '@chakra-ui/layout'
import { Button, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import InvoiceModal from '../features/invoices/InvoiceModal'
import InvoicesList from '../features/invoices/InvoicesList'

const Invoices = () => {
  const { onOpen, onClose, isOpen } = useDisclosure()

  return (
    <>
      <Heading>
        <FormattedMessage
          id="invoices"
          defaultMessage="Invoices"
          description="Invoices screen title"
        />
      </Heading>
      <Button
        leftIcon={<AddIcon />}
        colorScheme="teal"
        my="3"
        onClick={() => onOpen()}>
        <FormattedMessage
          id="invoices.button.new"
          description="Invoices new label"
          defaultMessage="New"
        />
      </Button>
      <InvoicesList />
      <InvoiceModal isOpenInvoice={isOpen} onCloseInvoice={onClose} />
    </>
  )
}

export default Invoices

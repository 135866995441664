import { AccordionItem } from '@chakra-ui/accordion'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl'
import { Link, useHistory } from 'react-router-dom'
import { Column } from 'react-table'
import SortableDataTable from '../common/SortableDataTable'
import { AssetLog } from './AssetLog.entity'
import { EmbeddedAsset } from './EmbeddedAsset'

export type AssetLogModalProps = {
  assetLog: AssetLog
  isOpen: boolean
  onClose: () => void
}

const AssetLogModal = ({ assetLog, isOpen, onClose }: AssetLogModalProps) => {
  const intl = useIntl()
  const history = useHistory()

  const columnsAsset = useMemo<Column<EmbeddedAsset>[]>(
    () => [
      {
        Header: intl.formatMessage({
          id: 'assets.reference',
          description: 'Reference attribute of an asset',
          defaultMessage: 'Reference',
        }),
        accessor: 'reference',
        disableFilters: true,
      },
      {
        Header: intl.formatMessage({
          id: 'assets.brand',
          description: 'Brand attribute of an asset',
          defaultMessage: 'Brand',
        }),
        accessor: (asset) => asset.type.brand.name,
        disableFilters: true,
      },
      {
        Header: intl.formatMessage({
          id: 'assets.type',
          description: 'Type attribute of an asset',
          defaultMessage: 'Type',
        }),
        accessor: (asset) => asset.type.id,
        disableFilters: true,
      },
    ],
    []
  )

  const goToAsset = (asset: EmbeddedAsset) => {
    history.replace('/assets', { asset: asset.reference })
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="5xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <FormattedMessage
              id="asset_logs"
              description="Asset Logs screen title"
              defaultMessage="Asset Logs"
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <HStack>
              <Box width="100px" padding="2">
                <Text>ID</Text>
              </Box>
              <Box
                border="1px"
                borderRadius="5"
                borderColor="gray.600"
                padding="2"
                flex="1">
                {assetLog.id}
              </Box>
            </HStack>
            <HStack marginTop="5">
              <Box width="100px" padding="2">
                <Text>
                  <FormattedMessage
                    id="assetlogs.action"
                    description="Action attribute of a assetlog"
                    defaultMessage="Action"
                  />
                </Text>
              </Box>
              <Box
                border="1px"
                borderRadius="5"
                borderColor="gray.600"
                padding="2"
                flex="1">
                {assetLog.action}
              </Box>
            </HStack>
            <HStack marginTop="5">
              <Box width="100px" padding="2">
                <Text>
                  <FormattedMessage
                    id="assetlogs.source"
                    description="Source label attribute of a assetlog"
                    defaultMessage="Source"
                  />
                </Text>
              </Box>
              <Box
                border="1px"
                borderRadius="5"
                borderColor="gray.600"
                padding="2"
                flex="1">
                <Link
                  to={{
                    pathname: '/users',
                    state: {
                      email: assetLog.source.userId
                        ? assetLog.source.userId.email
                        : '',
                    },
                  }}>
                  {assetLog.source.userId ? assetLog.source.userId.email : ''}
                </Link>
              </Box>
              <Box width="100px" padding="2">
                <Text>
                  <FormattedMessage
                    id="assetlogs.role"
                    description="Role label to assetlog"
                    defaultMessage="Role"
                  />
                </Text>
              </Box>
              <Box
                border="1px"
                borderRadius="5"
                borderColor="gray.600"
                padding="2"
                flex="1">
                {assetLog.source.role}
              </Box>
            </HStack>
            <HStack marginTop="5">
              <Box width="100px" padding="2">
                <Text>
                  <FormattedMessage
                    id="assetlogs.target"
                    description="Target label attribute of a assetlog"
                    defaultMessage="Target"
                  />
                </Text>
              </Box>
              <Box
                border="1px"
                borderRadius="5"
                borderColor="gray.600"
                padding="2"
                flex="1">
                <Link
                  to={{
                    pathname: '/users',
                    state: { email: assetLog.target.userId.email },
                  }}>
                  {assetLog.target.userId.email}
                </Link>
              </Box>
              <Box width="100px" padding="2">
                <Text>
                  <FormattedMessage
                    id="assetlogs.role"
                    description="Role label to assetlog"
                    defaultMessage="Role"
                  />
                </Text>
              </Box>
              <Box
                border="1px"
                borderRadius="5"
                borderColor="gray.600"
                padding="2"
                flex="1">
                {assetLog.target.role}
              </Box>
            </HStack>
            <HStack marginTop="5">
              <Box width="100px" padding="2">
                <Text>
                  <FormattedMessage
                    id="createdAt"
                    description="Created at label"
                    defaultMessage="Created at"
                  />
                </Text>
              </Box>
              <Box
                border="1px"
                borderRadius="5"
                borderColor="gray.600"
                padding="2"
                flex="1">
                <FormattedDate
                  value={assetLog.createdAt}
                  year="numeric"
                  month="long"
                  day="2-digit"
                  hour="2-digit"
                  minute="2-digit"
                />
              </Box>
            </HStack>
            <Stack spacing="3">
              <Accordion marginY="5" allowMultiple>
                <AccordionItem
                  marginY="5"
                  borderColor="gray.600"
                  border="1px"
                  borderRadius="5">
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        <FormattedMessage
                          id="assets"
                          defaultMessage="Assets"
                          description="Assets screen title"
                        />
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <SortableDataTable
                      data={assetLog.assets}
                      columns={columnsAsset}
                      currentPage={0}
                      paginationAuto={false}
                      totalCount={assetLog.assets.length}
                      onRowSelected={goToAsset}
                      enableFilters
                    />
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AssetLogModal

import { useDisclosure } from '@chakra-ui/react'
import React, { useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Column } from 'react-table'
import { Filter } from '../common/Filter.entity'
import SortableDataTable from '../common/SortableDataTable'
import { Invoice } from './Invoice.entity'
import InvoiceModal from './InvoiceModal'
import { useInvoicesCount, useInvoicesList } from './queries'

const InvoicesList = () => {
  const intl = useIntl()
  const [filters, setFilters] = useState<Filter[]>([])
  const { isOpen, onOpen } = useDisclosure()
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const { data, isLoading } = useInvoicesList(filters, currentPage, pageSize)
  const invoicesCount = useInvoicesCount(filters)
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice>()
  const setPage = useCallback(
    (pageIndex: number, pageSize: number) => {
      setCurrentPage(pageIndex)
      setPageSize(pageSize)
    },
    [currentPage, pageSize]
  )

  const columns = useMemo<Column<Invoice>[]>(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: intl.formatMessage({
          id: 'invoices.partner.name',
          description: 'Name attribute of an invoice partner',
          defaultMessage: 'Partner',
        }),
        accessor: (a) => a.partner.name,
      },
      {
        Header: intl.formatMessage({
          id: 'invoices.branch.name',
          description: 'Name attribute of an invoice branch',
          defaultMessage: 'Branch',
        }),
        accessor: (a) => a.branch.name,
      },
      {
        Header: intl.formatMessage({
          id: 'invoices.beginDate',
          description: 'BeginDate attribute of an invoice',
          defaultMessage: 'Begin',
        }),
        accessor: (invoice) => {
          const d = new Date(invoice.beginDate)
          return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
        },
      },
      {
        Header: intl.formatMessage({
          id: 'invoices.endDate',
          description: 'EndDate attribute of an invoice',
          defaultMessage: 'End',
        }),
        accessor: (invoice) => {
          const d = new Date(invoice.endDate)
          return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
        },
      },
    ],
    []
  )

  const detailInvoice = (invoice: Invoice) => {
    setSelectedInvoice(invoice)
    onOpen()
  }

  const onClose = () => {
    setSelectedInvoice(undefined)
  }

  return (
    <>
      <SortableDataTable
        data={data?.data ?? []}
        columns={columns}
        setPage={setPage}
        setFilters={setFilters}
        loading={isLoading}
        currentPage={currentPage}
        totalCount={invoicesCount.data}
        sizePage={pageSize}
        fontSize="smaller"
        paginationAuto={true}
        filterAuto={true}
        enableFilters
        onRowSelected={detailInvoice}
      />
      {selectedInvoice ? (
        <InvoiceModal
          invoice={selectedInvoice}
          isOpenInvoice={isOpen}
          onCloseInvoice={onClose}
        />
      ) : null}
    </>
  )
}

export default InvoicesList

import { useQuery } from 'react-query'
import PaginatedResult from '../common/PaginatedResult.entity'
import { Payment } from './Payment.entity'
import usePaymentApi from './usePaymentApi'

export const paymentKeys = {
  all: ['payments'] as const,
  lists: () => [...paymentKeys.all, 'lists'] as const,
  list: (page: number, limit: number) =>
    [...paymentKeys.lists(), { page, limit }] as const,
}

export const usePaymentList = (page: number, limit = 10) => {
  const offset = limit * page

  const findAllQuery = useQuery<PaginatedResult<Payment>, Error>(
    paymentKeys.list(page, limit),
    async () => {
      const { find } = await usePaymentApi()
      return find(offset, limit)
    },
    { keepPreviousData: true }
  )

  return findAllQuery
}

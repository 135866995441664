import { IconButton } from '@chakra-ui/button'
import { Box, Flex, Heading, Text } from '@chakra-ui/layout'
import React from 'react'
import { IoMdLogOut } from 'react-icons/io'
import { useAuth } from '../../providers/AuthProvider'
import { ColorModeSwitcher } from './ColorModeSwitcher'

const Topbar = () => {
  const { user, signOut } = useAuth()

  return (
    <Flex py="6">
      <Box>
        <Heading>Xiclo</Heading>
      </Box>
      <Flex flex="1" justifyContent="flex-end">
        {user ? (
          <>
            <Text mx="5" alignSelf="center">
              {user.name ?? user.email}
            </Text>
            <IconButton
              aria-label="Cerrar sesion"
              variant="outline"
              icon={<IoMdLogOut />}
              onClick={signOut}
            />
          </>
        ) : null}
        <ColorModeSwitcher />
      </Flex>
    </Flex>
  )
}

export default Topbar

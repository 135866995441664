import { AssetLog } from '../asset-logs/AssetLog.entity'
import { Asset } from '../assets/Asset.entity'
import { Branch } from '../branches/Branch.entity'
import { User } from '../users/User.entity'

export enum OrderStatus {
  Open = 'open',
  Close = 'close',
}

export enum OrderDetails {
  Normal = 'normal',
  Partial = 'partial',
  Expired = 'expired',
}

export interface Order {
  id: string
  user: User
  branch: Branch
  status: OrderStatus
  logs: AssetLog[]
  assetsBorrowed: Asset[]
  assetsReturned: Asset[]
  createdAt: Date
  updateAt: Date
}

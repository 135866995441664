import { useDisclosure } from '@chakra-ui/hooks'
import moment from 'moment'
import React, { useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Column } from 'react-table'
import { Filter } from '../common/Filter.entity'
import SortableDataTable, {
  DateColumnFilter,
  NumberColumnFilter,
  SelectColumnFilter,
} from '../common/SortableDataTable'
import { Order, OrderDetails, OrderStatus } from './Order.entity'
import OrderModal from './OrderModal'
import { useOrderCount, useOrdersList } from './queries'

const OrdersList = () => {
  const intl = useIntl()
  const { isOpen, onOpen } = useDisclosure()
  const [selectedOrder, setSelectedOrder] = useState<Order>()
  const status = Object.values(OrderStatus)
  const details = Object.values(OrderDetails)

  const [filters, setFilters] = useState<Filter[]>([])
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const { data, isLoading } = useOrdersList(filters, currentPage, pageSize)
  const orderCount = useOrderCount(filters)

  const setPage = useCallback(
    (pageIndex: number, pageSize: number) => {
      setCurrentPage(pageIndex)
      setPageSize(pageSize)
    },
    [currentPage, pageSize]
  )

  const columns = useMemo<Column<Order>[]>(
    () => [
      {
        Header: intl.formatMessage({
          id: 'orders.branch',
          description: 'Branch attribute of a Order',
          defaultMessage: 'Name',
        }),
        accessor: (order) => order.branch.name,
        id: 'branch',
      },
      {
        Header: intl.formatMessage({
          id: 'orders.user',
          description: 'User attribute of a Order',
          defaultMessage: 'User',
        }),
        accessor: (order) => order.user.name,
        id: 'user',
      },
      {
        Header: intl.formatMessage({
          id: 'orders.email',
          description: 'Email attribute of an Order',
          defaultMessage: 'Email',
        }),
        accessor: (order) => order.user.email,
        id: 'email',
      },
      {
        Header: intl.formatMessage({
          id: 'orders.status',
          description: 'Status attribute of an order',
          defaultMessage: 'Status',
        }),
        accessor: (order) =>
          order.status == OrderStatus.Close ? 'closed' : 'open',
        Filter: SelectColumnFilter,
        listOptions: status,
        id: 'status',
      },
      {
        Header: intl.formatMessage({
          id: 'orders.details',
          description: 'Status detail attribute of an order',
          defaultMessage: 'Details',
        }),
        accessor: (order) => {
          if (order.status == OrderStatus.Close) return OrderDetails.Normal
          else {
            var created = moment(order.createdAt)
            var now = moment()
            if (created.add(5, 'days') < now) return OrderDetails.Expired
            else {
              var isPartial =
                order.assetsReturned.length > 0 &&
                order.assetsBorrowed.length != order.assetsReturned.length
              return isPartial ? OrderDetails.Partial : OrderDetails.Normal
            }
          }
        },
        disableFilters: true,
        id: 'details',
      },
      {
        Header: intl.formatMessage({
          id: 'orders.number.assets.borrowed',
          description: 'Number of assets borrowed label attribute of a order',
          defaultMessage: 'Number of assets borrowed',
        }),
        Filter: NumberColumnFilter,
        accessor: (order) => order.assetsBorrowed.length,
        id: 'noAssetsBorrowed',
      },
      {
        Header: intl.formatMessage({
          id: 'orders.number.assets.returned',
          description: 'Number of assets returned label attribute of a order',
          defaultMessage: 'Number of assets returned',
        }),
        Filter: NumberColumnFilter,
        accessor: (order) => order.assetsReturned.length,
        id: 'noAssetsReturned',
      },
      {
        Header: intl.formatMessage({
          id: 'orders.createdAt',
          description: 'CreatedAt attribute of an order',
          defaultMessage: 'Created',
        }),
        accessor: (order) => {
          let date = moment(order.createdAt)
          return `${date.format('DD/MM/YYYY')}`
        },
        id: 'createdAt',
        locale: intl.locale,
        Filter: DateColumnFilter,
      },
    ],
    []
  )

  const detailOrder = (order: Order) => {
    setSelectedOrder(order)
    onOpen()
  }

  const onClose = () => {
    setSelectedOrder(undefined)
  }

  return (
    <>
      <SortableDataTable
        data={data?.data ?? []}
        columns={columns}
        setPage={setPage}
        setFilters={setFilters}
        loading={isLoading}
        currentPage={currentPage}
        totalCount={orderCount.data}
        sizePage={pageSize}
        fontSize="smaller"
        paginationAuto={true}
        filterAuto={true}
        enableFilters
        onRowSelected={detailOrder}
      />
      {selectedOrder ? (
        <OrderModal order={selectedOrder} isOpen={isOpen} onClose={onClose} />
      ) : null}
    </>
  )
}

export default OrdersList

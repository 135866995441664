import { useToast } from '@chakra-ui/react'
import { useIntl } from 'react-intl'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Filter } from '../common/Filter.entity'
import PaginatedResult from '../common/PaginatedResult.entity'
import useXicloClient, { XicloResource } from '../common/useXicloClient'
import { CreatePartnerDto, Partner } from './Partner.entity'

export const partnerKeys = {
  all: ['partners'] as const,
  lists: () => [...partnerKeys.all, 'list'] as const,
  list: (filters: Filter[], page: number, limit: number) =>
    [...partnerKeys.lists(), { filters, page, limit }] as const,
}

const findAll = async (
  filters: Filter[],
  page: number,
  limit: number,
  includeInvisible: boolean = true
) => {
  const offset = limit * page

  let params: { [key: string]: any } = {
    limit,
    offset,
    includeInvisible,
  }

  filters.forEach(({ id, value }) => {
    params[id] = value
  })

  const client = await useXicloClient(XicloResource.Partners)
  const { data } = await client.get<PaginatedResult<Partner>>('/', {
    params,
  })

  return data
}

export const usePartnersList = (
  filters: Filter[],
  page: number,
  limit = 10
) => {
  return useQuery<PaginatedResult<Partner>, Error>(
    partnerKeys.list(filters, page, limit),
    () => findAll(filters, page, limit),
    { keepPreviousData: true }
  )
}

const create = async (partner: CreatePartnerDto) => {
  const client = await useXicloClient(XicloResource.Partners)
  const { data } = await client.post<Partner>(`/`, partner)
  return data
}

export const useCreatePartner = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const intl = useIntl()

  const createdSuccessfullMessage = intl.formatMessage({
    id: 'partners.createdSuccessfully',
    description: 'Partner created successfully toast',
    defaultMessage: 'Partner created successfully',
  })

  return useMutation((partner: CreatePartnerDto) => create(partner), {
    onSuccess: () => {
      // Esto se puede mejorar utilizando setQueryData
      queryClient.invalidateQueries(partnerKeys.lists())
      toast({
        title: createdSuccessfullMessage,
        status: 'success',
        isClosable: true,
        position: 'top-right',
      })
    },
    onError: () => {
      toast({
        title: 'Error updating asset, try again later',
        status: 'error',
        isClosable: true,
        position: 'top-right',
      })
    },
  })
}

const update = async (partner: Partial<Partner>) => {
  const client = await useXicloClient(XicloResource.Partners)
  const { data } = await client.patch<Partner>(`${partner.id}`, partner)
  return data
}

export const useUpdatePartner = () => {
  const queryClient = useQueryClient()
  const intl = useIntl()
  const toast = useToast()

  const updatedSuccessfullMessage = intl.formatMessage({
    id: 'partners.updatedSuccessfully',
    description: 'Partner updated successfully toast',
    defaultMessage: 'Partner updated successfully',
  })

  return useMutation((partner: Partial<Partner>) => update(partner), {
    onSuccess: () => {
      // Esto se puede mejorar utilizando setQueryData
      queryClient.invalidateQueries(partnerKeys.lists())

      toast({
        title: updatedSuccessfullMessage,
        status: 'success',
        isClosable: true,
        position: 'top-right',
      })
    },
    onError: () => {
      toast({
        title: 'Error creating asset, try again later',
        status: 'error',
        isClosable: true,
        position: 'top-right',
      })
    },
  })
}

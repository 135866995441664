import { useQuery } from 'react-query'
import { Filter } from '../common/Filter.entity'
import PaginatedResult from '../common/PaginatedResult.entity'
import useXicloClient, { XicloResource } from '../common/useXicloClient'
import { AssetLog } from './AssetLog.entity'

export const assetLogKeys = {
  all: ['asset-logs'] as const,
  count: () => [...assetLogKeys.all, 'count'] as const,
  lists: () => [...assetLogKeys.all, 'list'] as const,
  list: (filters: Filter[], page: number, limit: number) =>
    [...assetLogKeys.lists(), { filters, page, limit }] as const,
  listByIds: (ids: string) => [...assetLogKeys.lists(), { ids }] as const,
}

const findAll = async (filters: Filter[], page: number, limit: number) => {
  const offset = limit * page

  let params: { [key: string]: any } = {
    limit,
    offset,
  }

  filters.forEach(({ id, value }) => {
    params[id] = value
  })

  const client = await useXicloClient(XicloResource.AssetLogs)
  const { data } = await client.get<PaginatedResult<AssetLog>>('/', {
    params,
  })

  return data
}

export const useAssetLogList = (
  filters: Filter[],
  page: number,
  limit = 10
) => {
  return useQuery<PaginatedResult<AssetLog>, Error>(
    assetLogKeys.list(filters, page, limit),
    () => findAll(filters, page ?? 0, limit),
    { keepPreviousData: true }
  )
}

const count = async (filters: Filter[]) => {
  let params: { [key: string]: any } = {}

  filters.forEach(({ id, value }) => {
    params[id] = value
  })

  const client = await useXicloClient(XicloResource.AssetLogs)
  const { data } = await client.get<number>('/count', {
    params,
  })

  return data
}

export const useAssetLogCount = (filters: Filter[]) => {
  const countAllQuery = useQuery<number, Error>(assetLogKeys.count(), () =>
    count(filters)
  )

  return countAllQuery
}

const findAllByIds = async (ids: string) => {
  if (ids) {
    const client = await useXicloClient(XicloResource.AssetLogs)
    const { data } = await client.get<AssetLog[]>('/all/ids', {
      params: { ids: ids.toString() },
    })

    return data
  }
  return []
}

export const useAssetLogListByIds = (ids: string) => {
  if (ids) {
    return useQuery<AssetLog[], Error>(
      assetLogKeys.listByIds(ids),
      () => findAllByIds(ids),
      { keepPreviousData: true }
    )
  }
}

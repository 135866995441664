import { Box, Link, Stack } from '@chakra-ui/layout'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { NavLink } from 'react-router-dom'

type SidebarLinkProps = {
  path: string
}

const SidebarLink: FC<SidebarLinkProps> = ({ children, path }) => {
  return (
    <Link
      exact
      activeStyle={{
        color: 'var(--chakra-colors-teal-200)',
      }}
      _hover={{ textDecoration: 'none' }}
      _focus={{ border: 'none' }}
      py="2"
      as={NavLink}
      to={path}
      w="full">
      {children}
    </Link>
  )
}

const SidebarContent = () => (
  <Stack spacing={0}>
    <SidebarLink path="/dashboard">
      <FormattedMessage
        id="dashboard"
        defaultMessage="Dashboard"
        description="Dashboard screen title"
      />
    </SidebarLink>
    <SidebarLink path="/users">
      <FormattedMessage
        id="users"
        defaultMessage="Users"
        description="Users screen title"
      />
    </SidebarLink>
    <SidebarLink path="/assets">
      <FormattedMessage
        id="assets"
        defaultMessage="Assets"
        description="Assets screen title"
      />
    </SidebarLink>
    <SidebarLink path="/asset-types">
      <FormattedMessage
        id="asset-types"
        defaultMessage="Asset Types"
        description="Asset Types screen title"
      />
    </SidebarLink>
    <SidebarLink path="/brands">
      <FormattedMessage
        id="brands"
        defaultMessage="Brands"
        description="Brands screen title"
      />
    </SidebarLink>
    <SidebarLink path="/partners">
      <FormattedMessage
        id="partners"
        description="Partners screen title"
        defaultMessage="Partners"
      />
    </SidebarLink>
    <SidebarLink path="/branches">
      <FormattedMessage
        id="branches"
        description="Branches screen title"
        defaultMessage="Branches"
      />
    </SidebarLink>
    <SidebarLink path="/asset-logs">
      <FormattedMessage
        id="asset_logs"
        description="Asset Logs screen title"
        defaultMessage="Asset Logs"
      />
    </SidebarLink>
    <SidebarLink path="/payments">
      <FormattedMessage
        id="payments"
        description="Payments screen title"
        defaultMessage="Payments"
      />
    </SidebarLink>
    {/* <SidebarLink path="/questions">
      <FormattedMessage
        id="questions.frequent"
        description="Frequent questions screen title"
        defaultMessage="Frequent questions"
      />
    </SidebarLink> */}
    <SidebarLink path="/qr-dimensions">
      <FormattedMessage
        id="qr-dimensions"
        defaultMessage="QR dimensions"
        description="QR dimensions screen title"
      />
    </SidebarLink>
    <SidebarLink path="/pickups">
      <FormattedMessage
        id="pickups"
        defaultMessage="Pickups"
        description="Pickups screen title"
      />
    </SidebarLink>
    <SidebarLink path="/orders">
      <FormattedMessage
        id="orders"
        defaultMessage="Orders"
        description="Orders screen title"
      />
    </SidebarLink>
    <SidebarLink path="/delivery-orders">
      <FormattedMessage
        id="delivery-orders"
        defaultMessage="Delivery Orders"
        description="Delivery Orders screen title"
      />
    </SidebarLink>
    <SidebarLink path="/invoices">
      <FormattedMessage
        id="invoices"
        defaultMessage="Invoices"
        description="Invoices screen title"
      />
    </SidebarLink>
  </Stack>
)

const Sidebar: FC = ({ children }) => {
  return (
    <Box
      left={0}
      top={0}
      h="100%"
      pr="5"
      pb="5"
      w="200px"
      position="sticky"
      flexShrink={0}
      overflowY="auto">
      <SidebarContent />
    </Box>
  )
}

export default Sidebar

import { useMutation, useQuery, useQueryClient } from 'react-query'
import useXicloClient, { XicloResource } from '../common/useXicloClient'
import { AssetType } from './AssetType.entity'

export const assetTypeKeys = {
  all: ['asset-types'] as const,
  lists: () => [...assetTypeKeys.all, 'list'] as const,
  list: () => [...assetTypeKeys.lists()] as const,
}

const findAll = async () => {
  const client = await useXicloClient(XicloResource.AssetTypes)
  const { data } = await client.get<AssetType[]>('/')

  return data
}

export const useAssetTypesList = () => {
  return useQuery<AssetType[], Error>(assetTypeKeys.list(), () => findAll())
}

const create = async (assetType: Partial<AssetType>) => {
  const client = await useXicloClient(XicloResource.AssetTypes)
  const { data } = await client.post<AssetType>('/', assetType)

  return data
}

export const useCreateAssetType = () => {
  const queryClient = useQueryClient()

  return useMutation((assetType: Partial<AssetType>) => create(assetType), {
    onSuccess: () => {
      queryClient.invalidateQueries(assetTypeKeys.lists())
    },
  })
}

const update = async (assetType: Partial<AssetType>) => {
  const client = await useXicloClient(XicloResource.AssetTypes)
  const { data } = await client.patch<AssetType>(`/${assetType.id}`, assetType)

  return data
}

export const useUpdateAssetType = () => {
  const queryClient = useQueryClient()

  return useMutation((assetType: Partial<AssetType>) => update(assetType), {
    onSuccess: () => {
      queryClient.invalidateQueries(assetTypeKeys.lists())
    },
  })
}

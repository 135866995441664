import { TriangleDownIcon } from '@chakra-ui/icons'
import { Box, Flex, HStack, Image, Spacer, Text } from '@chakra-ui/react'
import _ from 'lodash'
import moment from 'moment'
import 'moment/locale/es'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import QRCode from 'react-qr-code'
import infoContactUs from '../../../constants/InfoContactUs'
import { AssetType } from '../../asset-types/AssetType.entity'
import { template } from './theme'

export type TemplatePdfDeliveryOrderProps = {
  innerRef: any
  warehouse?: string
  targetBranch?: string
  assetTypes: any[]
  id?: string
  createdAt?: Date
}

const TemplatePdfDeliveryOrder = ({
  innerRef,
  warehouse,
  targetBranch,
  assetTypes,
  id,
  createdAt,
}: TemplatePdfDeliveryOrderProps) => {
  const intl = useIntl()
  const dateDownload = () => {
    moment.locale('es')
    return moment(createdAt).format('MMMM DD - YYYY')
  }

  const getFormatMessage = (id: string, value: string) => {
    return intl.formatMessage({
      id: `asset-types.${id}.${value}`,
    })
  }

  const getContainerType = (assetType: AssetType) => {
    return `${_.capitalize(
      getFormatMessage('containerType', assetType.containerType.toLowerCase())
    )}`
  }

  const getType = (assetType: AssetType) => {
    return `${_.capitalize(
      getFormatMessage('type', assetType.type.toLowerCase())
    )}`
  }

  const LineHeader = (props: any) => {
    return <Box {...template.header.line} {...props}></Box>
  }

  const Header = () => {
    return (
      <>
        <LineHeader />
        <Text {...template.header.title}>
          <FormattedMessage
            id="delivery-orders.template.delivery"
            description="Delivery label"
            defaultMessage="Orden"
          />
        </Text>
        <Text {...template.header.title}>
          <FormattedMessage
            id="delivery-orders.template.of"
            description="Of label"
            defaultMessage="de"
          />
        </Text>
        <Text {...template.header.title}>
          <FormattedMessage
            id="delivery-orders.template.order"
            description="Order label"
            defaultMessage="entrega"
          />
        </Text>
        <HStack marginTop={5}>
          <Box {...template.header.boxFieldDateDownload}>
            <Box height={5}>
              <Text {...template.header.label}>
                <FormattedMessage
                  id="delivery-orders.template.dateDownload"
                  description="Date of download label"
                  defaultMessage="FECHA DE DESCARGA:"
                />
              </Text>
            </Box>
            <Box height={10}>
              <Text
                {...template.header.text}
                textTransform="capitalize"
                noOfLines={2}>
                {dateDownload()}
              </Text>
            </Box>
          </Box>
          <Box {...template.header.boxFieldDelivery}>
            <Box height={5}>
              <Text {...template.header.label}>
                <FormattedMessage
                  id="delivery-orders.template.delivery.label"
                  description="Delivery label"
                  defaultMessage="ENTREGA:"
                />
              </Text>
            </Box>
            <Box height={10}>
              <Text {...template.header.text} noOfLines={2}>
                {warehouse}
              </Text>
            </Box>
          </Box>
          <Box {...template.header.boxFieldReceive}>
            <Box height={5}>
              <Text {...template.header.label}>
                <FormattedMessage
                  id="delivery-orders.template.receive"
                  description="Receive label"
                  defaultMessage="RECIBE:"
                />
              </Text>
            </Box>
            <Box height={10}>
              <Text {...template.header.text} noOfLines={2}>
                {targetBranch}
              </Text>
            </Box>
          </Box>
        </HStack>
      </>
    )
  }

  const Footer = () => {
    return (
      <Box {...template.footer.box} position={'fixed'}>
        <Flex>
          <Spacer></Spacer>
          <LineHeader marginEnd={'20px'} />
        </Flex>
        <Flex>
          <Box alignSelf={'center'}>
            <Image src="../images/xiclo.png" width={'150px'} />
          </Box>
          <Spacer></Spacer>
          <Box {...template.footer.label} textAlign={'right'}>
            <Text>{infoContactUs.email}</Text>
            <Text>
              <FormattedMessage
                id="delivery-orders.template.mobile"
                description="Mobile label"
                defaultMessage="Móvil: "
              />
              {infoContactUs.phone}
            </Text>
          </Box>
          <Box {...template.footer.label} textAlign={'right'}>
            <Text>{infoContactUs.pageWeb}</Text>
            <Text>
              <FormattedMessage
                id="delivery-orders.template.nit"
                description="Nit label"
                defaultMessage="NIT: "
              />
              {infoContactUs.nit}
            </Text>
          </Box>
        </Flex>
      </Box>
    )
  }

  const QR = () => {
    if (id) {
      return (
        <QRCode
          title="title"
          value={id}
          style={{ margin: 'auto', marginBottom: 5 }}
          size={150}
        />
      )
    }
    return null
  }

  const Body = () => {
    return (
      <Box paddingRight={5}>
        <Text {...template.body.labelResume}>
          <FormattedMessage
            id="delivery-orders.template.resume"
            description="Resume label"
            defaultMessage="RESUMEN"
          />
        </Text>
        <HStack>
          <Box
            display="inline-block"
            verticalAlign={'top'}
            textAlign={'left'}
            marginRight={10}
            minHeight={'500px'}
            width={'60%'}>
            <Box border={'1px'} borderRadius={10} height="auto" padding={3}>
              <HStack>
                <Box {...template.body.title}>
                  <Text>
                    <FormattedMessage
                      id="delivery-orders.template.type"
                      description="Type label"
                      defaultMessage="TIPO"
                    />
                  </Text>
                </Box>
                <Box {...template.body.title} textAlign={'left'}>
                  <Text>
                    <FormattedMessage
                      id="delivery-orders.template.reference"
                      description="Reference label"
                      defaultMessage="REFERENCIA"
                    />
                  </Text>
                </Box>
                <Box {...template.body.title} textAlign={'center'}>
                  <Text>
                    <FormattedMessage
                      id="delivery-orders.template.quantity"
                      description="Quantity label"
                      defaultMessage="CANTIDAD"
                    />
                  </Text>
                </Box>
              </HStack>
              {assetTypes.map((assetType) => (
                <HStack key={assetType.type.id}>
                  <Box {...template.body.row}>
                    <Text fontSize={'xs'}>{getType(assetType.type)}</Text>
                  </Box>
                  <Box {...template.body.row}>
                    <Text fontSize={'xs'}>
                      {`${getContainerType(assetType.type)} ${
                        assetType.type.ticker
                      }`}
                    </Text>
                  </Box>
                  <Box {...template.body.row} textAlign={'center'}>
                    <Text fontSize={'xs'}>{assetType.quantity}</Text>
                  </Box>
                </HStack>
              ))}
              <HStack>
                <Box {...template.body.row} fontWeight="extrabold">
                  <Text fontSize={'xs'}>
                    <FormattedMessage
                      id="delivery-orders.template.total"
                      description="Total label"
                      defaultMessage="TOTAL"
                    />
                  </Text>
                </Box>
                <Box {...template.body.row} />
                <Box
                  {...template.body.row}
                  fontWeight="extrabold"
                  textAlign={'center'}>
                  <Text fontSize={'xs'}>
                    {assetTypes.reduce(
                      (partialSum, a) => partialSum + a.quantity,
                      0
                    )}
                  </Text>
                </Box>
              </HStack>
            </Box>
          </Box>
          <Box
            textAlign="center"
            minHeight={'500px'}
            marginRight={10}
            width={'40%'}>
            <Text {...template.body.labelSignature}>
              <FormattedMessage
                id="delivery-orders.template.orderQR"
                description="Order QR label"
                defaultMessage="QR DE LA ORDEN"
              />
            </Text>
            <QR />
            <Text>
              <FormattedMessage
                id="delivery-orders.template.signHere"
                description="Sign here label"
                defaultMessage="FIRMA AQUÍ"
              />
            </Text>
            <TriangleDownIcon marginBottom={10} />
            <Box marginTop={5}>
              <hr style={template.body.lineDashed} />
              <Text
                {...template.body.labelSignature}
                textAlign={'center'}
                fontSize={'xs'}>
                <FormattedMessage
                  id="delivery-orders.template.signReceiver"
                  description="Signature receiver label"
                  defaultMessage="FIRMA DE QUIEN RECIBE"
                />
              </Text>
            </Box>
            <Box marginTop={20}>
              <hr style={template.body.lineDashed} />
              <Text
                {...template.body.labelSignature}
                textAlign={'center'}
                fontSize={'xs'}>
                <FormattedMessage
                  id="delivery-orders.template.signDeliverer"
                  description="Signature deliverer label"
                  defaultMessage="FIRMA DE QUIEN ENTREGA"
                />
              </Text>
            </Box>
          </Box>
        </HStack>
      </Box>
    )
  }

  return (
    <Box
      paddingLeft={5}
      paddingTop={5}
      ref={innerRef}
      color={'black'}
      height={window.screen.height * 0.75}>
      <Header />
      <Body />
      <Footer />
    </Box>
  )
}

export default TemplatePdfDeliveryOrder

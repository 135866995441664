import { Button } from '@chakra-ui/button'
import { useDisclosure } from '@chakra-ui/hooks'
import { AddIcon } from '@chakra-ui/icons'
import { Heading } from '@chakra-ui/layout'
import { Center, Spinner } from '@chakra-ui/react'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import PartnerModal from '../features/partners/PartnerModal'
import PartnersList from '../features/partners/PartnersList'
import { usePartnersList } from '../features/partners/queries'

const Partners = () => {
  const { data, isLoading } = usePartnersList([], 0, 100)

  const { onOpen, onClose, isOpen } = useDisclosure()

  return (
    <>
      <Heading>
        <FormattedMessage
          id="partners"
          description="Partners screen title"
          defaultMessage="Partners"
        />
      </Heading>
      <Button
        leftIcon={<AddIcon />}
        colorScheme="teal"
        my="3"
        onClick={() => onOpen()}>
        <FormattedMessage
          id="partners.button.new"
          description="Partners new label"
          defaultMessage="New"
        />
      </Button>
      {isLoading ? (
        <Center>
          <Spinner size="lg" />
        </Center>
      ) : (
        <PartnersList partners={data?.data ?? []} />
      )}
      <PartnerModal isOpenPartner={isOpen} onClosePartner={onClose} />
    </>
  )
}

export default Partners

import { Box, Flex, HStack, Image, Spacer, Text } from '@chakra-ui/react'
import moment from 'moment'
import 'moment/locale/es'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import QRCode from 'react-qr-code'
import infoContactUs from '../../../constants/InfoContactUs'
import { template } from './theme'

export type TemplatePdfOrdersInvoiceProps = {
  innerRef: any
  id?: string
  numOrders: number
  unitPriceOrder: number
  beginDate: Date
  endDate: Date
  partnerName: string
  branchName: string
  nit: string
}

const TemplatePdfOrdersInvoice = ({
  innerRef,
  id,
  numOrders,
  unitPriceOrder,
  beginDate,
  endDate,
  partnerName,
  branchName,
  nit,
}: TemplatePdfOrdersInvoiceProps) => {
  const intl = useIntl()
  const period = () => {
    moment.locale('es')
    return (
      moment(beginDate).format('MMMM DD - YYYY') +
      ' - ' +
      moment(endDate).format('MMMM DD - YYYY')
    )
  }

  const total = () => {
    const totalValue = numOrders * unitPriceOrder
    return '$' + totalValue.toFixed(0)
  }

  const LineHeader = (props: any) => {
    return <Box {...template.header.line} {...props}></Box>
  }

  const Header = () => {
    return (
      <>
        <LineHeader />
        <Text {...template.header.title}>Recibo</Text>
        <Text {...template.header.title}>consolidado</Text>
        <Text {...template.header.title}>de órdenes</Text>
        <HStack marginTop={5}>
          <Box {...template.header.boxFieldPeriod}>
            <Box height={5}>
              <Text {...template.header.label}>Periodo</Text>
            </Box>
            <Box height={10}>
              <Text
                {...template.header.text}
                textTransform="capitalize"
                noOfLines={2}>
                {period()}
              </Text>
            </Box>
          </Box>
          <Box {...template.header.boxFieldPartner}>
            <HStack>
              <Box>
                <Box height={5} {...template.header.boxFieldClient}>
                  <Text {...template.header.label}>Cliente</Text>
                </Box>
                <Box height={10}>
                  <Text {...template.header.text} noOfLines={2}>
                    {partnerName}
                  </Text>
                </Box>
              </Box>
              <Box>
                <Box height={5}>
                  <Text {...template.header.label}>NIT</Text>
                </Box>
                <Box height={10}>
                  <Text {...template.header.text} noOfLines={2}>
                    {nit}
                  </Text>
                </Box>
              </Box>
            </HStack>
          </Box>
          <Box {...template.header.boxFieldReceive}>
            <Box height={5}>
              <Text {...template.header.label}>Tienda</Text>
            </Box>
            <Box height={10}>
              <Text {...template.header.text} noOfLines={2}>
                {branchName}
              </Text>
            </Box>
          </Box>
        </HStack>
      </>
    )
  }

  const Footer = () => {
    return (
      <Box {...template.footer.box} position={'fixed'}>
        <Flex>
          <Spacer></Spacer>
          <LineHeader marginEnd={'20px'} />
        </Flex>
        <Flex>
          <Box alignSelf={'center'}>
            <Image src="../images/xiclo.png" width={'150px'} />
          </Box>
          <Spacer></Spacer>
          <Box {...template.footer.label} textAlign={'right'}>
            <Text>{infoContactUs.email}</Text>
            <Text>
              <FormattedMessage
                id="delivery-orders.template.mobile"
                description="Mobile label"
                defaultMessage="Móvil: "
              />
              {infoContactUs.phone}
            </Text>
          </Box>
          <Box {...template.footer.label} textAlign={'right'}>
            <Text>{infoContactUs.pageWeb}</Text>
            <Text>
              <FormattedMessage
                id="delivery-orders.template.nit"
                description="Nit label"
                defaultMessage="NIT: "
              />
              {infoContactUs.nit}
            </Text>
          </Box>
        </Flex>
      </Box>
    )
  }

  const QR = () => {
    if (id) {
      return (
        <QRCode
          title="title"
          value={id}
          style={{ margin: 'auto', marginBottom: 5 }}
          size={150}
        />
      )
    }
    return null
  }

  const Body = () => {
    return (
      <Box paddingRight={5}>
        <Text {...template.body.pricePerOrder}>
          VALOR X ORDEN: {'   '} {unitPriceOrder} + IVA
        </Text>
        <HStack>
          <Box
            display="inline-block"
            verticalAlign={'top'}
            textAlign={'left'}
            marginRight={10}
            minHeight={'500px'}
            width={'60%'}>
            <Box border={'1px'} borderRadius={10} height="auto" padding={3}>
              <HStack marginBottom={5}>
                <Box
                  {...template.body.title}
                  textAlign={'left'}
                  fontWeight="extrabold">
                  <Text># DE ÓRDENES</Text>
                </Box>
                <Box
                  {...template.body.title}
                  textAlign={'center'}
                  fontWeight="extrabold">
                  <Text>V. TOTAL</Text>
                </Box>
              </HStack>
              <HStack>
                <Box {...template.body.values} textAlign={'left'}>
                  <Text>{numOrders}</Text>
                </Box>
                <Box {...template.body.values} textAlign={'center'}>
                  <Text>{total()}</Text>
                </Box>
              </HStack>
            </Box>
          </Box>
          <Box
            textAlign="center"
            minHeight={'500px'}
            marginRight={10}
            width={'40%'}>
            <Text {...template.body.labelSignature}>QR DEL RECIBO</Text>
            <QR />
          </Box>
        </HStack>
      </Box>
    )
  }

  return (
    <Box
      paddingLeft={5}
      paddingTop={5}
      ref={innerRef}
      color={'black'}
      height={window.screen.height * 0.75}>
      <Header />
      <Body />
      <Footer />
    </Box>
  )
}

export default TemplatePdfOrdersInvoice

import { Heading } from '@chakra-ui/layout'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import DeliveryOrdersList from '../features/delivery-orders/DeliveryOrdersList'

const DeliveryOrders = () => {
  return (
    <>
      <Heading>
        <FormattedMessage
          id="delivery-orders"
          defaultMessage="Delivery Orders"
          description="Delivery Orders screen title"
        />
      </Heading>
      <DeliveryOrdersList />
    </>
  )
}

export default DeliveryOrders

import { useMutation, useQuery, useQueryClient } from 'react-query'
import useXicloClient, { XicloResource } from '../common/useXicloClient'
import { QrDimension } from './QRDimension.entity'

export const qrDimensionKeys = {
  all: ['qrs-dimensions'] as const,
  lists: () => [...qrDimensionKeys.all, 'list'] as const,
  list: () => [...qrDimensionKeys.lists()] as const,
}

const findAll = async () => {
  const client = await useXicloClient(XicloResource.QrDimension)
  const { data } = await client.get<QrDimension[]>('/')

  return data
}

export const useQrDimensionsList = () => {
  return useQuery<QrDimension[], Error>(qrDimensionKeys.list(), () => findAll())
}

const create = async (qrDimension: Partial<QrDimension>) => {
  const client = await useXicloClient(XicloResource.QrDimension)
  const { data } = await client.post<QrDimension>('/', qrDimension)

  return data
}

export const useCreateQrDimension = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (qrDimension: Partial<QrDimension>) => create(qrDimension),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(qrDimensionKeys.lists())
      },
    }
  )
}

const update = async (qrDimension: Partial<QrDimension>) => {
  const client = await useXicloClient(XicloResource.QrDimension)
  const { data } = await client.patch<QrDimension>(
    `/${qrDimension.id}`,
    qrDimension
  )

  return data
}

export const useUpdateQrDimension = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (qrDimension: Partial<QrDimension>) => update(qrDimension),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(qrDimensionKeys.lists())
      },
    }
  )
}

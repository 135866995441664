import { useToast } from '@chakra-ui/react'
import { useIntl } from 'react-intl'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Filter } from '../common/Filter.entity'
import PaginatedResult from '../common/PaginatedResult.entity'
import useXicloClient, { XicloResource } from '../common/useXicloClient'
import { Branch, CreateBranchDto } from './Branch.entity'

export const brancheKeys = {
  all: ['branches'] as const,
  lists: () => [...brancheKeys.all, 'list'] as const,
  list: (filters: Filter[], page: number, limit: number) =>
    [...brancheKeys.lists(), { filters, page, limit }] as const,
  listIndicators: () => [...brancheKeys.all, 'listIndicators'] as const,
}

const findAll = async (
  filters: Filter[],
  page: number,
  limit: number,
  includeInvisible: boolean
) => {
  const offset = limit * page

  let params: { [key: string]: any } = {
    limit,
    offset,
    includeInvisible,
  }

  filters.forEach(({ id, value }) => {
    params[id] = value
  })

  const client = await useXicloClient(XicloResource.Branches)
  const { data } = await client.get<PaginatedResult<Branch>>('/', {
    params,
  })

  return data
}

export const useBranchesList = (
  filters: Filter[],
  page: number,
  limit = 10,
  includeInvisible = true
) => {
  return useQuery<PaginatedResult<Branch>, Error>(
    brancheKeys.list(filters, page, limit),
    () => findAll(filters, page, limit, includeInvisible),
    { keepPreviousData: true }
  )
}

const create = async (branch: CreateBranchDto) => {
  const client = await useXicloClient(XicloResource.Branches)
  const { data } = await client.post<Branch>(`/`, branch)
  return data
}

export const useCreateBranch = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const intl = useIntl()

  const createdSuccessfullMessage = intl.formatMessage({
    id: 'branches.createdSuccessfully',
    description: 'Branch created successfully toast',
    defaultMessage: 'Branch created successfully',
  })

  return useMutation((branch: CreateBranchDto) => create(branch), {
    onSuccess: () => {
      // Esto se puede mejorar utilizando setQueryData
      queryClient.invalidateQueries(brancheKeys.lists())
      toast({
        title: createdSuccessfullMessage,
        status: 'success',
        isClosable: true,
        position: 'top-right',
      })
    },
    onError: () => {
      toast({
        title: 'Error updating asset, try again later',
        status: 'error',
        isClosable: true,
        position: 'top-right',
      })
    },
  })
}

const update = async (branch: Partial<Branch>) => {
  const client = await useXicloClient(XicloResource.Branches)
  const { data } = await client.patch<Branch>(`${branch.id}`, branch)
  return data
}

export const useUpdateBranch = () => {
  const queryClient = useQueryClient()
  const intl = useIntl()
  const toast = useToast()

  const updatedSuccessfullMessage = intl.formatMessage({
    id: 'branches.updatedSuccessfully',
    description: 'Branch updated successfully toast',
    defaultMessage: 'Branch updated successfully',
  })

  return useMutation((branch: Partial<Branch>) => update(branch), {
    onSuccess: () => {
      // Esto se puede mejorar utilizando setQueryData
      queryClient.invalidateQueries(brancheKeys.lists())

      toast({
        title: updatedSuccessfullMessage,
        status: 'success',
        isClosable: true,
        position: 'top-right',
      })
    },
    onError: () => {
      toast({
        title: 'Error creating asset, try again later',
        status: 'error',
        isClosable: true,
        position: 'top-right',
      })
    },
  })
}

const findBranchesWithIndicators = async () => {
  const client = await useXicloClient(XicloResource.Branches)
  const { data } = await client.get<Branch[]>('/all/config-indicators', {})

  return data
}

export const useBranchesWithIndicatorsList = () => {
  return useQuery<Branch[], Error>(
    brancheKeys.listIndicators(),
    () => findBranchesWithIndicators(),
    { keepPreviousData: true }
  )
}

import { useDisclosure } from '@chakra-ui/react'
import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router'
import { Column } from 'react-table'
import SortableDataTable from '../common/SortableDataTable'
import { AssetType } from './AssetType.entity'
import AssetTypeModal from './AssetTypeModal'

export type AssetTypeslistProps = {
  assetTypes: AssetType[]
}

const AssetTypesList = ({ assetTypes }: AssetTypeslistProps) => {
  const location: any = useLocation()
  const intl = useIntl()
  const { isOpen, onOpen } = useDisclosure()
  const [selectedAssetType, setSelectedAssetType] = useState<AssetType>()

  const columns = useMemo<Column<AssetType>[]>(
    () => [
      {
        Header: intl.formatMessage({
          id: 'asset-types.brand',
          description: 'Brand attribute of an asset type',
          defaultMessage: 'Brand',
        }),
        accessor: (assetType) => assetType.brand.name,
      },
      {
        Header: intl.formatMessage({
          id: 'asset-types.ticker',
          description: 'Ticker attribute of an asset type',
          defaultMessage: 'Ticker',
        }),
        accessor: 'ticker',
      },
      {
        Header: intl.formatMessage({
          id: 'asset-types.type',
          description: 'Type attribute of an asset type',
          defaultMessage: 'Type',
        }),
        accessor: 'type',
      },
      {
        Header: intl.formatMessage({
          id: 'asset-types.containerType',
          description: 'Container type attribute of an asset type',
          defaultMessage: 'Container Type',
        }),
        accessor: 'containerType',
      },
      {
        Header: intl.formatMessage({
          id: 'asset-types.dimension',
          description: 'Dimension attribute of an asset type',
          defaultMessage: 'Dimension (Height,Width,Length)',
        }),
        accessor: (assetType) => {
          return `${assetType.dimension.height}, ${assetType.dimension.width}, ${assetType.dimension.length}`
        },
      },
      {
        Header: intl.formatMessage({
          id: 'asset-types.volume',
          description: 'Volume attribute of an asset type',
          defaultMessage: 'Volume',
        }),
        accessor: 'volume',
      },
      {
        Header: intl.formatMessage({
          id: 'asset-types.material',
          description: 'Material attribute of an asset type',
          defaultMessage: 'Material',
        }),
        accessor: (assetType) => assetType.materialType,
      },
      {
        Header: intl.formatMessage({
          id: 'asset-types.formlabel.qrDimension',
          description: 'QR dimension attribute of an asset type',
          defaultMessage: 'QR dimension',
        }),
        accessor: (assetType) =>
          `${assetType.qrDimension.name} (${assetType.qrDimension.width}cm x ${assetType.qrDimension.height}cm)`,
      },
    ],
    []
  )

  const editAssetType = (assetType: AssetType) => {
    setSelectedAssetType(assetType)
    onOpen()
  }

  const getFilter = () => {
    const assetTypeId = location?.state?.assetType
    return assetTypeId ? [{ id: 'id', value: assetTypeId }] : []
  }

  const onClose = () => {
    setSelectedAssetType(undefined)
  }

  return (
    <>
      <SortableDataTable
        data={assetTypes}
        columns={columns}
        onRowSelected={editAssetType}
        currentPage={0}
        totalCount={assetTypes.length}
        paginationAuto={false}
        filterAuto={false}
        filtersInit={getFilter()}
      />
      {selectedAssetType ? (
        <AssetTypeModal
          assetType={selectedAssetType}
          isOpen={isOpen}
          onClose={onClose}
        />
      ) : null}
    </>
  )
}

export default AssetTypesList

import { AddIcon } from '@chakra-ui/icons'
import { Button, Heading, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import BranchList from '../features/branches/BranchList'
import BranchModal from '../features/branches/BranchModal'

const Branches = () => {
  const { onOpen, onClose, isOpen } = useDisclosure()

  return (
    <>
      <Heading>
        <FormattedMessage
          id="branches"
          defaultMessage="Branches"
          description="Branches title screen"
        />
      </Heading>
      <Button
        leftIcon={<AddIcon />}
        colorScheme="teal"
        my="3"
        onClick={() => onOpen()}>
        <FormattedMessage
          id="assets.button.new"
          description="Assets new label"
          defaultMessage="New"
        />
      </Button>
      <BranchList />
      <BranchModal isOpenBranch={isOpen} onCloseBranch={onClose} />
    </>
  )
}

export default Branches

import { AssetType } from '../asset-types/AssetType.entity'

export enum RolloutPhase {
  Testing = 'testing',
  Released = 'released',
}

export interface Partner {
  id?: string
  nit: string
  name: string
  shortDescription: string
  description: string
  coverageImageUrl?: string
  website?: string
  mainImage?: string
  isVisible: boolean
  rolloutPhase: RolloutPhase
  appNotes: string
  appNotesLink: string
  unitPriceOrder: number
  unitPriceCycle: number
  assetTypePrices: AssetTypePriceConfig[]
  createdAt: Date
  updatedAt: Date
}

export interface CreatePartnerDto {
  name: string
  nit: string
  mainImage?: string
  shortDescription: string
  description: string
  website?: string
  isVisible: boolean
  rolloutPhase: RolloutPhase
  appNotes: string
  appNotesLink: string
  coverageImageUrl?: string
  coverageImageFile?: any
  unitPriceOrder: number
  unitPriceCycle: number
  assetTypePrices: AssetTypePriceConfig[]
}

export interface AssetTypePriceConfig {
  id?: string
  assetType?: AssetType
  minAmount?: number
  maxAmount?: number
  price?: number
}

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Progress,
  Select,
  SimpleGrid,
  Spacer,
  Stack,
  Switch,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import ReactSelect from 'react-select'
import { Column } from 'react-table'
import { useAssetTypesList } from '../asset-types/queries'
import { Filter } from '../common/Filter.entity'
import SortableDataTable from '../common/SortableDataTable'
import { Partner } from '../partners/Partner.entity'
import { usePartnersList } from '../partners/queries'
import {
  AssetTypeConfig,
  Branch,
  BranchType,
  CreateBranchDto,
  Point,
} from './Branch.entity'
import { useCreateBranch, useUpdateBranch } from './queries'

export type BranchModalProps = {
  branch?: Branch
  isOpenBranch: boolean
  onCloseBranch: () => void
}

const BranchModal = ({
  branch,
  onCloseBranch,
  isOpenBranch,
}: BranchModalProps) => {
  const intl = useIntl()
  const createBranch = useCreateBranch()
  const [partnerFilters, setPartnerFilters] = useState<Filter[]>([])
  const updateBranch = useUpdateBranch()
  const partnersQuery = usePartnersList(partnerFilters, 0, 50)
  const assetTypes = useAssetTypesList()
  const [selectedAssetType, setSelectedAssetType] = useState<AssetTypeConfig>()
  const { isOpen, onOpen } = useDisclosure()
  const [uploadingPercentage, setUploadingPercentage] = useState<
    number | undefined
  >(undefined)
  const toast = useToast()

  const newBranchTitleMessage = intl.formatMessage({
    id: 'branches.new',
    description: 'New branch title message',
    defaultMessage: 'New branch',
  })
  const createdSuccessfullMessage = intl.formatMessage({
    id: 'branches.createdSuccessfully',
    description: 'Branch created successfully toast',
    defaultMessage: 'Branch created successfully',
  })
  const updatedSuccessfullMessage = intl.formatMessage({
    id: 'branches.updatedSuccessfully',
    description: 'Branch updated successfully toast',
    defaultMessage: 'Branch updated successfully',
  })

  const branchTypes = Object.values(BranchType)
  const title = branch ? branch.name : newBranchTitleMessage

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm<CreateBranchDto>({ defaultValues: branch })

  const columnsAssetsType = useMemo<Column<AssetTypeConfig>[]>(
    () => [
      {
        Header: intl.formatMessage({
          id: 'asset-types',
          description: 'Asset types label',
          defaultMessage: 'Asset types label',
        }),
        accessor: (assetTypeConfig) =>
          `${assetTypeConfig?.assetType?.brand.ticker}, ${assetTypeConfig?.assetType?.ticker}, ${assetTypeConfig?.assetType?.type}, ${assetTypeConfig?.assetType?.containerType} (${assetTypeConfig?.assetType?.dimension.height}, ${assetTypeConfig?.assetType?.dimension.width}, ${assetTypeConfig?.assetType?.dimension.length})`,
      },
      {
        Header: intl.formatMessage({
          id: 'asset-types.min',
          description: 'Minimum associated to asset type',
          defaultMessage: 'Minimum',
        }),
        accessor: 'min',
      },
      {
        Header: intl.formatMessage({
          id: 'asset-types.max',
          description: 'Maximum associated to asset type',
          defaultMessage: 'Maximum',
        }),
        accessor: 'max',
      },
    ],
    [selectedAssetType]
  )

  const onSubmit: SubmitHandler<CreateBranchDto> = async (data) => {
    if (data.coverageImageFile && data.coverageImageFile.length) {
      uploadImage(data)
    } else if (branch) {
      updateBranch.mutate(data)
    } else {
      createBranch.mutate(data)
    }
  }

  const uploadImage = (data: CreateBranchDto) => {
    const file = data.coverageImageFile[0]
    const storage = getStorage()
    const fileNameComponents = file.name.split('.')
    const extension = fileNameComponents[fileNameComponents.length - 1]
    const kebabCaseBranchName = _.kebabCase(data.name)
    const storageRef = ref(
      storage,
      `/public/branches/images/coverage_${kebabCaseBranchName}.${extension}`
    )
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )

        setUploadingPercentage(progress)
      },
      (error) => {
        toast({
          title: 'Error updloading image, try again later',
          status: 'error',
          isClosable: true,
          position: 'top-right',
        })
      },
      async () => {
        const url = await getDownloadURL(uploadTask.snapshot.ref)
        setValue('coverageImageUrl', url)
        setUploadingPercentage(undefined)
        data.coverageImageUrl = url

        if (branch) {
          updateBranch.mutate(data)
        } else {
          createBranch.mutate(data)
        }
      }
    )
  }

  const onPartnerSelected = (partner: Partner) => {
    setValue('partner', partner)
  }

  const setCoordinates = (coordinates: string) => {
    const coordinatesArray = coordinates.split(',').map((coor) => Number(coor))

    const point: Point = {
      type: 'Point',
      coordinates: coordinatesArray,
    }

    setValue('location.point', point)
  }

  const onCloseAssetTypeConfig = () => {
    setSelectedAssetType(undefined)
  }

  const selectAssetTypeConfig = (assetTypeId: string) => {
    setSelectedAssetType({
      ...selectedAssetType,
      assetType: assetTypes.data?.find(
        (assetType) => assetType.id == assetTypeId
      ),
      min: 0,
    })
    onOpen()
  }

  const editAssetTypeConfig = (assetType: AssetTypeConfig) => {
    setSelectedAssetType(assetType)
    onOpen()
  }

  const deleteAssetTypeConfig = (assetType: AssetTypeConfig) => {
    if (branch) {
      var index = branch?.assetTypes.indexOf(assetType)
      if (index !== undefined && index > -1) {
        branch?.assetTypes.splice(index, 1)
        reset(branch)
      }
      onCloseAssetTypeConfig()
    }
  }

  useEffect(() => {
    if (createBranch.isSuccess || updateBranch.isSuccess) {
      onCloseBranch()
    }
  }, [createBranch.isSuccess, updateBranch.isSuccess])

  return (
    <>
      <Modal size="3xl" isOpen={isOpenBranch} onClose={onCloseBranch}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              <Stack spacing="3">
                <SimpleGrid columns={[1, 2, 2]} spacing="2">
                  <FormControl
                    id="name"
                    isRequired
                    isInvalid={errors.name !== undefined}>
                    <FormLabel>
                      <FormattedMessage
                        id="partners.formlabel.name"
                        description="Partners name form label"
                        defaultMessage="Name"
                      />
                    </FormLabel>
                    <Input {...register('name', { required: true })} />
                  </FormControl>
                  <FormControl
                    id="pin"
                    isRequired
                    isInvalid={errors.pin !== undefined}>
                    <FormLabel>
                      <FormattedMessage
                        id="partners.formlabel.pin"
                        description="Partners pin form label"
                        defaultMessage="Pin"
                      />
                    </FormLabel>
                    <Input {...register('pin', { required: true })} />
                  </FormControl>
                </SimpleGrid>
                <SimpleGrid columns={[1, 2, 2]} spacing="2">
                  <FormControl
                    id="zipCode"
                    isRequired
                    isInvalid={errors.location?.zipCode !== undefined}>
                    <FormLabel>
                      <FormattedMessage
                        id="partners.formlabel.zipCode"
                        description="Partners zipCode form label"
                        defaultMessage="Zip Code"
                      />
                    </FormLabel>
                    <Input
                      defaultValue={branch?.location.zipCode}
                      {...register('location.zipCode')}
                    />
                  </FormControl>
                  <FormControl
                    id="address"
                    isRequired
                    isInvalid={errors.location?.address !== undefined}>
                    <FormLabel>
                      <FormattedMessage
                        id="partners.formlabel.address"
                        description="Partners address form label"
                        defaultMessage="Address"
                      />
                    </FormLabel>
                    <Input
                      {...register('location.address', { required: true })}
                    />
                  </FormControl>
                </SimpleGrid>
                <SimpleGrid columns={[1, 2, 2]} spacing="2">
                  <FormControl
                    id="additionalInfo"
                    isRequired
                    isInvalid={errors.location?.additionalInfo !== undefined}>
                    <FormLabel>
                      <FormattedMessage
                        id="partners.formlabel.addiotionalInfo"
                        description="Partners addiotionalInfo form label"
                        defaultMessage="Additional info"
                      />
                    </FormLabel>
                    <Input {...register('location.additionalInfo')} />
                  </FormControl>
                  <FormControl
                    id="point.coordinates"
                    isRequired
                    isInvalid={
                      errors.location?.point?.coordinates !== undefined
                    }>
                    <FormLabel>
                      <FormattedMessage
                        id="partners.formlabel.coordinates"
                        description="Partners coordinates form label"
                        defaultMessage="Coordinates"
                      />
                    </FormLabel>
                    <Input
                      placeholder="<lattitude>,<longitude>"
                      defaultValue={branch?.location.point.coordinates?.join(
                        ','
                      )}
                      onChange={(e) => setCoordinates(e.target.value)}
                      value={getValues()?.location?.point?.coordinates?.join(
                        ','
                      )}
                    />
                    <FormHelperText>
                      <FormattedMessage
                        id="partners.helpText.coordinates"
                        description="Help text for the coordinates form label"
                        defaultMessage='Separate each coordinate by a comma ",". Ex: 2.1100,-24.0000'
                      />
                    </FormHelperText>
                  </FormControl>
                </SimpleGrid>
                <SimpleGrid columns={[1, 2, 2]} spacing="2">
                  <FormControl
                    id="phoneNumbers"
                    isRequired
                    isInvalid={errors.location?.phoneNumbers !== undefined}>
                    <FormLabel>
                      <FormattedMessage
                        id="partners.formlabel.phoneNumbers"
                        description="Partners phoneNumbers form label"
                        defaultMessage="Phone numbers"
                      />
                    </FormLabel>
                    <Input
                      {...register('location.phoneNumbers', { required: true })}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>
                      <FormattedMessage
                        id="branch.partner"
                        description="Partner input form"
                        defaultMessage="Partner"
                      />
                    </FormLabel>
                    <ReactSelect
                      cacheOptions
                      defaultOptions
                      isClearable
                      defaultValue={
                        branch?.partner && {
                          value: branch.partner,
                          label: branch.partner.name,
                        }
                      }
                      onChange={(option) => {
                        if (option?.value) {
                          onPartnerSelected(option.value)
                        }
                      }}
                      onInputChange={(value) => {
                        setPartnerFilters([{ id: 'name', value }])
                      }}
                      options={
                        partnersQuery.data?.data.map((partner) => ({
                          value: partner,
                          label: partner.name,
                        })) ?? []
                      }
                      styles={{
                        input: (provided, _state) => ({
                          ...provided,
                          color: 'inherit',
                        }),
                        control: (provided, _state) => ({
                          ...provided,
                          background: 'inherit',
                          border:
                            '1px solid var(--chakra-colors-whiteAlpha-300)',
                          borderColor: 'inherit',
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: 'inherit',
                        }),
                        menu: (provided, _state) => ({
                          ...provided,
                          backgroundColor: 'var(--chakra-colors-gray-700)',
                        }),
                        option: (provided, _state) => ({
                          ...provided,
                          backgroundColor: 'var(--chakra-colors-gray-700)',
                          cursor: 'pointer',
                        }),
                      }}
                    />
                  </FormControl>
                </SimpleGrid>
                <SimpleGrid columns={[1, 2, 2]} spacing="2">
                  <FormControl
                    id="country"
                    isRequired
                    isInvalid={errors.location?.country !== undefined}>
                    <FormLabel>
                      <FormattedMessage
                        id="partners.formlabel.country"
                        description="Partners country form label"
                        defaultMessage="Country"
                      />
                    </FormLabel>
                    <Input
                      {...register('location.country', { required: true })}
                    />
                  </FormControl>
                  <FormControl
                    id="city"
                    isRequired
                    isInvalid={errors.location?.city !== undefined}>
                    <FormLabel>
                      <FormattedMessage
                        id="partners.formlabel.city"
                        description="Partners city form label"
                        defaultMessage="City"
                      />
                    </FormLabel>
                    <Input
                      {...register('location.city', {
                        required: true,
                      })}
                    />
                  </FormControl>
                </SimpleGrid>
                <FormControl
                  id="coverageImageFile"
                  isRequired
                  isInvalid={errors.coverageImageFile !== undefined}>
                  <FormLabel>
                    <FormattedMessage
                      id="partners.formlabel.coverageImageFile"
                      description="Partners coverageImageFile form label"
                      defaultMessage="Coverage Image"
                    />
                  </FormLabel>
                  <Input
                    {...register('coverageImageFile')}
                    type="file"
                    accept="/image/*"
                    mb={6}
                  />
                  {branch?.coverageImageUrl && (
                    <Image
                      boxSize={300}
                      objectFit="contain"
                      src={branch.coverageImageUrl}
                    />
                  )}
                </FormControl>
                <HStack spacing="2">
                  <FormControl
                    isRequired
                    isInvalid={errors.isMain !== undefined}>
                    <FormLabel htmlFor="isMain">
                      <FormattedMessage
                        id="partners.formlabel.isMain"
                        description="Partners isMain form label"
                        defaultMessage="Main branch"
                      />
                    </FormLabel>
                    <Switch id="isMain" size="lg" {...register('isMain')} />
                  </FormControl>
                  <FormControl
                    isRequired
                    isInvalid={errors.isVisible !== undefined}>
                    <FormLabel htmlFor="isVisible">
                      <FormattedMessage
                        id="partners.formlabel.isVisible"
                        description="Partners isVisible form label"
                        defaultMessage="Visible branch"
                      />
                    </FormLabel>
                    <Switch
                      id="isVisible"
                      size="lg"
                      {...register('isVisible')}
                    />
                  </FormControl>
                  <FormControl
                    id="branchType"
                    isRequired
                    isInvalid={errors.branchType !== undefined}>
                    <FormLabel>
                      <FormattedMessage
                        id="partners.formlabel.type"
                        description="Partners type form label"
                        defaultMessage="Type"
                      />
                    </FormLabel>
                    <Select
                      placeholder="Select a type"
                      {...register('branchType', {
                        required: true,
                      })}>
                      {branchTypes.map((type) => (
                        <option key={type} value={type}>
                          {_.capitalize(type)}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </HStack>
              </Stack>
              <Stack>
                <Accordion allowMultiple>
                  <AccordionItem
                    marginY="5"
                    borderColor="gray.600"
                    border="1px"
                    borderRadius="5">
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        <FormattedMessage
                          id="asset-types"
                          defaultMessage="Assets type"
                          description="Assets type title"
                        />
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                      <HStack>
                        <Select
                          placeholder={intl.formatMessage({
                            id: 'branches.asset.type.config.select',
                            description: 'Select asset type message',
                            defaultMessage: 'Select asset type',
                          })}
                          onChange={(event) =>
                            selectAssetTypeConfig(event.target.value)
                          }>
                          {assetTypes.data?.map((assetType) => (
                            <option key={assetType.id} value={assetType.id}>
                              {`${assetType.brand.ticker}, ${assetType.ticker}, ${assetType.type}, ${assetType.containerType} (${assetType.dimension.height}, ${assetType.dimension.width}, ${assetType.dimension.length})`}
                            </option>
                          ))}
                        </Select>
                      </HStack>
                      <SortableDataTable
                        data={branch?.assetTypes ?? []}
                        columns={columnsAssetsType}
                        paginationAuto={false}
                        onRowSelected={editAssetTypeConfig}
                      />
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Stack>
              <Progress size="sm" value={uploadingPercentage} />
            </ModalBody>
            <ModalFooter>
              <Button
                type="submit"
                colorScheme="teal"
                fontSize="md"
                isLoading={status === 'loading'}
                onClick={handleSubmit(onSubmit)}>
                <FormattedMessage
                  id="branches.button.save"
                  description="Branches save button"
                  defaultMessage="Save"
                />
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      {selectedAssetType ? (
        <Modal isOpen={isOpen} onClose={onCloseAssetTypeConfig} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <FormattedMessage
                id="branches.asset.type.config.add"
                description="Add asset type"
                defaultMessage="Add asset type"
              />
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody marginBottom={5}>
              <Text fontWeight={'bold'}>
                <FormattedMessage
                  id="branches.asset.type.config.minimum"
                  description="Minimum label for asset type config"
                  defaultMessage="Minimum"
                />
              </Text>
              <NumberInput
                name="minimum"
                defaultValue={0}
                min={0}
                value={selectedAssetType.min}
                onChange={(value) =>
                  setSelectedAssetType({
                    ...selectedAssetType,
                    min: parseInt(value),
                  })
                }>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Text fontWeight={'bold'}>
                <FormattedMessage
                  id="branches.asset.type.config.maximum"
                  description="Maximum label for asset type config"
                  defaultMessage="Maximum"
                />
              </Text>
              <NumberInput
                name="maximum"
                defaultValue={0}
                min={selectedAssetType.min}
                value={selectedAssetType.max}
                onChange={(value) =>
                  setSelectedAssetType({
                    ...selectedAssetType,
                    max: parseInt(value),
                  })
                }>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </ModalBody>
            <ModalFooter>
              <Button
                type="submit"
                colorScheme="teal"
                fontSize="md"
                hidden={
                  branch?.assetTypes.find(
                    (assetType) =>
                      assetType.assetType?.id == selectedAssetType.assetType?.id
                  ) == undefined
                }
                onClick={() => deleteAssetTypeConfig(selectedAssetType)}>
                <FormattedMessage
                  id="branches.asset.type.config.delete.button"
                  description="Delete button for asset type config"
                  defaultMessage="Delete"
                />
              </Button>
              <Spacer />
              <Button
                type="submit"
                colorScheme="teal"
                fontSize="md"
                isLoading={status === 'loading'}
                onClick={() => {
                  if (selectedAssetType && branch && selectedAssetType.max) {
                    if (!branch.hasOwnProperty('assetTypes'))
                      branch.assetTypes = []
                    var index = branch?.assetTypes.filter(
                      (a) => a.assetType?.id == selectedAssetType.assetType?.id
                    )

                    if (index !== undefined && index.length > 0) {
                      branch?.assetTypes.splice(
                        branch.assetTypes.indexOf(index[0]),
                        1
                      )
                      reset(branch)
                    }
                    branch.assetTypes.push(selectedAssetType)
                    console.log('branch.assetTypes ', branch.assetTypes)
                    reset(branch)
                    onCloseAssetTypeConfig()
                  }
                }}>
                <FormattedMessage
                  id="branches.asset.type.config.save.button"
                  description="Save button for asset type config"
                  defaultMessage="Save"
                />
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : null}
    </>
  )
}

export default BranchModal

import { useDisclosure } from '@chakra-ui/hooks'
import { DownloadIcon } from '@chakra-ui/icons'
import { Box, Button, Checkbox, Text, useToast } from '@chakra-ui/react'
import { saveAs } from 'file-saver'
import React, { useCallback, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { Column } from 'react-table'
import { ContainerType, Type } from '../asset-types/AssetType.entity'
import { Filter } from '../common/Filter.entity'
import SortableDataTable, {
  NumberColumnFilter,
  SelectColumnFilter,
} from '../common/SortableDataTable'
import { Asset, AssetLocation, AssetStatus } from './Asset.entity'
import AssetModal from './AssetModal'
import { useAssets, useAssetsCount, useGenerateQRs } from './queries'

const AssetsList = () => {
  const toast = useToast()

  const { isOpen, onOpen } = useDisclosure()
  const [selectedAsset, setSelectedAsset] = useState<Asset>()
  const [filters, setFilters] = useState<Filter[]>([])
  const [pageSize, setPageSize] = useState(10)
  const intl = useIntl()
  const [currentPage, setCurrentPage] = useState(0)
  const location: any = useLocation()
  const { isLoading, data } = useAssets(filters, currentPage, pageSize)
  const assetCountQuery = useAssetsCount(filters)

  const types = Object.values(Type)
  const assetLocations = Object.values(AssetLocation)
  const statuses = Object.values(AssetStatus)
  const containerTypes = Object.values(ContainerType)
  const [exportAssets, setExportAssets] = useState<string[]>([])
  const [isCheckAll, setIsCheckAll] = useState<boolean>(false)
  const geneatorQRs = useGenerateQRs()

  const setPage = useCallback(
    (pageIndex: number, pageSize: number) => {
      setCurrentPage(pageIndex)
      setPageSize(pageSize)
    },
    [currentPage, pageSize]
  )

  const selectAssetToExport = (asset: any) => {
    let index = exportAssets.findIndex((item) => item == asset.reference)
    if (index == -1) {
      setExportAssets([...exportAssets, asset.reference])
      exportAssets.push(asset.reference)
    } else {
      setExportAssets(exportAssets.filter((item) => item !== asset.reference))
    }
    onClose()
  }

  function selectAllAsset() {
    setIsCheckAll(!isCheckAll)
    if (!isCheckAll) {
      setExportAssets(data?.data.map((x) => x.reference)!)
    } else {
      setExportAssets([])
    }
  }

  const download = () => {
    if (exportAssets.length <= 0) {
      toast({
        title: intl.formatMessage({
          id: 'assets.download.error.notSelected',
          description: 'Download error not selected',
          defaultMessage: 'You must select at least one asset',
        }),
        status: 'warning',
        isClosable: true,
        position: 'top-right',
      })
      return
    }

    if (exportAssets.length == 1) {
      downloadInSVG(exportAssets[0])
      return
    }

    downloadInZip()
    getMessageSuccessDownload()
  }

  function getMessageSuccessDownload() {
    toast({
      title: intl.formatMessage({
        id: 'assets.download.success',
        description: 'Download success message',
        defaultMessage: 'The download was successful',
      }),
      status: 'success',
      isClosable: true,
      position: 'top-right',
    })
  }

  function getCheckBoxSelectAll() {
    return <Checkbox checked={isCheckAll} onChange={() => selectAllAsset()} />
  }

  function isChecked(asset: any) {
    return exportAssets.findIndex((item) => item == asset.reference) !== -1
  }

  const columns = useMemo<Column<Asset>[]>(
    () => [
      {
        Header: getCheckBoxSelectAll(),
        accessor: 'type',
        Cell: function Download({ row }) {
          return (
            <Checkbox
              isChecked={isChecked(row.original)}
              onChange={() => selectAssetToExport(row.original)}
            />
          )
        },
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: intl.formatMessage({
          id: 'assets.reference',
          description: 'Reference attribute of an asset',
          defaultMessage: 'Reference',
        }),
        accessor: 'reference',
        Cell: function Download({ row }) {
          return (
            <>
              <Box alignItems="center">
                <Text>{row.original.reference}</Text>
              </Box>
            </>
          )
        },
      },
      {
        Header: intl.formatMessage({
          id: 'assets.brand',
          description: 'Brand attribute of an asset',
          defaultMessage: 'Brand',
        }),
        accessor: (asset) => asset.type.brand.name,
        id: 'brand',
      },
      {
        Header: intl.formatMessage({
          id: 'assets.type',
          description: 'Type attribute of an asset',
          defaultMessage: 'Type',
        }),
        Filter: SelectColumnFilter,
        listOptions: types,
        accessor: (asset) => asset.type.type,
        id: 'assetType',
      },
      {
        Header: intl.formatMessage({
          id: 'assets.type.container',
          description: 'Container type attribute of an asset',
          defaultMessage: 'Type of container',
        }),
        Filter: SelectColumnFilter,
        listOptions: containerTypes,
        accessor: (asset) => asset.type.containerType,
        id: 'containerType',
      },
      {
        Header: intl.formatMessage({
          id: 'assets.currentCycle',
          description: 'currentCycle attribute of an asset',
          defaultMessage: 'Current Cycle',
        }),
        Filter: NumberColumnFilter,
        accessor: 'currentCycle',
      },
      {
        id: 'currentLocation',
        Header: intl.formatMessage({
          id: 'assets.currentLocation',
          description: 'currentLocation attribute of an asset',
          defaultMessage: 'Current Location',
        }),
        Filter: SelectColumnFilter,
        listOptions: assetLocations,
        accessor: (asset) => asset.currentLocation.type,
      },
      {
        Header: intl.formatMessage({
          id: 'assets.status',
          description: 'Status attribute of an asset',
          defaultMessage: 'Status',
        }),
        Filter: SelectColumnFilter,
        listOptions: statuses,
        accessor: 'status',
      },
      {
        id: 'qrDimension',
        Header: intl.formatMessage({
          id: 'assets.qrDimension',
          description: 'QR dimension attribute of an asset',
          defaultMessage: 'QR dimension',
        }),
        accessor: (asset) =>
          `${asset.type.qrDimension.name} (${asset.type.qrDimension.width}cm x ${asset.type.qrDimension.height}cm)`,
      },
    ],
    [data, exportAssets, isCheckAll]
  )

  const getAssetByReference = () => {
    const asset = location?.state?.asset
    return asset ? [{ id: 'reference', value: asset }] : []
  }

  const editAsset = (asset: Asset) => {
    setSelectedAsset(asset)
    onOpen()
  }

  const onClose = () => {
    setSelectedAsset(undefined)
  }

  const downloadInSVG = async (reference: string) => {
    const files = await geneatorQRs.mutateAsync([reference])
    files.forEach((file) => {
      let downloadLink = document.createElement('a')
      downloadLink.href = file.file
      downloadLink.download = file.reference
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      getMessageSuccessDownload()
      onClose()
    })
  }

  const downloadInZip = async () => {
    const files = await geneatorQRs.mutateAsync(exportAssets)
    const zip = require('jszip')()
    files.forEach((file) => {
      zip.file(
        `${file.reference}.svg`,
        file.file.replace('data:image/svg+xml;base64,', ''),
        { base64: true }
      )
    })

    var dateCurrent = new Date()
    zip.generateAsync({ type: 'blob' }).then((content: any) => {
      saveAs(
        content,
        `QRs-${
          dateCurrent.getDate().toString() +
          (dateCurrent.getMonth() + 1) +
          dateCurrent.getFullYear()
        }.zip`
      )
    })
  }

  return (
    <>
      <Box flex="1" textAlign="right" marginTop={-59}>
        <Button
          leftIcon={<DownloadIcon />}
          colorScheme="teal"
          my="3"
          onClick={() => download()}>
          <FormattedMessage
            id="assets.download.qr"
            description="Download QR label"
            defaultMessage="Download QR"
          />
        </Button>
      </Box>
      <SortableDataTable
        data={data?.data ?? []}
        columns={columns}
        setPage={setPage}
        setFilters={setFilters}
        loading={isLoading}
        currentPage={currentPage}
        onRowSelected={editAsset}
        totalCount={assetCountQuery.data}
        sizePage={pageSize}
        fontSize="smaller"
        paginationAuto={true}
        filterAuto={true}
        filtersInit={getAssetByReference()}
        enableFilters
      />
      {selectedAsset && (
        <AssetModal
          asset={selectedAsset}
          onClose={onClose}
          isOpen={isOpen}
          downloadInSVG={downloadInSVG}
        />
      )}
    </>
  )
}

export default AssetsList

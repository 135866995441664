import { Asset } from '../assets/Asset.entity'
import { Branch } from '../branches/Branch.entity'

export enum Role {
  Admin = 'admin',
  Customer = 'customer',
  BusinessPartner = 'business_partner',
  Cleaner = 'cleaner',
  Warehouse = 'warehouse',
}

export interface PaymentMethod {
  id: string
  brand: string
  expirationDate: string
  cardNumber: string
  isMain: boolean
  createdAt: Date
}

export interface UserToken {
  token: string
  isReferenced: boolean
  createdAt: Date
  expiresAt: Date
}

export interface User {
  id: string
  email: string
  token: string
  firebaseUUID: string
  emailVerified: boolean
  name?: string
  locale?: string
  phoneNumber?: string
  branches: Branch[]
  assets: Asset[]
  roles: Role[]
  paymentMethods?: PaymentMethod[]
  tokens?: UserToken[]
  createdAt: Date
}

export enum SocialProvider {
  Google = 'google',
}

import { getApps, initializeApp } from 'firebase/app'
import {
  Auth,
  createUserWithEmailAndPassword as firebaseCreateUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
  signInWithPopup,
  useAuthEmulator as authEmulator,
  User as FirebaseUser,
  UserCredential,
} from 'firebase/auth'
import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from 'react'
import { firebaseConfig } from '../constants/FirebaseConfig'
import ServerConstants from '../constants/Server'
import useXicloClient, {
  XicloResource,
} from '../features/common/useXicloClient'
import { User } from '../features/users/User.entity'

type AuthContextProps = {
  user?: User
  isInitializing: boolean
  getToken: () => Promise<string> | undefined
  signInWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<UserCredential>
  signInWithGoogle: () => void
  signOut: () => void
  createUserWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<UserCredential>
}

if (!firebaseConfig) {
  console.log('Firebase config not found.', firebaseConfig)
}

if (getApps().length === 0) {
  initializeApp(firebaseConfig)
}

const auth: Auth = getAuth()
auth.useDeviceLanguage()

if (ServerConstants.inDevelopment) {
  authEmulator(auth, 'http://localhost:9099')
}

const AuthProvider: FC = ({ children }) => {
  const [user, setUser] = useState<User>()
  const [isInitializing, setIsInitializing] = useState(true)

  const getToken = () => {
    return auth.currentUser?.getIdToken()
  }

  const signInWithEmailAndPassword = (email: string, password: string) => {
    return firebaseSignInWithEmailAndPassword(auth, email, password)
  }

  const createUserWithEmailAndPassword = (email: string, password: string) => {
    return firebaseCreateUserWithEmailAndPassword(auth, email, password)
  }

  const signInWithGoogle = () => {
    const googleProvider = new GoogleAuthProvider()
    signInWithPopup(auth, googleProvider)
  }

  const signOut = () => {
    setUser(undefined)
    return auth.signOut()
  }

  const onAuthStateChanged = async (userAuth: FirebaseUser | null) => {
    console.log('User Auth', userAuth)

    if (userAuth && userAuth.email) {
      try {
        const client = await useXicloClient(XicloResource.Users)
        const { data } = await client.get<User>('/me')

        if (data) {
          setUser(data)
        } else {
          throw new Error('User data not found')
        }
      } catch (error) {
        console.log('Error loading user', error)
        console.log('Error request', error.request)
      } finally {
        setIsInitializing(false)
      }
    } else {
      setIsInitializing(false)
    }
  }

  useEffect(() => {
    const subscriber = auth.onAuthStateChanged(
      (firebaseUser: FirebaseUser | null) => {
        onAuthStateChanged(firebaseUser)
      }
    )

    return subscriber
  }, [])

  return (
    <AuthContext.Provider
      value={{
        user,
        isInitializing,
        getToken,
        signInWithEmailAndPassword,
        createUserWithEmailAndPassword,
        signInWithGoogle,
        signOut,
      }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthContext = createContext<AuthContextProps>({
  getToken: () => undefined,
  signInWithEmailAndPassword: () => new Promise<UserCredential>(() => {}),
  createUserWithEmailAndPassword: () => new Promise<UserCredential>(() => {}),
  signInWithGoogle: () => {},
  signOut: () => {},
  isInitializing: true,
})

export const useAuth = () => useContext(AuthContext)

export default AuthProvider

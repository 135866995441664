import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/form-control'
import { Input } from '@chakra-ui/input'
import { Stack } from '@chakra-ui/layout'
import { Alert, AlertIcon, Button } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router'
import { useAuth } from '../../providers/AuthProvider'

type FormInput = {
  email: string
  password: string
}

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const LoginForm = () => {
  const [error, setError] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const intl = useIntl()
  const history = useHistory()

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormInput>()

  const { signInWithEmailAndPassword, user, isInitializing } = useAuth()

  const invalidEmailMessage = intl.formatMessage({
    id: 'auth.invalidEmail',
    description: 'Message when an invalid email was entered at login',
    defaultMessage: 'Invalid email',
  })

  const onSubmit = handleSubmit(async (data) => {
    const { email, password } = data

    setIsLoading(true)

    try {
      await signInWithEmailAndPassword(email, password)
    } catch (error) {
      console.log(error)
      setError('Username or password invalid')
    } finally {
      setIsLoading(false)
    }
  })

  useEffect(() => {
    if (user) {
      history.replace('/')
    }
  }, [user, history])

  return (
    <>
      <Stack as="form" onSubmit={onSubmit}>
        {error && (
          <Alert status="error">
            <AlertIcon />
            {error}
          </Alert>
        )}
        <FormControl
          id="email"
          isRequired
          isInvalid={errors.email ? true : false}>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            autoComplete="email"
            {...register('email', {
              required: true,
              pattern: { value: emailRegex, message: invalidEmailMessage },
            })}
          />
          <FormErrorMessage> {errors.email?.message} </FormErrorMessage>
        </FormControl>
        <FormControl
          id="password"
          isRequired
          isInvalid={errors.password ? true : false}>
          <FormLabel>
            <FormattedMessage
              id="auth.password"
              description="Password label"
              defaultMessage="Password"
            />
          </FormLabel>
          <Input
            type="password"
            autoComplete="current-password"
            {...register('password', {
              required: true,
            })}
          />
        </FormControl>
        <Button
          type="submit"
          colorScheme="blue"
          fontSize="md"
          isLoading={isLoading}>
          <FormattedMessage
            id="login_button"
            defaultMessage="Login"
            description="Login button copy"
          />
        </Button>
      </Stack>
    </>
  )
}

export default LoginForm

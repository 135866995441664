import { Location } from '../branches/Branch.entity'
import { User } from '../users/User.entity'

export enum PickupStatus {
  Pending = 'pending',
  Scheduled = 'scheduled',
  EnRoute = 'enroute',
  Done = 'done',
  Expired = 'expired',
}

export interface PickupEta {
  high: Date
  low: Date
  date: Date
}

export interface Pickup {
  id: string
  status: PickupStatus
  expectedAssets: number
  eta: PickupEta
  location: Location
  user: User
  createdAt: Date
}

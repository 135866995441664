import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorMode,
} from '@chakra-ui/react'
import { ResponsiveRadialBar } from '@nivo/radial-bar'
import React from 'react'
import { useIntl } from 'react-intl'
import { AssetLocation } from '../assets/Asset.entity'
import { useCalculateAssetsByBranch } from './queries'

export type InventoryPanelProps = {
  assetLocation: AssetLocation
  reference: string
}

const InventoryPanel = ({ assetLocation, reference }: InventoryPanelProps) => {
  const { data } = useCalculateAssetsByBranch(assetLocation, reference)
  const { colorMode } = useColorMode()
  const intl = useIntl()

  const printInfoIndicator = (label: string, quantity: number) => {
    return (
      <Flex>
        <Box>
          <Text color={'black'} fontSize={'sm'} fontWeight={'bold'} width={20}>
            {label}
          </Text>
        </Box>
        <Box>
          <Text color={'black'} fontSize={'sm'}>
            {quantity}
          </Text>
        </Box>
      </Flex>
    )
  }

  const renderTable = () => {
    return (
      <Table>
        <Thead textAlign={'center'}>
          <Th padding={2}>
            {intl.formatMessage({
              id: 'asset-types.containerType',
              description: 'Container type attribute of an asset type',
              defaultMessage: 'Container Type',
            })}
          </Th>
          <Th padding={2}>
            {intl.formatMessage({
              id: 'inventory.min',
              description: 'Minimum attribute of an indicator',
              defaultMessage: 'Minimum',
            })}
          </Th>
          <Th padding={2}>
            {intl.formatMessage({
              id: 'inventory.max',
              description: 'Maximum attribute of an indicator',
              defaultMessage: 'Maximum',
            })}
          </Th>
          <Th padding={2}>
            {intl.formatMessage({
              id: 'inventory.quantity',
              description: 'Quantity attribute of an indicator',
              defaultMessage: 'Quantity',
            })}
          </Th>
          <Th padding={2}>
            {intl.formatMessage({
              id: 'inventory.fill',
              description: 'Fill attribute of an indicator',
              defaultMessage: 'Fill',
            })}
          </Th>
        </Thead>
        <Tbody>
          {data?.data.map((row) => {
            return (
              <Tr padding={1}>
                <Td>
                  <Text fontSize={'sm'}>{row.id}</Text>
                </Td>
                <Td fontSize={'sm'}> {row.data[0].min}</Td>
                <Td fontSize={'sm'}> {row.data[0].max}</Td>
                <Td fontSize={'sm'}> {row.data[0].count}</Td>
                <Td fontSize={'sm'} color={'#F7FF8D'}>
                  {row.data[0].max - row.data[0].count}
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    )
  }

  return (
    <div style={{ height: '900px' }}>
      {data?.data ? (
        <>
          <ResponsiveRadialBar
            data={data?.data}
            startAngle={-90}
            endAngle={90}
            valueFormat=">-.2f"
            padding={0.4}
            cornerRadius={2}
            maxValue={100}
            margin={{ top: 40, right: 120, bottom: 40, left: 40 }}
            radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
            circularAxisOuter={{
              tickSize: 5,
              tickPadding: 12,
              tickRotation: 0,
            }}
            colors={data?.colors}
            tooltip={({ bar }) => {
              return (
                <Box
                  backgroundColor={'whiteAlpha.900'}
                  padding={1}
                  borderRadius={10}>
                  <Text color={'black'} fontSize={'xl'} textAlign={'center'}>
                    {`${Number(bar.value).toFixed(0)}%`}
                  </Text>
                  {printInfoIndicator(
                    intl.formatMessage({
                      id: 'inventory.quantity',
                      description: 'Quantity attribute of an indicator',
                      defaultMessage: 'Quantity',
                    }),
                    bar.data.count
                  )}
                  {printInfoIndicator(
                    intl.formatMessage({
                      id: 'inventory.min',
                      description: 'Minimum attribute of an indicator',
                      defaultMessage: 'Minimum',
                    }),
                    bar.data.min
                  )}
                  {printInfoIndicator(
                    intl.formatMessage({
                      id: 'inventory.max',
                      description: 'Maximum attribute of an indicator',
                      defaultMessage: 'Maximum',
                    }),
                    bar.data.max
                  )}
                </Box>
              )
            }}
            theme={{
              textColor: colorMode == 'dark' ? 'white' : 'black',
            }}
          />
          <Box width={'50'} height={window.screen.height} marginTop={-300}>
            {renderTable()}
          </Box>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

export default InventoryPanel

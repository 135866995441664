import { Heading } from '@chakra-ui/layout'
import { Select } from '@chakra-ui/react'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { AssetLocation } from '../features/assets/Asset.entity'
import { parseBranchTypeToAssetLocation } from '../features/branches/Branch.entity'
import { useBranchesList } from '../features/branches/queries'
import InventoryPanel from '../features/indicators/InventoryPanel'

const Dashboard = () => {
  const [assetLocation, setAssetLocation] = useState<AssetLocation>()
  const [reference, setReference] = useState<string>()
  const { data } = useBranchesList([], 0, 50)

  useEffect(() => {
    if (data && data && !assetLocation && !reference) {
      setAssetLocation(parseBranchTypeToAssetLocation(data.data[0].branchType))
      setReference(data.data[0].id)
    }
  }, [assetLocation, reference, data])

  const selectedBranchTest = (branch: any) => {
    const data = branch.split('-')
    const assetLocation = parseBranchTypeToAssetLocation(data[1])
    setAssetLocation(assetLocation)
    setReference(data[0])
  }

  return (
    <Heading>
      <FormattedMessage
        id="dashboard"
        defaultMessage="Dashboard"
        description="Dashboard screen title"
      />
      <Select
        onChange={(event) => {
          selectedBranchTest(event.target.value)
        }}>
        {data
          ? data.data.map((branch) => (
              <option
                key={branch.id}
                value={`${branch.id}-${branch.branchType}`}>
                {`${_.capitalize(branch.name)}`}
              </option>
            ))
          : []}
      </Select>
      {assetLocation && reference ? (
        <InventoryPanel assetLocation={assetLocation} reference={reference} />
      ) : null}
    </Heading>
  )
}

export default Dashboard

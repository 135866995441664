import { useToast } from '@chakra-ui/react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Filter } from '../common/Filter.entity'
import PaginatedResult from '../common/PaginatedResult.entity'
import useXicloClient, { XicloResource } from '../common/useXicloClient'
import { User } from './User.entity'

export const userKeys = {
  all: ['users'] as const,
  count: (filters: Filter[]) =>
    [...userKeys.all, 'count', { filters }] as const,
  me: () => [...userKeys.all, 'me'] as const,
  lists: () => [...userKeys.all, 'list'] as const,
  list: (filters: Filter[], page: number, limit: number) =>
    [...userKeys.lists(), { filters, page, limit }] as const,
  details: () => [...userKeys.all, 'detail'] as const,
  detail: (id: number) => [...userKeys.details(), id] as const,
}

const findMe = async () => {
  const client = await useXicloClient(XicloResource.Users)
  const { data } = await client.get<User>('/me')

  return data
}

export const useMe = () => {
  const findMeQuery = useQuery<User, Error>(userKeys.me(), () => findMe())

  return findMeQuery
}

const findAll = async (page: number, limit: number, filters: Filter[]) => {
  const offset = limit * page

  let params: { [key: string]: any } = {
    limit,
    offset,
  }

  filters.forEach(({ id, value }) => {
    params[id] = value
  })

  const client = await useXicloClient(XicloResource.Users)
  const { data } = await client.get<PaginatedResult<User>>('/', {
    params,
  })

  return data
}

export const useUsersList = (filters: Filter[], page: number, limit = 10) => {
  const findAllQuery = useQuery<PaginatedResult<User>, Error>(
    userKeys.list(filters, page ?? 0, limit),
    () => findAll(page, limit, filters),
    { keepPreviousData: true }
  )

  return findAllQuery
}

const count = async (filters: Filter[]) => {
  let params: { [key: string]: any } = {}

  filters.forEach(({ id, value }) => {
    params[id] = value
  })

  const client = await useXicloClient(XicloResource.Users)
  const { data } = await client.get<number>('/count', {
    params,
  })

  return data
}

export const useUsersCount = (filters: Filter[]) => {
  const countAllQuery = useQuery<number, Error>(userKeys.count(filters), () =>
    count(filters)
  )

  return countAllQuery
}

const update = async (user: Partial<User>) => {
  const client = await useXicloClient(XicloResource.Users)
  const { data } = await client.patch<User>(`/${user.id}`, user)
  return data
}

export const useUserMutation = () => {
  const queryClient = useQueryClient()
  const toast = useToast()

  return useMutation((user: Partial<User>) => update(user), {
    onSuccess: () => {
      // Esto se puede mejorar utilizando setQueryData
      queryClient.invalidateQueries(userKeys.lists())
      toast({
        title: 'User updated successfully',
        status: 'success',
        isClosable: true,
        position: 'bottom-right',
      })
    },
    onError: () => {
      toast({
        title: 'Error updating user, try again later',
        status: 'error',
        isClosable: true,
        position: 'bottom-right',
      })
    },
  })
}

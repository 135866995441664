import { Button } from '@chakra-ui/button'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/form-control'
import { Input } from '@chakra-ui/input'
import { HStack, Stack } from '@chakra-ui/layout'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  CheckboxGroup,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Select } from '@chakra-ui/select'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Column } from 'react-table'
import { Asset } from '../assets/Asset.entity'
import { Branch } from '../branches/Branch.entity'
import SortableDataTable from '../common/SortableDataTable'
import { useUserMutation } from './queries'
import { PaymentMethod, Role, User, UserToken } from './User.entity'

type Inputs = {
  roles?: Role[]
  email: string
  name?: string
  phoneNumber?: string
}

export type EditUserModalProps = {
  user: User
  branches: Branch[]
  isOpen: boolean
  onClose: () => void
}

const EditUserModal = ({
  user,
  branches,
  isOpen,
  onClose,
}: EditUserModalProps) => {
  const mutation = useUserMutation()
  const intl = useIntl()
  const [selectedRoles, setSelectedRoles] = useState<Role[]>(user.roles)
  const [selectedBranch, setSelectedBranch] = useState<string>('')
  const [selectedUserToken, setSelectedUserToken] = useState<UserToken>()
  const [selectedUserTokenIndex, setSelectedUserTokenIndex] = useState<number>()
  const { isOpen: isOpenUserToken, onOpen: onOpenUserToken } = useDisclosure()

  const userBranchIds = user.branches
    ? user.branches.map((b) => {
        return b.id
      })
    : []

  const availableBranches = branches.filter(
    (b) => !userBranchIds.includes(b.id)
  )

  const columnsBranches = useMemo<Column<Branch>[]>(
    () => [
      {
        Header: intl.formatMessage({
          id: 'users.partner.name',
          description: 'name of the partner',
          defaultMessage: 'Partner Name',
        }),
        accessor: (branch) => branch?.partner?.name,
      },
      {
        Header: intl.formatMessage({
          id: 'branch.name',
          description: 'name of the Branch',
          defaultMessage: 'Name',
        }),
        accessor: 'name',
      },
      {
        Header: intl.formatMessage({
          id: 'branch.type',
          description: 'type of the Branch',
          defaultMessage: 'Type',
        }),
        accessor: 'branchType',
      },
      {
        Header: intl.formatMessage({
          id: 'branch.remove',
          description: 'remove a Branch',
          defaultMessage: 'Remove',
        }),
        Cell: function removeBtn(cell: any) {
          return (
            <Button
              type="button"
              onClick={() => {
                let branchesToUpdate = Object.assign([], user.branches)
                branchesToUpdate.splice(cell.row.index, 1)
                user.branches = branchesToUpdate
                mutation.mutate({
                  id: user.id,
                  branches: user.branches,
                })
              }}>
              Remove and Save
            </Button>
          )
        },
      },
    ],
    []
  )

  const columnsAssets = useMemo<Column<Asset>[]>(
    () => [
      {
        Header: intl.formatMessage({
          id: 'assets',
          description: 'assets',
          defaultMessage: 'assets',
        }),
        accessor: 'reference',
      },
      {
        Header: intl.formatMessage({
          id: 'assets.lastAssetLogRegistered',
          description: 'Last asset log associated to asset',
          defaultMessage: 'Asset Log',
        }),
        accessor: (asset) =>
          asset.lastAssetLogRegistered ? asset.lastAssetLogRegistered.id : '',
      },
    ],
    []
  )

  const columnsPaymentMethods = useMemo<Column<PaymentMethod>[]>(
    () => [
      {
        Header: intl.formatMessage({
          id: 'paymentmethods.brand',
          description: 'Brand of the payment method',
          defaultMessage: 'Card franchise',
        }),
        accessor: 'brand',
      },
      {
        Header: intl.formatMessage({
          id: 'paymentmethods.cardNumber',
          description: 'Card number a payment method',
          defaultMessage: 'Card number',
        }),
        accessor: 'cardNumber',
      },
      {
        Header: intl.formatMessage({
          id: 'paymentmethods.expirationDate',
          description: 'Expiration of date of payment method',
          defaultMessage: 'Expiration date',
        }),
        accessor: 'expirationDate',
      },
    ],
    []
  )

  const columnsUserTokens = useMemo<Column<UserToken>[]>(
    () => [
      {
        Header: intl.formatMessage({
          id: 'userToken.token',
          description: 'Token of the user token',
          defaultMessage: 'Token',
        }),
        accessor: 'token',
      },
      {
        Header: intl.formatMessage({
          id: 'userToken.isReferenced',
          description: 'isReferenced flag from user token',
          defaultMessage: 'Is Referenced',
        }),
        accessor: (token) =>
          token.isReferenced
            ? intl.formatMessage({
                id: 'userToken.isReferenced.yes',
                description: 'isReferenced affirmative flag from user token',
                defaultMessage: 'Yes',
              })
            : intl.formatMessage({
                id: 'userToken.isReferenced.no',
                description: 'isReferenced negative flag from user token',
                defaultMessage: 'No',
              }),
      },
      {
        Header: intl.formatMessage({
          id: 'userToken.createdAt',
          description: 'Creation date of user token',
          defaultMessage: 'Creation date',
        }),
        accessor: (token) =>
          `${moment(token.createdAt).format('DD/MM/YYYY hh:mm A')}`,
      },
      {
        Header: intl.formatMessage({
          id: 'userToken.expiresAt',
          description: 'Expire date of user token',
          defaultMessage: 'Expire date',
        }),
        accessor: (token) =>
          `${moment(token.expiresAt).format('DD/MM/YYYY hh:mm A')}`,
      },
    ],
    [selectedUserToken]
  )

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>({ defaultValues: user })

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const updatedUser = {
      ...data,
      id: user.id,
      roles: selectedRoles,
    }
    mutation.mutate(updatedUser)
  }

  useEffect(() => {
    if (mutation.isSuccess) {
      onClose()
    }
  }, [mutation.isSuccess])

  const newUserToken = () => {
    setSelectedUserToken({
      token: '',
      isReferenced: false,
      createdAt: moment().toDate(),
      expiresAt: moment().add(2, 'hours').toDate(),
    } as UserToken)
    setSelectedUserTokenIndex(-1)
    onOpenUserToken()
  }

  const editUserToken = (userToken: UserToken) => {
    setSelectedUserToken(userToken)
    setSelectedUserTokenIndex(user.tokens?.indexOf(userToken))
    onOpenUserToken()
  }

  const deleteUserToken = (userToken: UserToken) => {
    if (selectedUserTokenIndex !== undefined && selectedUserTokenIndex > -1) {
      user.tokens?.splice(selectedUserTokenIndex, 1)
      reset(user)
    }
    onCloseUserToken()
  }

  const onCloseUserToken = () => {
    setSelectedUserToken(undefined)
    setSelectedUserTokenIndex(undefined)
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="5xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit user</ModalHeader>
          <ModalCloseButton />
          <form>
            <ModalBody pb={6}>
              <Stack spacing="3">
                <FormControl id="roles">
                  <FormLabel>Roles</FormLabel>
                  <CheckboxGroup
                    colorScheme="blue"
                    defaultValue={user.roles}
                    onChange={(selectedRoles) => {
                      const rolePairs = Object.entries(Role).filter((role) =>
                        selectedRoles.includes(role[1])
                      )
                      const roles: Role[] = rolePairs.map(
                        (rolePair) => rolePair[1]
                      )
                      setSelectedRoles(roles)
                    }}>
                    <HStack>
                      {Object.values(Role).map((role: string) => {
                        return (
                          <Checkbox key={role} value={role}>
                            {_.capitalize(role.replace('_', ' '))}
                          </Checkbox>
                        )
                      })}
                    </HStack>
                  </CheckboxGroup>
                </FormControl>
                <FormControl
                  id="email"
                  isRequired
                  isInvalid={errors.email !== undefined}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    {...register('email', {
                      required: true,
                      pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    })}
                    type="email"
                    name="email"
                    required
                    disabled
                  />
                  <FormErrorMessage>{errors.email}</FormErrorMessage>
                </FormControl>
                <FormControl id="name" isInvalid={errors.name !== undefined}>
                  <FormLabel>Name</FormLabel>
                  <Input {...register('name')} name="name" />
                  <FormErrorMessage>{errors.name}</FormErrorMessage>
                </FormControl>
                <FormControl
                  id="phoneNumber"
                  isInvalid={errors.phoneNumber !== undefined}>
                  <FormLabel>Phone number</FormLabel>
                  <Input
                    {...register('phoneNumber')}
                    type="tel"
                    name="phoneNumber"
                  />
                  <FormErrorMessage>{errors.phoneNumber}</FormErrorMessage>
                </FormControl>
              </Stack>
              <Stack>
                <Accordion allowMultiple>
                  <AccordionItem
                    marginY="5"
                    borderColor="gray.600"
                    border="1px"
                    borderRadius="5">
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        <FormattedMessage
                          id="assets"
                          defaultMessage="assets"
                          description="assets title"
                        />
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                      <SortableDataTable
                        data={user.assets ?? []}
                        columns={columnsAssets}
                        paginationAuto={false}
                      />
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem
                    marginY="5"
                    borderColor="gray.600"
                    border="1px"
                    borderRadius="5">
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        <FormattedMessage
                          id="paymentmethods"
                          defaultMessage="Payment methods"
                          description="Payment methods title"
                        />
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                      <SortableDataTable
                        data={user.paymentMethods ?? []}
                        columns={columnsPaymentMethods}
                        paginationAuto={false}
                      />
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem
                    marginY="5"
                    borderColor="gray.600"
                    border="1px"
                    borderRadius="5">
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        <FormattedMessage
                          id="userTokens"
                          defaultMessage="tokens"
                          description="tokens title"
                        />
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                      <Button
                        colorScheme="teal"
                        fontSize="md"
                        onClick={newUserToken}>
                        <FormattedMessage
                          id="userToken.new"
                          defaultMessage="New Token"
                          description="New Token Button"
                        />
                      </Button>
                      <SortableDataTable
                        data={user.tokens ?? []}
                        columns={columnsUserTokens}
                        paginationAuto={false}
                        onRowSelected={editUserToken}
                      />
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem
                    marginY="5"
                    borderColor="gray.600"
                    border="1px"
                    borderRadius="5">
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        <FormattedMessage
                          id="partners"
                          defaultMessage="partners"
                          description="partners title"
                        />
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                      <HStack>
                        <Select
                          placeholder="Select branch"
                          onChange={(event) =>
                            setSelectedBranch(event.target.value)
                          }>
                          {availableBranches.map((branch) => (
                            <option key={branch.id} value={branch.id}>
                              ({_.capitalize(branch.partner.name)}){' '}
                              {_.capitalize(branch.name)}
                            </option>
                          ))}
                        </Select>
                        <Button
                          type="button"
                          onClick={() => {
                            const branch = availableBranches.find(
                              (b) => b.id === selectedBranch
                            )
                            if (branch) {
                              if (!user.hasOwnProperty('branches'))
                                user.branches = []

                              user.branches.push(branch)

                              mutation.mutate({
                                id: user.id,
                                branches: user.branches,
                              })
                            }
                          }}
                          disabled={selectedBranch === ''}>
                          Add Branch
                        </Button>
                      </HStack>
                      <SortableDataTable
                        data={user.branches ?? []}
                        columns={columnsBranches}
                        totalCount={user.branches?.length ?? 0}
                      />
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Button
                isLoading={mutation.isLoading}
                type="button"
                colorScheme="teal"
                fontSize="md"
                onClick={handleSubmit(onSubmit)}>
                Save
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      {selectedUserToken ? (
        <Modal isOpen={isOpenUserToken} onClose={onCloseUserToken} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {selectedUserToken != undefined ? (
                <FormattedMessage
                  id="userToken.edit"
                  description="Edit user token"
                  defaultMessage="Edit User Token"
                />
              ) : (
                <FormattedMessage
                  id="userToken.add"
                  description="Add user token"
                  defaultMessage="Add User Token"
                />
              )}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody marginBottom={5}>
              <Text fontWeight={'bold'}>
                <FormattedMessage
                  id="userToken.token"
                  description="Token of the user token"
                  defaultMessage="Token"
                />
              </Text>
              <Input
                type="text"
                value={selectedUserToken.token}
                onChange={(event) =>
                  setSelectedUserToken({
                    ...selectedUserToken,
                    token: event.target.value,
                  })
                }
                name="Token"
              />

              <Text fontWeight={'bold'}>
                <FormattedMessage
                  id="userToken.isReferenced"
                  description="isReferenced flag of the user token"
                  defaultMessage="Is Referenced"
                />
              </Text>
              <Select
                value={selectedUserToken.isReferenced ? 'yes' : 'no'}
                onChange={(event) =>
                  setSelectedUserToken({
                    ...selectedUserToken,
                    isReferenced: event.target.value == 'yes',
                  })
                }>
                <option value="no">No</option>
                <option value="yes">Yes</option>
              </Select>

              <Text fontWeight={'bold'}>
                <FormattedMessage
                  id="userToken.createdAt"
                  description="createdAt of the user token"
                  defaultMessage="Creationg Date"
                />
              </Text>
              <Input
                placeholder="Select Date and Time"
                size="md"
                type="datetime-local"
                value={moment(selectedUserToken.createdAt).format(
                  'YYYY-MM-DDTHH:mm'
                )}
                onChange={(event) =>
                  setSelectedUserToken({
                    ...selectedUserToken,
                    createdAt: moment(event.target.value).toDate(),
                  })
                }
              />

              <Text fontWeight={'bold'}>
                <FormattedMessage
                  id="userToken.expiresAt"
                  description="expiresAt of the user token"
                  defaultMessage="Expiration Date"
                />
              </Text>
              <Input
                placeholder="Select Date and Time"
                size="md"
                type="datetime-local"
                value={moment(selectedUserToken.expiresAt).format(
                  'YYYY-MM-DDTHH:mm'
                )}
                onChange={(event) =>
                  setSelectedUserToken({
                    ...selectedUserToken,
                    expiresAt: moment(event.target.value).toDate(),
                  })
                }
              />
            </ModalBody>
            <ModalFooter>
              <Button
                type="submit"
                colorScheme="teal"
                fontSize="md"
                hidden={(selectedUserTokenIndex ?? -1) < 0}
                onClick={() => deleteUserToken(selectedUserToken)}>
                <FormattedMessage
                  id="userToken.delete.button"
                  description="Delete button for user token"
                  defaultMessage="Delete"
                />
              </Button>
              <Spacer />
              <Button
                type="submit"
                colorScheme="teal"
                fontSize="md"
                isLoading={status === 'loading'}
                onClick={() => {
                  if (selectedUserToken.token != '') {
                    if (!user.hasOwnProperty('tokens')) user.tokens = []

                    if (selectedUserTokenIndex! >= 0) {
                      user.tokens?.splice(selectedUserTokenIndex!, 1)
                      reset(user)
                    }

                    selectedUserToken.token = selectedUserToken.token.trim()

                    user.tokens?.push(selectedUserToken)
                    reset(user)
                    onCloseUserToken()
                  }
                }}>
                <FormattedMessage
                  id="userToken.save.button"
                  description="Save button for asset type config"
                  defaultMessage="Save"
                />
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : null}
    </>
  )
}

export default EditUserModal

import { Center, Spinner } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import PageNotFound from './features/common/PageNotFound'
import ProtectedRoute from './features/common/ProtectedRoute'
import { Role } from './features/users/User.entity'
import AccessDenied from './pages/access-denied'
import AssetLogs from './pages/asset-logs'
import AssetTypes from './pages/asset-types'
import Assets from './pages/assets'
import Branches from './pages/branches'
import Brands from './pages/brands'
import Dashboard from './pages/dashboard'
import DeliveryOrders from './pages/delivery-orders'
import Invoices from './pages/invoices'
import Login from './pages/login'
import Orders from './pages/orders'
import Partners from './pages/partners'
import Payments from './pages/payments'
import Pickups from './pages/pickups'
import QrDimensions from './pages/qr-dimensions'
import Users from './pages/users'
import { useAuth } from './providers/AuthProvider'

export const App = () => {
  let history = useHistory()

  const { user, isInitializing } = useAuth()

  useEffect(() => {
    if (user && !user.roles.includes(Role.Admin)) {
      history.replace('/access-denied')
    }
  }, [user, history])

  if (isInitializing) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    )
  }

  return (
    <Switch>
      <Route path="/access-denied">
        <AccessDenied />
      </Route>
      <Route path="/login">
        <Login />
      </Route>
      <ProtectedRoute exact path="/users">
        <Users />
      </ProtectedRoute>
      <ProtectedRoute path="/partners">
        <Partners />
      </ProtectedRoute>
      <ProtectedRoute path="/branches">
        <Branches />
      </ProtectedRoute>
      <ProtectedRoute path="/assets">
        <Assets />
      </ProtectedRoute>
      <ProtectedRoute path="/asset-types">
        <AssetTypes />
      </ProtectedRoute>
      <ProtectedRoute path="/brands">
        <Brands />
      </ProtectedRoute>
      <ProtectedRoute path="/dashboard">
        <Dashboard />
      </ProtectedRoute>
      <ProtectedRoute path="/asset-logs">
        <AssetLogs />
      </ProtectedRoute>
      <ProtectedRoute path="/payments">
        <Payments />
      </ProtectedRoute>
      <ProtectedRoute path="/qr-dimensions">
        <QrDimensions />
      </ProtectedRoute>
      <ProtectedRoute path="/pickups">
        <Pickups />
      </ProtectedRoute>
      <ProtectedRoute path="/orders">
        <Orders />
      </ProtectedRoute>
      <ProtectedRoute path="/delivery-orders">
        <DeliveryOrders />
      </ProtectedRoute>
      <ProtectedRoute path="/invoices">
        <Invoices />
      </ProtectedRoute>
      <Redirect exact from="/" to="/dashboard" />
      <Route path="*">
        <PageNotFound />
      </Route>
    </Switch>
  )
}

import { getAuth } from '@firebase/auth'
import axios from 'axios'
import Server from '../../constants/Server'

export enum XicloResource {
  Users = 'users',
  Partners = 'business-partners',
  Branches = 'branches',
  AssetTypes = 'asset-types',
  AssetLogs = 'asset-log',
  Assets = 'assets',
  Payments = 'payments',
  Brand = 'brands',
  QrDimension = 'qr-dimension',
  GeneratorQr = 'generator-qr',
  Pickups = 'pickups',
  Orders = 'orders',
  DeliveryOrders = 'delivery-order',
  Invoices = 'invoices',
}

const useXicloClient = async (resource: XicloResource) => {
  const token = await getAuth().currentUser?.getIdToken()
  const client = axios.create({
    baseURL: `${Server.host}/${Server.version}/${resource}`,
  })

  if (token) {
    client.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }

  return client
}

export default useXicloClient

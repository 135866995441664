import { useToast } from '@chakra-ui/react'
import { useIntl } from 'react-intl'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Filter } from '../common/Filter.entity'
import PaginatedResult from '../common/PaginatedResult.entity'
import useXicloClient, { XicloResource } from '../common/useXicloClient'
import { CreateInvoiceDto, Invoice } from './Invoice.entity'

export const invoicesKeys = {
  all: ['invoices'] as const,
  count: (filters: Filter[]) =>
    [...invoicesKeys.all, 'count', { filters }] as const,
  lists: () => [...invoicesKeys.all, 'list'] as const,
  list: (filters: Filter[], page: number, limit: number) =>
    [...invoicesKeys.lists(), { filters, page, limit }] as const,
}

const findAll = async (filters: Filter[], page: number, limit: number) => {
  const offset = limit * (page ?? 1)

  let params: { [key: string]: any } = {
    limit,
    offset,
    filters,
  }

  filters.forEach(({ id, value }) => {
    params[id] = value
  })

  const client = await useXicloClient(XicloResource.Invoices)
  const { data } = await client.get<PaginatedResult<Invoice>>('/', { params })

  return data
}

export const useInvoicesList = (
  filters: Filter[],
  page: number,
  limit = 10
) => {
  return useQuery<PaginatedResult<Invoice>, Error>(
    invoicesKeys.list(filters, page, limit),
    () => findAll(filters, page, limit),
    { keepPreviousData: true }
  )
}

const count = async (filters: Filter[]) => {
  let params: { [key: string]: any } = {}

  filters.forEach(({ id, value }) => {
    params[id] = value
  })

  const client = await useXicloClient(XicloResource.Invoices)
  const { data } = await client.get<number>('/count/total', {
    params,
  })

  return data
}

export const useInvoicesCount = (filters: Filter[]) => {
  const countAllQuery = useQuery<number, Error>(
    invoicesKeys.count(filters),
    () => count(filters)
  )

  return countAllQuery
}

const create = async (invoice: CreateInvoiceDto) => {
  const client = await useXicloClient(XicloResource.Invoices)
  const { data } = await client.post<Invoice>(`/`, invoice)
  return data
}

export const useCreateInvoice = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const intl = useIntl()

  const createdSuccessfullMessage = intl.formatMessage({
    id: 'invoices.createdSuccessfully',
    description: 'Invoice created successfully toast',
    defaultMessage: 'Invoice created successfully',
  })

  return useMutation((invoice: CreateInvoiceDto) => create(invoice), {
    onSuccess: () => {
      // Esto se puede mejorar utilizando setQueryData
      queryClient.invalidateQueries(invoicesKeys.lists())
      toast({
        title: createdSuccessfullMessage,
        status: 'success',
        isClosable: true,
        position: 'top-right',
      })
    },
    onError: () => {
      toast({
        title: 'Error updating asset, try again later',
        status: 'error',
        isClosable: true,
        position: 'top-right',
      })
    },
  })
}

import { Center, Spinner } from '@chakra-ui/react'
import moment from 'moment'
import React, { useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Column } from 'react-table'
import { Filter } from '../common/Filter.entity'
import SortableDataTable, {
  DateColumnFilter,
  SelectColumnFilter,
} from '../common/SortableDataTable'
import { Pickup, PickupStatus } from './Pickup.entity'
import { usePickupCount, usePickupsList } from './queries'

const PickupsList = () => {
  const status = Object.values(PickupStatus)

  const [filters, setFilters] = useState<Filter[]>([])
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const { data, isLoading } = usePickupsList(filters, currentPage, pageSize)
  const pickupCount = usePickupCount(filters)
  const intl = useIntl()

  const setPage = useCallback(
    (pageIndex: number, pageSize: number) => {
      setCurrentPage(pageIndex)
      setPageSize(pageSize)
    },
    [currentPage, pageSize]
  )

  const columns = useMemo<Column<Pickup>[]>(
    () => [
      {
        Header: intl.formatMessage({
          id: 'pickups.user',
          description: 'User attribute of an pickup',
          defaultMessage: 'Name',
        }),
        accessor: (pickup) => pickup.user.name,
        id: 'user',
      },
      {
        Header: intl.formatMessage({
          id: 'pickups.email',
          description: 'Email attribute of a pickup',
          defaultMessage: 'Email',
        }),
        accessor: (pickup) => pickup.user.email,
        id: 'email',
      },
      {
        Header: intl.formatMessage({
          id: 'pickups.status',
          description: 'Status attribute of an pickup',
          defaultMessage: 'Status',
        }),
        accessor: 'status',
        Filter: SelectColumnFilter,
        listOptions: status,
        id: 'status',
      },
      {
        Header: intl.formatMessage({
          id: 'pickups.expectedAssets',
          description: 'Expected assets attribute of an pickup',
          defaultMessage: 'Expected Assets',
        }),
        accessor: 'expectedAssets',
        id: 'expectedAssets',
      },
      {
        Header: intl.formatMessage({
          id: 'pickups.eta',
          description: 'Eta attribute of an pickup',
          defaultMessage: 'Eta',
        }),
        accessor: (pickup) => {
          let date = moment(pickup.eta.date)
          return `${date.format('DD/MM/YYYY')}`
        },
        id: 'eta',
        locale: intl.locale,
        Filter: DateColumnFilter,
      },
      {
        Header: intl.formatMessage({
          id: 'pickups.address',
          description: 'Addess attribute of an pickup',
          defaultMessage: 'Address',
        }),
        accessor: (pickup) => pickup.location.address,
        id: 'location',
      },
      {
        Header: intl.formatMessage({
          id: 'pickups.details',
          description: 'Details attribute of a pickup',
          defaultMessage: 'Details',
        }),
        accessor: (pickup) => pickup.location.additionalInfo,
        id: 'additionalInfo',
      },
      {
        Header: intl.formatMessage({
          id: 'pickups.createdAt',
          description: 'Created at attribute of an Pickup',
          defaultMessage: 'Created At',
        }),
        accessor: (pickup) => {
          let date = moment(pickup.createdAt)
          return `${date.format('DD/MM/YYYY')}`
        },
        id: 'createdAt',
        locale: intl.locale,
        Filter: DateColumnFilter,
      },
    ],
    []
  )

  return (
    <>
      {isLoading ? (
        <Center>
          <Spinner size="lg" />
        </Center>
      ) : (
        <SortableDataTable
          data={data?.data ?? []}
          columns={columns}
          setPage={setPage}
          setFilters={setFilters}
          loading={isLoading}
          currentPage={currentPage}
          totalCount={pickupCount.data}
          sizePage={pageSize}
          fontSize="smaller"
          paginationAuto={true}
          filterAuto={true}
          enableFilters
        />
      )}
    </>
  )
}

export default PickupsList

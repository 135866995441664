import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
} from '@chakra-ui/react'
import { useToast } from '@chakra-ui/toast'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useBrandsList } from '../brands/queries'
import { useQrDimensionsList } from '../qr-dimension/queries'
import {
  AssetType,
  ContainerType,
  MaterialType,
  Type,
} from './AssetType.entity'
import { useCreateAssetType, useUpdateAssetType } from './queries'

export type AssetTypesModalProps = {
  assetType?: Partial<AssetType> | undefined
  isOpen: boolean
  onClose: () => void
}

const AssetTypeModal = ({
  assetType,
  isOpen,
  onClose,
}: AssetTypesModalProps) => {
  const intl = useIntl()
  const toast = useToast()

  const createAssetType = useCreateAssetType()
  const updateAssetType = useUpdateAssetType()

  const newAssetTypeTitleMessage = intl.formatMessage({
    id: 'asset-types.title.new',
    description: 'New asset type title message',
    defaultMessage: 'New Asset Type',
  })
  const createdSuccessfullMessage = intl.formatMessage({
    id: 'asset-types.createdSuccessfully',
    description: 'Asset type created successfully toast',
    defaultMessage: 'Asset type created successfully',
  })
  const updatedSuccessfullMessage = intl.formatMessage({
    id: 'asset-types.updatedSuccessfully',
    description: 'Asset type updated successfully toast',
    defaultMessage: 'Asset type updated successfully',
  })

  const title = assetType ? assetType.id : newAssetTypeTitleMessage
  const assetTypeTypes = Object.values(Type)
  const assetContainerTypes = Object.values(ContainerType)
  const assetMaterialTypes = Object.values(MaterialType)
  const brands = useBrandsList()
  const qrDimensions = useQrDimensionsList()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AssetType>({ defaultValues: assetType })

  const onSubmit: SubmitHandler<AssetType> = (data) => {
    if (assetType) {
      updateAssetType.mutate(data)
    } else {
      createAssetType.mutate(data)
    }
  }

  useEffect(() => {
    if (createAssetType.isSuccess || updateAssetType.isSuccess) {
      toast({
        title: updateAssetType.isSuccess
          ? updatedSuccessfullMessage
          : createdSuccessfullMessage,
        status: 'success',
        isClosable: true,
        position: 'top-right',
      })
      onClose()
    } else if (createAssetType.isError || updateAssetType.isError) {
      toast({
        title: 'Error updating or creating asset type, try again later',
        status: 'error',
        isClosable: true,
        position: 'top-right',
      })
    }
  }, [
    createAssetType.isSuccess,
    updateAssetType.isSuccess,
    updateAssetType.isError,
    createAssetType.isError,
  ])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Stack spacing="3">
              <FormControl
                id="brand"
                isRequired
                isInvalid={errors.brand !== undefined}>
                <FormLabel>
                  <FormattedMessage
                    id="asset-types.formlabel.brand"
                    description="Asset type brand form label"
                    defaultMessage="Brand"
                  />
                </FormLabel>
                <Select {...register('brand.id')}>
                  {brands.data?.map((brand) => (
                    <option key={brand.id} value={brand.id}>
                      {brand.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                id="ticker"
                isRequired
                isInvalid={errors.brand !== undefined}>
                <FormLabel>
                  <FormattedMessage
                    id="asset-types.formlabel.ticker"
                    description="Asset type ticker form label"
                    defaultMessage="Ticker"
                  />
                </FormLabel>
                <Input {...register('ticker')} name="ticker" />
              </FormControl>
            </Stack>
            <HStack spacing="8">
              <FormControl
                id="volume"
                isRequired
                isInvalid={errors.volume !== undefined}>
                <FormLabel>
                  <FormattedMessage
                    id="asset-types.formlabel.volume"
                    description="Asset type volume form label"
                    defaultMessage="Volume"
                  />
                </FormLabel>
                <Input
                  {...register('volume', { valueAsNumber: true })}
                  name="volume"
                />
              </FormControl>
              <FormControl
                id="providerPhoneNumber"
                isRequired
                isInvalid={errors.providerPhoneNumber !== undefined}>
                <FormLabel>
                  <FormattedMessage
                    id="asset-types.formlabel.providerPhoneNumber"
                    description="Asset type provider phone number form label"
                    defaultMessage="Provider Phone #"
                  />
                </FormLabel>
                <Input
                  {...register('providerPhoneNumber', { valueAsNumber: true })}
                  name="providerPhoneNumber"
                />
              </FormControl>
            </HStack>
            <HStack spacing="8">
              <FormControl
                id="type"
                isRequired
                isInvalid={errors.type !== undefined}>
                <FormLabel>
                  <FormattedMessage
                    id="asset-types.formlabel.type"
                    description="Asset type type form label"
                    defaultMessage="Type"
                  />
                </FormLabel>
                <Select {...register('type')}>
                  {assetTypeTypes.map((assetType) => (
                    <option key={assetType} value={assetType}>
                      {_.capitalize(assetType)}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                id="containerType"
                isRequired
                isInvalid={errors.containerType !== undefined}>
                <FormLabel>
                  <FormattedMessage
                    id="asset-types.formlabel.containerType"
                    description="Asset type container type form label"
                    defaultMessage="Container Type"
                  />
                </FormLabel>
                <Select {...register('containerType')}>
                  {assetContainerTypes.map((assetContainerType) => (
                    <option key={assetContainerType} value={assetContainerType}>
                      {_.capitalize(assetContainerType)}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </HStack>
            <br></br>
            <Divider></Divider>
            <FormLabel>
              <FormattedMessage
                id="asset-types.formlabel.dimension"
                description="Asset type dimension form label"
                defaultMessage="Dimension"
              />
            </FormLabel>
            <HStack>
              <FormControl
                id="height"
                isRequired
                isInvalid={errors.dimension?.height !== undefined}>
                <FormLabel>
                  <FormattedMessage
                    id="asset-types.formlabel.height"
                    description="Asset type height form label"
                    defaultMessage="Height"
                  />
                </FormLabel>
                <Input
                  {...register('dimension.height', { valueAsNumber: true })}
                  name="dimension.height"
                />
              </FormControl>
              <FormControl
                id="width"
                isRequired
                isInvalid={errors.dimension?.width !== undefined}>
                <FormLabel>
                  <FormattedMessage
                    id="asset-types.formlabel.width"
                    description="Asset type width form label"
                    defaultMessage="Width"
                  />
                </FormLabel>
                <Input
                  {...register('dimension.width', { valueAsNumber: true })}
                  name="dimension.width"
                />
              </FormControl>
              <FormControl
                id="length"
                isRequired
                isInvalid={errors.dimension?.length !== undefined}>
                <FormLabel>
                  <FormattedMessage
                    id="asset-types.formlabel.length"
                    description="Asset type length form label"
                    defaultMessage="Length"
                  />
                </FormLabel>
                <Input
                  {...register('dimension.length', { valueAsNumber: true })}
                  name="dimension.length"
                />
              </FormControl>
            </HStack>
            <br></br>
            <FormControl
              id="qrDimension"
              isRequired
              isInvalid={errors.qrDimension !== undefined}>
              <FormLabel>
                <FormattedMessage
                  id="asset-types.formlabel.qrDimension"
                  description="Asset type QR dimension form label"
                  defaultMessage="QR dimension"
                />
              </FormLabel>
              <Select {...register('qrDimension.id')}>
                {qrDimensions?.data?.map((qrDimension) => (
                  <option key={qrDimension.id} value={qrDimension.id}>
                    {_.capitalize(
                      `${qrDimension.name} (${qrDimension.width}cm x ${qrDimension.height}cm)`
                    )}
                  </option>
                ))}
              </Select>
            </FormControl>
            <br></br>
            <HStack>
              <FormControl
                id="materialType"
                isRequired
                isInvalid={errors.materialType !== undefined}>
                <FormLabel>
                  <FormattedMessage
                    id="asset-types.formlabel.materialType"
                    description="Asset type material type form label"
                    defaultMessage="Material Type"
                  />
                </FormLabel>
                <Select {...register('materialType')}>
                  {assetMaterialTypes.map((assetMaterialType) => (
                    <option key={assetMaterialType} value={assetMaterialType}>
                      {_.capitalize(assetMaterialType)}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </HStack>
            <br></br>
            <HStack>
              <FormControl
                id="icon"
                isRequired
                isInvalid={errors.icon !== undefined}>
                <FormLabel>
                  <FormattedMessage
                    id="asset-types.formlabel.icon"
                    description="Asset type icon form label"
                    defaultMessage="Icon"
                  />
                </FormLabel>
                <Input {...register('icon')} name="icon" />
              </FormControl>
            </HStack>
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              colorScheme="teal"
              fontSize="md"
              isLoading={
                createAssetType.isLoading || updateAssetType.isLoading
              }>
              <FormattedMessage
                id="asset-types.button.save"
                description="Asset Type save button"
                defaultMessage="Save"
              />
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default AssetTypeModal

import { Center, Heading, Stack } from '@chakra-ui/layout'
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router'
import GoogleSignIn from '../features/auth/GoogleSignIn'
import LoginForm from '../features/auth/LoginForm'
import { ColorModeSwitcher } from '../features/common/ColorModeSwitcher'
import DividerWithText from '../features/common/Divider'
import { useAuth } from '../providers/AuthProvider'

const Login = () => {
  const history = useHistory()
  const { user, isInitializing } = useAuth()

  useEffect(() => {
    if (user && isInitializing) {
      history.replace('/')
    }
  }, [user, history])

  return (
    <>
      <ColorModeSwitcher position="absolute" right="0" m="2" />
      <Center h="100vh">
        <Stack w="sm" mx="6">
          <Heading>
            <FormattedMessage
              id="login"
              defaultMessage="Login"
              description="Login screen title"
            />
          </Heading>
          <LoginForm />
          <DividerWithText my="6">
            <FormattedMessage
              id="login_divider.or_continue_with"
              defaultMessage="Or continue with"
              description="Or continue with sign in divider message"
            />
          </DividerWithText>
          <GoogleSignIn />
        </Stack>
      </Center>
    </>
  )
}

export default Login

import { Button } from '@chakra-ui/button'
import { useDisclosure } from '@chakra-ui/hooks'
import { AddIcon } from '@chakra-ui/icons'
import { Heading } from '@chakra-ui/layout'
import { Center, Spinner } from '@chakra-ui/react'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import AssetTypeModal from '../features/asset-types/AssetTypeModal'
import AssetTypesList from '../features/asset-types/AssetTypesList'
import { useAssetTypesList } from '../features/asset-types/queries'

const AssetTypes = () => {
  const { onOpen, onClose, isOpen } = useDisclosure()
  const { data, isLoading } = useAssetTypesList()

  return (
    <>
      <Heading>
        <FormattedMessage
          id="asset-types"
          defaultMessage="Asset Types"
          description="Asset Types screen title"
        />
      </Heading>
      <Button
        leftIcon={<AddIcon />}
        colorScheme="teal"
        my="3"
        onClick={() => onOpen()}>
        <FormattedMessage
          id="asset-types.button.new"
          description="Asset types new label"
          defaultMessage="New"
        />
      </Button>
      {isLoading ? (
        <Center>
          <Spinner size="lg" />
        </Center>
      ) : (
        <AssetTypesList assetTypes={data ?? []} />
      )}
      <AssetTypeModal isOpen={isOpen} onClose={onClose} />
    </>
  )
}

export default AssetTypes

import { Button } from '@chakra-ui/button'
import { useDisclosure } from '@chakra-ui/hooks'
import { AddIcon } from '@chakra-ui/icons'
import { Center, Heading } from '@chakra-ui/layout'
import { Spinner } from '@chakra-ui/spinner'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import QRDimensionList from '../features/qr-dimension/QrDimensionList'
import QRDimensionModal from '../features/qr-dimension/QrDimensionModal'
import { useQrDimensionsList } from '../features/qr-dimension/queries'

const QrDimensions = () => {
  const { onOpen, onClose, isOpen } = useDisclosure()
  const { data, isLoading } = useQrDimensionsList()

  return (
    <>
      <Heading>
        <FormattedMessage
          id="qr-dimensions"
          defaultMessage="QR dimensions"
          description="QR dimensions screen title"
        />
      </Heading>
      <Button
        leftIcon={<AddIcon />}
        colorScheme="teal"
        my="3"
        onClick={() => onOpen()}>
        <FormattedMessage
          id="qr-dimensions.button.new"
          description="QR dimensions new label"
          defaultMessage="New"
        />
      </Button>
      {isLoading ? (
        <Center>
          <Spinner size="lg" />
        </Center>
      ) : (
        <QRDimensionList qrDimensions={data ?? []} />
      )}
      <QRDimensionModal isOpen={isOpen} onClose={onClose} />
    </>
  )
}

export default QrDimensions

import { useToast } from '@chakra-ui/react'
import { useIntl } from 'react-intl'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Filter } from '../common/Filter.entity'
import PaginatedResult from '../common/PaginatedResult.entity'
import useXicloClient, { XicloResource } from '../common/useXicloClient'
import { Asset, CreateBatchAssetDto, QRFile } from './Asset.entity'

export const assetKeys = {
  all: ['assets'] as const,
  count: (filters: Filter[]) =>
    [...assetKeys.all, 'count', { filters }] as const,
  lists: () => [...assetKeys.all, 'list'] as const,
  list: (filters: Filter[], page: number, limit: number) =>
    [...assetKeys.lists(), { filters, page, limit }] as const,
  listByIds: (ids: string) => [...assetKeys.lists(), { ids }] as const,
}

const findAll = async (page: number, limit: number, filters: Filter[]) => {
  const offset = limit * page

  let params: { [key: string]: any } = {
    limit,
    offset,
  }

  filters.forEach(({ id, value }) => {
    params[id] = value
  })

  const client = await useXicloClient(XicloResource.Assets)
  const { data } = await client.get<PaginatedResult<Asset>>('/', {
    params,
  })

  return data
}

export const useAssets = (filters: Filter[], page: number, limit = 10) => {
  const findAllQuery = useQuery<PaginatedResult<Asset>, Error>(
    assetKeys.list(filters, page ?? 0, limit),
    () => findAll(page, limit, filters),
    { keepPreviousData: true }
  )

  return findAllQuery
}

const count = async (filters: Filter[]) => {
  let params: { [key: string]: any } = {}

  filters.forEach(({ id, value }) => {
    params[id] = value
  })

  const client = await useXicloClient(XicloResource.Assets)
  const { data } = await client.get<number>('/count', {
    params,
  })

  return data
}

export const useAssetsCount = (filters: Filter[]) => {
  const countAllQuery = useQuery<number, Error>(assetKeys.count(filters), () =>
    count(filters)
  )

  return countAllQuery
}

const create = async (asset: CreateBatchAssetDto) => {
  const client = await useXicloClient(XicloResource.Assets)
  const { data } = await client.post<Asset>(`/`, asset)
  return data
}

export const useCreateAsset = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const intl = useIntl()

  const createdSuccessfullMessage = intl.formatMessage({
    id: 'assets.createdSuccessfully',
    description: 'Asset created successfully toast',
    defaultMessage: 'Asset created successfully',
  })

  return useMutation((asset: CreateBatchAssetDto) => create(asset), {
    onSuccess: () => {
      // Esto se puede mejorar utilizando setQueryData
      queryClient.invalidateQueries(assetKeys.lists())
      toast({
        title: createdSuccessfullMessage,
        status: 'success',
        isClosable: true,
        position: 'bottom-right',
      })
    },
    onError: () => {
      toast({
        title: 'Error updating asset, try again later',
        status: 'error',
        isClosable: true,
        position: 'bottom-right',
      })
    },
  })
}

const update = async (asset: Partial<Asset>) => {
  const client = await useXicloClient(XicloResource.Assets)
  const { data } = await client.patch<Asset>(`/${asset.id}`, asset)
  return data
}

export const useUpdateAsset = () => {
  const queryClient = useQueryClient()
  const intl = useIntl()
  const toast = useToast()

  const updatedSuccessfullMessage = intl.formatMessage({
    id: 'assets.updatedSuccessfully',
    description: 'Asset updated successfully toast',
    defaultMessage: 'Asset updated successfully',
  })

  return useMutation((asset: Partial<Asset>) => update(asset), {
    onSuccess: () => {
      // Esto se puede mejorar utilizando setQueryData
      queryClient.invalidateQueries(assetKeys.lists())

      toast({
        title: updatedSuccessfullMessage,
        status: 'success',
        isClosable: true,
        position: 'bottom-right',
      })
    },
    onError: () => {
      toast({
        title: 'Error creating asset, try again later',
        status: 'error',
        isClosable: true,
        position: 'bottom-right',
      })
    },
  })
}

const generateQRs = async (references: string[]) => {
  const client = await useXicloClient(XicloResource.GeneratorQr)
  const { data } = await client.post<QRFile[]>('', references)

  return data
}

export const useGenerateQRs = () => {
  const toast = useToast()
  return useMutation((references: string[]) => generateQRs(references), {
    onSuccess: () => {},
    onError: () => {
      toast({
        title: 'Error generating QR, try again later',
        status: 'error',
        isClosable: true,
        position: 'bottom-right',
      })
    },
  })
}

const findAllByIds = async (ids: string) => {
  const client = await useXicloClient(XicloResource.Assets)
  const { data } = await client.get<Asset[]>('/all/ids', {
    params: { ids: ids.toString() },
  })

  return data
}

export const useAssetsByIds = (ids: string) => {
  if (ids) {
    const findAllQuery = useQuery<Asset[], Error>(
      assetKeys.listByIds(ids),
      () => findAllByIds(ids),
      { keepPreviousData: true }
    )

    return findAllQuery
  }
}

import { useDisclosure } from '@chakra-ui/react'
import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Column } from 'react-table'
import SortableDataTable from '../common/SortableDataTable'
import { QrDimension } from './QRDimension.entity'
import QRDimensionModal from './QrDimensionModal'

export type QrDimensionListProps = {
  qrDimensions: QrDimension[]
}

const QrDimensionList = ({ qrDimensions }: QrDimensionListProps) => {
  const intl = useIntl()
  const { isOpen, onOpen } = useDisclosure()
  const [selectedQRDimension, setSelectedQRDimension] = useState<QrDimension>()

  const columns = useMemo<Column<QrDimension>[]>(
    () => [
      {
        Header: intl.formatMessage({
          id: 'qr-dimensions.name',
          description: 'Name attribute of an QR dimension',
          defaultMessage: 'Name',
        }),
        accessor: 'name',
      },
      {
        Header: intl.formatMessage({
          id: 'qr-dimensions.height',
          description: 'Height attribute of an QR dimension',
          defaultMessage: 'Height',
        }),
        accessor: 'height',
      },
      {
        Header: intl.formatMessage({
          id: 'qr-dimensions.width',
          description: 'Width attribute of an QR dimension',
          defaultMessage: 'Width',
        }),
        accessor: 'width',
      },
    ],
    []
  )

  const editQrDimension = (qrDimension: QrDimension) => {
    setSelectedQRDimension(qrDimension)
    onOpen()
  }

  const onClose = () => {
    setSelectedQRDimension(undefined)
  }

  return (
    <>
      <SortableDataTable
        data={qrDimensions}
        columns={columns}
        onRowSelected={editQrDimension}
        totalCount={qrDimensions.length}
        currentPage={0}
        paginationAuto={false}
        filterAuto={false}
      />
      {selectedQRDimension ? (
        <QRDimensionModal
          qrDimension={selectedQRDimension}
          isOpen={isOpen}
          onClose={onClose}
        />
      ) : null}
    </>
  )
}

export default QrDimensionList

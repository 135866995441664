import { Button } from '@chakra-ui/button'
import VisuallyHidden from '@chakra-ui/visually-hidden'
import React from 'react'
import { FaGoogle } from 'react-icons/fa'
import { useAuth } from '../../providers/AuthProvider'

const GoogleSignIn = () => {
  const { signInWithGoogle } = useAuth()

  const onSignIn = () => {
    signInWithGoogle()
  }

  return (
    <Button onClick={onSignIn} color="currentColor" variant="outline">
      <VisuallyHidden>Login with Google</VisuallyHidden>
      <FaGoogle />
    </Button>
  )
}

export default GoogleSignIn

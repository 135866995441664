import { Button } from '@chakra-ui/button'
import { Container, Heading, Text } from '@chakra-ui/layout'
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router'
import { useAuth } from '../providers/AuthProvider'

const AccessDenied = () => {
  const { signOut, user, isInitializing } = useAuth()
  const history = useHistory()

  useEffect(() => {
    if (!user && !isInitializing) {
      history.replace('/')
    }
  }, [user])

  return (
    <Container>
      <Heading>
        <FormattedMessage
          id="access_denied.title"
          defaultMessage="Access denied"
          description="Title when the user is unauthorized"
        />
      </Heading>
      <Text>
        <FormattedMessage
          id="access_denied.message"
          defaultMessage="You're not an admin, please close this session and login again"
          description="Access denied message when user not autorized"
        />
      </Text>
      <Button colorScheme="teal" onClick={signOut}>
        <FormattedMessage
          id="logout"
          defaultMessage="Logout"
          description="Logout button"
        />
      </Button>
    </Container>
  )
}

export default AccessDenied

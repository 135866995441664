import { Button } from '@chakra-ui/button'
import { FormControl, FormLabel } from '@chakra-ui/form-control'
import { Input } from '@chakra-ui/input'
import { Stack } from '@chakra-ui/layout'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Image,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Progress,
  Select,
  SimpleGrid,
  Spacer,
  Switch,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Textarea } from '@chakra-ui/textarea'
import { useToast } from '@chakra-ui/toast'
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Column } from 'react-table'
import { useAssetTypesList } from '../asset-types/queries'
import SortableDataTable from '../common/SortableDataTable'
import {
  AssetTypePriceConfig,
  CreatePartnerDto,
  Partner,
  RolloutPhase,
} from './Partner.entity'
import { useCreatePartner, useUpdatePartner } from './queries'

export type PartnerModalProps = {
  partner?: Partner
  isOpenPartner: boolean
  onClosePartner: () => void
}

const PartnerModal = ({
  partner,
  onClosePartner,
  isOpenPartner,
}: PartnerModalProps) => {
  const createPartner = useCreatePartner()
  const updatePartner = useUpdatePartner()
  const assetTypes = useAssetTypesList()
  const [selectedAssetTypePrice, setSelectedAssetTypePrice] =
    useState<AssetTypePriceConfig>()
  const { isOpen, onOpen } = useDisclosure()
  const [uploadingPercentage, setUploadingPercentage] = useState<
    number | undefined
  >(undefined)
  const toast = useToast()
  const intl = useIntl()
  const newPartnerTitleMessage = intl.formatMessage({
    id: 'partners.new',
    description: 'New partners title message',
    defaultMessage: 'New partner',
  })
  const createdSuccessfullMessage = intl.formatMessage({
    id: 'partners.createdSuccessfully',
    description: 'Partners created successfully toast',
    defaultMessage: 'Partner created successfully',
  })
  const updatedSuccessfullMessage = intl.formatMessage({
    id: 'partners.updatedSuccessfully',
    description: 'Partners updated successfully toast',
    defaultMessage: 'Partner updated successfully',
  })
  const title = partner ? partner.name : newPartnerTitleMessage

  const rolloutPhase = Object.values(RolloutPhase)

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CreatePartnerDto>({ defaultValues: partner })

  const columnsAssetsTypePrice = useMemo<Column<AssetTypePriceConfig>[]>(
    () => [
      {
        Header: intl.formatMessage({
          id: 'asset-types',
          description: 'Asset types label',
          defaultMessage: 'Asset types label',
        }),
        id: 'asset-type-name',
        accessor: (assetTypeConfig) =>
          `${assetTypeConfig?.assetType?.brand.ticker}, ${assetTypeConfig?.assetType?.ticker}, ${assetTypeConfig?.assetType?.type}, ${assetTypeConfig?.assetType?.containerType} (${assetTypeConfig?.assetType?.dimension.height}, ${assetTypeConfig?.assetType?.dimension.width}, ${assetTypeConfig?.assetType?.dimension.length})`,
      },
      {
        Header: intl.formatMessage({
          id: 'partners.assetTypePriceConfig.minAmount',
          description: 'Minimum Amount associated to asset type price config',
          defaultMessage: 'Minimum Amount',
        }),
        id: 'minAmount',
        accessor: 'minAmount',
      },
      {
        Header: intl.formatMessage({
          id: 'partners.assetTypePriceConfig.maxAmount',
          description: 'Maximum Amount associated to asset type price config',
          defaultMessage: 'Maximum Amount',
        }),
        id: 'maxAmount',
        accessor: 'maxAmount',
      },
      {
        Header: intl.formatMessage({
          id: 'partners.assetTypePriceConfig.price',
          description: 'Price associated to asset type price config',
          defaultMessage: 'Price',
        }),
        id: 'price',
        accessor: 'price',
      },
    ],
    [selectedAssetTypePrice]
  )

  const onSubmit: SubmitHandler<CreatePartnerDto> = (data) => {
    if (!data.assetTypePrices) data.assetTypePrices = []

    if (data.coverageImageFile && data.coverageImageFile.length) {
      uploadImage(data)
    } else if (partner) {
      updatePartner.mutate(data)
    } else {
      createPartner.mutate(data)
    }
  }

  const uploadImage = (data: CreatePartnerDto) => {
    const file = data.coverageImageFile[0]
    const storage = getStorage()
    const fileNameComponents = file.name.split('.')
    const extension = fileNameComponents[fileNameComponents.length - 1]
    const kebabCasePartnerName = _.kebabCase(data.name)
    const storageRef = ref(
      storage,
      `/public/partners/images/coverage_${kebabCasePartnerName}.${extension}`
    )
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )

        setUploadingPercentage(progress)
      },
      (error) => {
        toast({
          title: 'Error updloading image, try again later',
          status: 'error',
          isClosable: true,
          position: 'top-right',
        })
      },
      async () => {
        const url = await getDownloadURL(uploadTask.snapshot.ref)
        setValue('coverageImageUrl', url)
        setUploadingPercentage(undefined)
        data.coverageImageUrl = url

        if (partner) {
          updatePartner.mutate(data)
        } else {
          createPartner.mutate(data)
        }
      }
    )
  }

  useEffect(() => {
    if (createPartner.isSuccess || updatePartner.isSuccess) {
      onClosePartner()
    }
  }, [createPartner.isSuccess, updatePartner.isSuccess])

  const selectAssetTypePriceConfig = (assetTypeId: string) => {
    setSelectedAssetTypePrice({
      ...selectedAssetTypePrice,
      assetType: assetTypes.data?.find(
        (assetType) => assetType.id == assetTypeId
      ),
      minAmount: 0,
      maxAmount: 1000,
      price: partner?.unitPriceCycle ?? 1000,
    })
    onOpen()
  }

  const editAssetTypePriceConfig = (assetTypePrice: AssetTypePriceConfig) => {
    setSelectedAssetTypePrice(assetTypePrice)
    onOpen()
  }

  const deleteAssetTypePriceConfig = (assetTypePrice: AssetTypePriceConfig) => {
    if (partner) {
      var index = partner?.assetTypePrices.indexOf(assetTypePrice)
      if (index !== undefined && index > -1) {
        partner?.assetTypePrices.splice(index, 1)
        reset(partner)
      }
      onCloseAssetTypePriceConfig()
    }
  }

  const onCloseAssetTypePriceConfig = () => {
    setSelectedAssetTypePrice(undefined)
  }

  return (
    <>
      <Modal size="3xl" isOpen={isOpenPartner} onClose={onClosePartner}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              <Stack spacing="3">
                <FormControl
                  id="name"
                  isRequired
                  isInvalid={errors.name !== undefined}>
                  <FormLabel>
                    <FormattedMessage
                      id="partners.formlabel.name"
                      description="Partners name form label"
                      defaultMessage="Name"
                    />
                  </FormLabel>
                  <Input {...register('name')} name="name" />
                </FormControl>
                <FormControl
                  id="nit"
                  isRequired
                  isInvalid={errors.nit !== undefined}>
                  <FormLabel>
                    <FormattedMessage
                      id="partners.formlabel.nit"
                      description="Partners nit form label"
                      defaultMessage="Nit"
                    />
                  </FormLabel>
                  <Input {...register('nit')} name="nit" />
                </FormControl>
                <FormControl
                  id="mainImage"
                  isInvalid={errors.mainImage !== undefined}>
                  <FormLabel>
                    <FormattedMessage
                      id="partners.formlabel.mainImage"
                      description="Partners mainImage form label"
                      defaultMessage="Main image"
                    />
                  </FormLabel>
                  <Input {...register('mainImage')} name="mainImage" />
                </FormControl>
                <FormControl
                  id="shortDescription"
                  isInvalid={errors.shortDescription !== undefined}>
                  <FormLabel>
                    <FormattedMessage
                      id="partners.formlabel.shortDescription"
                      description="Partners shortDescription form label"
                      defaultMessage="Short description"
                    />
                  </FormLabel>
                  <Input
                    {...register('shortDescription')}
                    name="shortDescription"
                  />
                </FormControl>
                <FormControl
                  id="description"
                  isInvalid={errors.description !== undefined}>
                  <FormLabel>
                    <FormattedMessage
                      id="partners.formlabel.description"
                      description="Partners description form label"
                      defaultMessage="Description"
                    />
                  </FormLabel>
                  <Textarea {...register('description')} name="description" />
                </FormControl>
                <FormControl
                  id="website"
                  isInvalid={errors.website !== undefined}>
                  <FormLabel>
                    <FormattedMessage
                      id="partners.formlabel.website"
                      description="Partners website form label"
                      defaultMessage="Website"
                    />
                  </FormLabel>
                  <Input {...register('website')} name="website" />
                </FormControl>
                <SimpleGrid columns={[1, 2, 2]} spacing="2">
                  <FormControl
                    isRequired
                    isInvalid={errors.isVisible !== undefined}>
                    <FormLabel htmlFor="isVisible">
                      <FormattedMessage
                        id="partners.formlabel.isVisible"
                        description="Partners isVisible form label"
                        defaultMessage="Visible branch"
                      />
                    </FormLabel>
                    <Switch
                      id="isVisible"
                      size="lg"
                      {...register('isVisible')}
                    />
                  </FormControl>
                  <FormControl
                    id="rolloutPhase"
                    isRequired
                    isInvalid={errors.rolloutPhase !== undefined}>
                    <FormLabel>
                      <FormattedMessage
                        id="partners.formlabel.rolloutphase"
                        description="Partners rollout phase form label"
                        defaultMessage="Rollout Phase"
                      />
                    </FormLabel>
                    <Select
                      placeholder="Select a rollout phase"
                      {...register('rolloutPhase', {
                        required: true,
                      })}>
                      {rolloutPhase.map((phase) => (
                        <option key={phase} value={phase}>
                          {_.capitalize(phase)}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </SimpleGrid>
                <FormControl
                  id="appnotes"
                  isInvalid={errors.appNotes !== undefined}>
                  <FormLabel>
                    <FormattedMessage
                      id="partners.formlabel.appnotes"
                      description="Partners appnots form label"
                      defaultMessage="App Notes"
                    />
                  </FormLabel>
                  <Textarea {...register('appNotes')} name="appNotes" />
                </FormControl>
                <FormControl
                  id="appnotes"
                  isInvalid={errors.appNotes !== undefined}>
                  <FormLabel>
                    <FormattedMessage
                      id="partners.formlabel.appnoteslink"
                      description="Partners appnoteslink form label"
                      defaultMessage="App Notes Link"
                    />
                  </FormLabel>
                  <Input {...register('appNotesLink')} name="appNotesLink" />
                </FormControl>
                <FormControl
                  id="coverageImageFile"
                  isRequired
                  isInvalid={errors.coverageImageFile !== undefined}>
                  <FormLabel>
                    <FormattedMessage
                      id="partners.formlabel.coverageImageFile"
                      description="Partners coverageImageFile form label"
                      defaultMessage="Coverage Image"
                    />
                  </FormLabel>
                  <Input
                    {...register('coverageImageFile')}
                    type="file"
                    accept="/image/*"
                    mb={6}
                  />
                  {partner?.coverageImageUrl && (
                    <Image
                      boxSize={300}
                      objectFit="contain"
                      src={partner.coverageImageUrl}
                    />
                  )}
                </FormControl>
              </Stack>
              <br />
              <HStack spacing="2">
                <FormControl
                  id="unitPriceOrder"
                  isInvalid={errors.unitPriceOrder !== undefined}>
                  <FormLabel>
                    <FormattedMessage
                      id="partners.formlabel.unitPriceOrder"
                      description="Partners unitPriceOrder form label"
                      defaultMessage="Order Price"
                    />
                  </FormLabel>
                  <Input
                    {...register('unitPriceOrder')}
                    name="unitPriceOrder"
                  />
                </FormControl>
                <FormControl
                  id="unitPriceCycle"
                  isInvalid={errors.unitPriceCycle !== undefined}>
                  <FormLabel>
                    <FormattedMessage
                      id="partners.formlabel.unitPriceCycle"
                      description="Partners unitPriceCycle form label"
                      defaultMessage="Cycle Price"
                    />
                  </FormLabel>
                  <Input
                    {...register('unitPriceCycle')}
                    name="unitPriceCycle"
                  />
                </FormControl>
              </HStack>
              <Stack>
                <Accordion allowMultiple>
                  <AccordionItem
                    marginY="5"
                    borderColor="gray.600"
                    border="1px"
                    borderRadius="5">
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        <FormattedMessage
                          id="partners.assetTypePriceConfig.priceTitle"
                          defaultMessage="Asset Type Prices"
                          description="Assets type prices title"
                        />
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                      <HStack>
                        <Select
                          placeholder={intl.formatMessage({
                            id: 'branches.asset.type.config.select',
                            description: 'Select asset type message',
                            defaultMessage: 'Select asset type',
                          })}
                          onChange={(event) =>
                            selectAssetTypePriceConfig(event.target.value)
                          }>
                          {assetTypes.data?.map((assetType) => (
                            <option key={assetType.id} value={assetType.id}>
                              {`${assetType.brand.ticker}, ${assetType.ticker}, ${assetType.type}, ${assetType.containerType} (${assetType.dimension.height}, ${assetType.dimension.width}, ${assetType.dimension.length})`}
                            </option>
                          ))}
                        </Select>
                      </HStack>
                      <SortableDataTable
                        data={partner?.assetTypePrices ?? []}
                        columns={columnsAssetsTypePrice}
                        paginationAuto={false}
                        currentPage={0}
                        onRowSelected={editAssetTypePriceConfig}
                      />
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Stack>
              <Progress size="sm" value={uploadingPercentage} />
            </ModalBody>
            <ModalFooter>
              <Button
                type="submit"
                colorScheme="teal"
                fontSize="md"
                isLoading={createPartner.isLoading || updatePartner.isLoading}
                onClick={handleSubmit(onSubmit)}>
                <FormattedMessage
                  id="partners.button.save"
                  description="Partners save button"
                  defaultMessage="Save"
                />
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      {selectedAssetTypePrice ? (
        <Modal isOpen={isOpen} onClose={onCloseAssetTypePriceConfig} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <FormattedMessage
                id="branches.asset.type.config.add"
                description="Add asset type"
                defaultMessage="Add asset type"
              />
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody marginBottom={5}>
              <Text fontWeight={'bold'}>
                <FormattedMessage
                  id="partners.assetTypePriceConfig.minAmount"
                  description="Minimum amount label for asset type price config"
                  defaultMessage="Minimum Amount"
                />
              </Text>
              <NumberInput
                name="minAmount"
                defaultValue={0}
                min={0}
                value={selectedAssetTypePrice.minAmount}
                onChange={(value) =>
                  setSelectedAssetTypePrice({
                    ...selectedAssetTypePrice,
                    minAmount: parseInt(value),
                  })
                }>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Text fontWeight={'bold'}>
                <FormattedMessage
                  id="partners.assetTypePriceConfig.maxAmount"
                  description="Maximum Amount label for asset type price config"
                  defaultMessage="Maximum Amount"
                />
              </Text>
              <NumberInput
                name="maxAmount"
                defaultValue={0}
                min={0}
                value={selectedAssetTypePrice.maxAmount}
                onChange={(value) =>
                  setSelectedAssetTypePrice({
                    ...selectedAssetTypePrice,
                    maxAmount: parseInt(value),
                  })
                }>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Text fontWeight={'bold'}>
                <FormattedMessage
                  id="partners.assetTypePriceConfig.price"
                  description="Price label for asset type price config"
                  defaultMessage="Price"
                />
              </Text>
              <NumberInput
                name="price"
                defaultValue={0}
                min={selectedAssetTypePrice.price}
                value={selectedAssetTypePrice.price}
                onChange={(value) =>
                  setSelectedAssetTypePrice({
                    ...selectedAssetTypePrice,
                    price: parseInt(value),
                  })
                }>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </ModalBody>
            <ModalFooter>
              <Button
                type="submit"
                colorScheme="teal"
                fontSize="md"
                hidden={
                  selectedAssetTypePrice.id == undefined &&
                  (partner?.assetTypePrices == undefined ||
                    partner?.assetTypePrices.find(
                      (assetType) => assetType.id == selectedAssetTypePrice.id
                    ) == undefined)
                }
                onClick={() =>
                  deleteAssetTypePriceConfig(selectedAssetTypePrice)
                }>
                <FormattedMessage
                  id="branches.asset.type.config.delete.button"
                  description="Delete button for asset type config"
                  defaultMessage="Delete"
                />
              </Button>
              <Spacer />
              <Button
                type="submit"
                colorScheme="teal"
                fontSize="md"
                isLoading={status === 'loading'}
                onClick={() => {
                  if (
                    selectedAssetTypePrice &&
                    partner &&
                    selectedAssetTypePrice.maxAmount &&
                    selectedAssetTypePrice.price
                  ) {
                    if (!partner.hasOwnProperty('assetTypePrices'))
                      partner.assetTypePrices = []

                    if (selectedAssetTypePrice.id !== undefined) {
                      var index = partner?.assetTypePrices.filter(
                        (a) => a.id == selectedAssetTypePrice.id
                      )

                      if (index !== undefined && index.length > 0) {
                        partner?.assetTypePrices.splice(
                          partner.assetTypePrices.indexOf(index[0]),
                          1
                        )
                        reset(partner)
                      }
                    }

                    partner.assetTypePrices.push(selectedAssetTypePrice)
                    reset(partner)
                    onCloseAssetTypePriceConfig()
                  }
                }}>
                <FormattedMessage
                  id="branches.asset.type.config.save.button"
                  description="Save button for asset type config"
                  defaultMessage="Save"
                />
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : null}
    </>
  )
}

export default PartnerModal

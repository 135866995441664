import { useDisclosure } from '@chakra-ui/hooks'
import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Column } from 'react-table'
import SortableDataTable from '../common/SortableDataTable'
import { Partner } from './Partner.entity'
import PartnerModal from './PartnerModal'

export type PartnersListProp = {
  partners: Partner[]
}

const PartnersList = ({ partners }: PartnersListProp) => {
  const { isOpen, onOpen } = useDisclosure()
  const [selectedPartner, setSelectedPartner] = useState<Partner>()
  const intl = useIntl()

  const columns = useMemo<Column<Partner>[]>(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: intl.formatMessage({
          id: 'partners.name',
          description: 'Name attribute of a partner',
          defaultMessage: 'Name',
        }),
        accessor: 'name',
      },
      {
        Header: intl.formatMessage({
          id: 'partners.nit',
          description: 'NIT attribute of a partner',
          defaultMessage: 'NIT',
        }),
        accessor: 'nit',
      },
      {
        Header: intl.formatMessage({
          id: 'partners.website',
          description: 'Website attribute of a partner',
          defaultMessage: 'Website',
        }),
        accessor: 'website',
      },
      {
        Header: intl.formatMessage({
          id: 'partners.unitPriceOrder',
          description: 'unitPriceOrder attribute of a partner',
          defaultMessage: 'Order Price',
        }),
        accessor: 'unitPriceOrder',
      },
      {
        Header: intl.formatMessage({
          id: 'partners.unitPriceCycle',
          description: 'unitPriceCycle attribute of a partner',
          defaultMessage: 'Cycle Price',
        }),
        accessor: 'unitPriceCycle',
      },
    ],
    []
  )

  const editPartner = (partner: Partner) => {
    setSelectedPartner(partner)
    onOpen()
  }

  const onClose = () => {
    setSelectedPartner(undefined)
  }

  return (
    <>
      <SortableDataTable
        columns={columns}
        data={partners}
        onRowSelected={editPartner}
        currentPage={0}
        totalCount={partners.length}
        paginationAuto={false}
        filterAuto={false}
      />
      {selectedPartner && (
        <PartnerModal
          partner={selectedPartner}
          onClosePartner={onClose}
          isOpenPartner={isOpen}
        />
      )}
    </>
  )
}

export default PartnersList

import { Heading } from '@chakra-ui/layout'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import PickupsList from '../features/pickups/PickupsList'

const Pickups = () => {
  return (
    <>
      <Heading>
        <FormattedMessage
          id="pickups"
          defaultMessage="Pickups"
          description="Pickups screen title"
        />
      </Heading>
      <PickupsList />
    </>
  )
}

export default Pickups

import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  HStack,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
  Text,
  chakra,
} from '@chakra-ui/react'
import { Table, TableProps, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table'
import es from 'date-fns/locale/es'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  TableOptions,
  useFilters,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'

export interface SortableDataTableProps<T extends object>
  extends TableOptions<T>,
    TableProps {
  onRowSelected?: (element: T) => void
}

export const Filter = ({ column }: any) => {
  return (
    <Box style={{ marginTop: 5 }}>
      {column.canFilter && column.render('Filter')}
    </Box>
  )
}

export const DefaultColumnFilter = ({
  column: { filterValue, setFilter },
}: any) => {
  return (
    <Input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined)
      }}
    />
  )
}

export const NumberColumnFilter = ({
  column: { filterValue, setFilter },
}: any) => {
  return (
    <NumberInput
      value={filterValue || ''}
      type="number"
      onChange={(e) => {
        setFilter(e || undefined)
      }}
      placeholder={'Min (0)'}
      min={0}>
      <NumberInputField />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  )
}

export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id, listOptions },
}: any) => {
  const options = !listOptions
    ? useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach((row: any) => {
          options.add(row.values[id])
        })
        return [...options.values()]
      }, [id, preFilteredRows])
    : listOptions

  return (
    <Select
      id="custom-select"
      type="select"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined)
      }}
      width="-webkit-fit-content">
      <option value=""></option>
      {options?.map((option: string) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </Select>
  )
}

export const DateColumnFilter = ({
  column: { filterValue, setFilter, locale },
}: any) => {
  registerLocale('es', es)
  return (
    <ReactDatePicker
      dateFormat="dd/MM/yyyy"
      onChange={setFilter}
      todayButton={'Today'}
      selected={filterValue}
      locale={locale}
      className="chakra-input css-xpongc css-esakc6 css-1c6j008"
    />
  )
}

function SortableDataTable<T extends object>({
  onRowSelected,
  columns,
  data,
  setPage,
  setFilters,
  loading,
  currentPage,
  paginationAuto,
  filterAuto,
  totalCount,
  sizePage,
  filtersInit,
  enableFilters,
  ...tableProps
}: SortableDataTableProps<T>) {
  const tableOptions = useMemo<TableOptions<T>>(
    () => ({
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: data && data.length > 0 ? currentPage ?? 0 : 0,
        filters: filtersInit === undefined ? [] : filtersInit,
      },
      manualFilters: filterAuto,
      pageCount: Math.ceil(totalCount / sizePage),
      manualPagination: paginationAuto,
    }),
    [columns, data, totalCount]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageCount,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, filters },
  } = useTable<T>(tableOptions, useFilters, useSortBy, usePagination)

  const intl = useIntl()

  let setFiltersDebounced: Function

  if (setFilters) {
    setFiltersDebounced = useCallback(_.debounce(setFilters, 300), [])
  }

  useEffect(() => {
    setPage ? setPage(pageIndex, pageSize) : null
  }, [pageIndex, pageSize])

  useEffect(() => {
    setFilters ? setFiltersDebounced(filters) : null
  }, [filters, totalCount])

  const onChangeInSelect = (event: any) => {
    setPageSize(Number(event.target.value))
  }

  const onChangeInInput = (event: any) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  return (
    <>
      <Table colorScheme="teal" {...getTableProps()} {...tableProps}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <Th key={index}>
                  <Box
                    {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <Text isTruncated>
                      {column.render('Header')}
                      <chakra.span pl="4">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <TriangleDownIcon aria-label="sorted descending" />
                          ) : (
                            <TriangleUpIcon aria-label="sorted ascending" />
                          )
                        ) : null}
                      </chakra.span>
                    </Text>
                  </Box>
                  {column.canFilter && enableFilters && (
                    <Box>
                      <Filter column={column} />
                    </Box>
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {data.length <= 0 ? (
            <Tr>
              <Td colSpan={columns.length}>
                <Center>
                  <FormattedMessage
                    id="table.notRecordsFound"
                    description="No records found label"
                    defaultMessage="No records found"
                  />
                </Center>
              </Td>
            </Tr>
          ) : (
            page.map((row) => {
              prepareRow(row)
              return (
                <Tr
                  cursor="pointer"
                  _hover={{
                    backgroundColor: 'var(--chakra-colors-teal-700)',
                  }}
                  onClick={() =>
                    onRowSelected ? onRowSelected(row.original) : null
                  }
                  {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <Td {...cell.getCellProps()}> {cell.render('Cell')}</Td>
                  ))}
                </Tr>
              )
            })
          )}
          {loading ? (
            <Tr>
              <Td colSpan={columns.length}>
                <Box marginTop="2">
                  <FormattedMessage
                    id="loading"
                    description="Loading label"
                    defaultMessage="Loading"
                  />
                </Box>
              </Td>
            </Tr>
          ) : null}
        </Tbody>
      </Table>
      <HStack flex="1" justifyContent="center" marginTop="5" marginBottom="10">
        <Button
          color="primary"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}>
          {'<<'}
        </Button>
        <Button
          color="primary"
          onClick={previousPage}
          disabled={!canPreviousPage}>
          {'<'}
        </Button>
        <Stack md={2} style={{ marginTop: 7 }}>
          Page{' '}
          <strong>
            {pageIndex + 1}{' '}
            {intl.formatMessage({
              id: 'of',
              description: 'Of label',
              defaultMessage: 'Of',
            })}{' '}
            {pageOptions.length}
          </strong>
        </Stack>
        <Stack md={2}>
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={pageOptions.length}
            defaultValue={(pageIndex ?? 0) + 1}
            onChange={onChangeInInput}
          />
        </Stack>
        <Stack md={2}>
          <Select type="select" value={pageSize} onChange={onChangeInSelect}>
            {[5, 10, 15, 20, 30, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {intl.formatMessage({
                  id: 'show',
                  description: 'Show label',
                  defaultMessage: 'Show',
                })}{' '}
                {pageSize}
              </option>
            ))}
          </Select>
        </Stack>
        <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
          {'>'}
        </Button>
        <Button
          color="primary"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}>
          {'>>'}
        </Button>
      </HStack>
    </>
  )
}

export default SortableDataTable

import { Box, Container, Flex } from '@chakra-ui/layout'
import React, { FC } from 'react'
import Sidebar from './Sidebar'
import Topbar from './Topbar'

const Layout: FC = ({ children }) => {
  return (
    <Container h="100vh" maxW="container.m" paddingX="10">
      <Topbar />
      <Flex>
        <Sidebar />
        <Box flex="1">{children}</Box>
      </Flex>
    </Container>
  )
}

export default Layout

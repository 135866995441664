import React from 'react'
import { Center, Heading } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/button'
import { FormattedMessage } from 'react-intl'
import { useBrandsList } from '../features/brands/queries'
import { AddIcon } from '@chakra-ui/icons'
import { Spinner } from '@chakra-ui/spinner'
import BrandsList from '../features/brands/BrandsList'
import { useDisclosure } from '@chakra-ui/hooks'
import BrandModal from '../features/brands/BrandModal'

const Brands = () => {
  const { onOpen, onClose, isOpen } = useDisclosure()
  const { data, isLoading } = useBrandsList()

  return (
    <>
      <Heading>
        <FormattedMessage
          id="brands"
          defaultMessage="Brands"
          description="Brands screen title"
        />
      </Heading>
      <Button
        leftIcon={<AddIcon />}
        colorScheme="teal"
        my="3"
        onClick={() => onOpen()}>
        <FormattedMessage
          id="brands.button.new"
          description="Brands new label"
          defaultMessage="New"
        />
      </Button>
      {isLoading ? (
        <Center>
          <Spinner size="lg" />
        </Center>
      ) : (
        <BrandsList brands={data ?? []} />
      )}
      <BrandModal isOpen={isOpen} onClose={onClose} />
    </>
  )
}

export default Brands

import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Column } from 'react-table'
import SortableDataTable, {
  SelectColumnFilter,
} from '../common/SortableDataTable'
import { Payment, PayUState } from './Payment.entity'

export type PaymentListProp = {
  payments: Payment[]
}

const PaymentsList = ({ payments }: PaymentListProp) => {
  const intl = useIntl()

  const states = Object.values(PayUState)

  const columns = useMemo<Column<Payment>[]>(
    () => [
      {
        Header: intl.formatMessage({
          id: 'payments.userEmail',
          description: 'User email',
          defaultMessage: 'User email',
        }),
        accessor: (payment) => payment.user.email,
      },
      {
        Header: intl.formatMessage({
          id: 'payments.paymentMethod',
          description: 'Payment Method a payment',
          defaultMessage: 'Payment Method',
        }),
        accessor: (payment) => payment.paymentMethod.id,
      },
      {
        Header: intl.formatMessage({
          id: 'payments.amount',
          description: 'Payment amount',
          defaultMessage: 'Amount',
        }),
        accessor: 'amount',
      },
      {
        Header: intl.formatMessage({
          id: 'payments.state',
          description: 'State a payment',
          defaultMessage: 'State',
        }),
        accessor: 'state',
        Filter: SelectColumnFilter,
        listOptions: states,
      },
      {
        Header: intl.formatMessage({
          id: 'payments.createdAt',
          description: 'Payment created at title',
          defaultMessage: 'Created at',
        }),
        accessor: 'createdAt',
      },
    ],
    []
  )

  return (
    <>
      <SortableDataTable
        columns={columns}
        data={payments}
        currentPage={0}
        totalCount={payments.length}
        filterAuto={false}
      />
    </>
  )
}

export default PaymentsList

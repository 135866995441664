import { useMutation, useQuery, useQueryClient } from 'react-query'
import useXicloClient, { XicloResource } from '../common/useXicloClient'
import { Brand } from './Brand.entity'

export const brandKeys = {
  all: ['brands'] as const,
  lists: () => [...brandKeys.all, 'list'] as const,
  list: () => [...brandKeys.lists()] as const,
}

const findAll = async () => {
  const client = await useXicloClient(XicloResource.Brand)
  const { data } = await client.get<Brand[]>('/')

  return data
}

export const useBrandsList = () => {
  return useQuery<Brand[], Error>(brandKeys.list(), () => findAll())
}

const create = async (brand: Partial<Brand>) => {
  const client = await useXicloClient(XicloResource.Brand)
  const { data } = await client.post<Brand>('/', brand)

  return data
}

export const useCreateBrand = () => {
  const queryClient = useQueryClient()

  return useMutation((brand: Partial<Brand>) => create(brand), {
    onSuccess: () => {
      queryClient.invalidateQueries(brandKeys.lists())
    },
  })
}

const update = async (brand: Partial<Brand>) => {
  const client = await useXicloClient(XicloResource.Brand)
  const { data } = await client.patch<Brand>(`/${brand.id}`, brand)

  return data
}

export const useUpdateBrand = () => {
  const queryClient = useQueryClient()

  return useMutation((brand: Partial<Brand>) => update(brand), {
    onSuccess: () => {
      queryClient.invalidateQueries(brandKeys.lists())
    },
  })
}

export const template = {
  header: {
    title: {
      fontSize: 'xl',
      fontWeight: 'bold',
      marginBottom: -3,
    },

    label: {
      color: 'gray',
      letterSpacing: 3,
      marginBottom: 3,
      fontSize: 'md',
    },

    text: {
      fontWeight: 'bold',
      fontSize: 'md',
    },

    line: {
      backgroundColor: 'black',
      width: 5,
      height: 1,
    },

    boxFieldPeriod: {
      borderEnd: '1px',
      borderColor: 'gray',
      marginEnd: 30,
      width: '40%',
    },

    boxFieldPartner: {
      borderEnd: '1px',
      borderColor: 'gray',
      marginEnd: 30,
      width: '30%',
    },

    boxFieldClient: {
      marginEnd: 30,
    },

    boxFieldReceive: {
      flex: 1,
      paddingStart: 8,
    },
  },

  footer: {
    label: {
      color: 'gray',
      fontSize: 'sm',
      padding: 5,
      textAlign: 'right',
    },

    box: {
      left: '0px',
      bottom: '0px',
      width: '100%',
    },
  },

  body: {
    title: {
      letterSpacing: 3,
      fontSize: 'xs',
      width: '170px',
      fontWeight: 'normal',
    },

    values: {
      letterSpacing: 3,
      fontSize: 'l',
      width: '170px',
    },

    row: {
      paddingBottom: 1,
      letterSpacing: 3,
      fontSize: 'xs',
      width: '150px',
    },

    pricePerOrder: {
      marginTop: 5,
      color: 'black',
      letterSpacing: 3,
      fontSize: 'xs',
    },

    lineDashed: {
      borderColor: 'gray',
      borderStyle: 'dashed',
      marginInline: 5,
    },

    labelSignature: {
      letterSpacing: 3,
      color: 'gray',
      fontSize: 'xs',
    },
  },
}

import React, { FC } from 'react'
import { Redirect, Route } from 'react-router'
import { useAuth } from '../../providers/AuthProvider'
import Layout from './Layout'

export type ProtectedRouteProps = {
  redirectTo?: string
  exact?: boolean
  path: string
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({
  children,
  redirectTo,
  exact,
  path,
}) => {
  const { user, isInitializing } = useAuth()

  if (!user && !isInitializing) {
    return <Redirect to={redirectTo ?? '/login'} />
  }

  return (
    <Route exact={exact} path={path}>
      <Layout>{children}</Layout>
    </Route>
  )
}

export default ProtectedRoute

import { Button } from '@chakra-ui/button'
import { useDisclosure } from '@chakra-ui/hooks'
import { AddIcon } from '@chakra-ui/icons'
import { Heading } from '@chakra-ui/layout'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import AssetModal from '../features/assets/AssetModal'
import AssetsList from '../features/assets/AssetsList'

const Assets = () => {
  const { onOpen, onClose, isOpen } = useDisclosure()

  return (
    <>
      <Heading>
        <FormattedMessage
          id="assets"
          defaultMessage="Assets"
          description="Assets screen title"
        />
      </Heading>
      <Button
        leftIcon={<AddIcon />}
        colorScheme="teal"
        my="3"
        onClick={() => onOpen()}>
        <FormattedMessage
          id="assets.button.new"
          description="Assets new label"
          defaultMessage="New"
        />
      </Button>
      <AssetsList />
      <AssetModal isOpen={isOpen} onClose={onClose} />
    </>
  )
}

export default Assets

import { useDisclosure } from '@chakra-ui/react'
import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Column } from 'react-table'
import SortableDataTable, {
  SelectColumnFilter,
} from '../common/SortableDataTable'
import { Branch, BranchType } from './Branch.entity'
import BranchModal from './BranchModal'
import { useBranchesList } from './queries'

const BranchList = () => {
  const { isOpen, onOpen } = useDisclosure()
  const [selectedBranch, setSelectedBranch] = useState<Branch>()
  const { data, isLoading } = useBranchesList([], 0, 50)
  const intl = useIntl()

  const types = Object.values(BranchType)

  const columns = useMemo<Column<Branch>[]>(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: intl.formatMessage({
          id: 'branch.name',
          description: 'Name attribute of a branch',
          defaultMessage: 'Name',
        }),
        accessor: 'name',
      },
      {
        Header: intl.formatMessage({
          id: 'branch.branchType',
          description: 'Type of the branch',
          defaultMessage: 'Branch type',
        }),
        accessor: 'branchType',
        Filter: SelectColumnFilter,
        listOptions: types,
      },
      {
        Header: intl.formatMessage({
          id: 'branch.partner',
          description: 'Name of the partner',
          defaultMessage: 'Partner',
        }),
        accessor: (branch) => branch.partner.name,
      },
    ],
    []
  )

  const editBranch = (branch: Branch) => {
    setSelectedBranch(branch)
    onOpen()
  }

  const onClose = () => {
    setSelectedBranch(undefined)
    // TODO: Review why this line here
    // window.location.reload()
  }

  return (
    <>
      <SortableDataTable
        columns={columns}
        data={data?.data ?? []}
        onRowSelected={editBranch}
        paginationAuto={true}
        filterAuto={true}
        fontSize="smaller"
        totalCount={data?.data.length ?? 0}
        currentPage={0}
        loading={isLoading}
      />
      {selectedBranch && (
        <BranchModal
          branch={selectedBranch}
          isOpenBranch={isOpen}
          onCloseBranch={onClose}
        />
      )}
    </>
  )
}

export default BranchList

import { Heading } from '@chakra-ui/react'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import AssetLogsList from '../features/asset-logs/AssetLogsList'

const AssetLogs = () => {
  return (
    <>
      <Heading marginBottom="10">
        <FormattedMessage
          id="asset_logs"
          description="Asset Logs screen title"
          defaultMessage="Asset Logs"
        />
      </Heading>
      <AssetLogsList />
    </>
  )
}

export default AssetLogs

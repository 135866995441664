import { DeepPartial, extendTheme, ThemeConfig } from '@chakra-ui/react'
import Link from './components/link'

const config: DeepPartial<ThemeConfig> = {
  initialColorMode: 'dark',
  useSystemColorMode: true,
}

const theme = {
  config,
  components: {
    Link,
  },
}

export default extendTheme(theme)

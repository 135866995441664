import PaginatedResult from '../common/PaginatedResult.entity'
import useXicloClient, { XicloResource } from '../common/useXicloClient'
import { Payment } from './Payment.entity'

const usePaymentApi = async () => {
  const client = await useXicloClient(XicloResource.Payments)

  const find = async (offset: number, limit: number) => {
    const resp = await client.get<PaginatedResult<Payment>>('', {
      params: {
        offset: offset,
        limit: limit,
      },
    })

    return resp.data
  }

  return { find }
}

export default usePaymentApi

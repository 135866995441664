import { AssetType } from '../asset-types/AssetType.entity'
import { AssetLocation } from '../assets/Asset.entity'
import { Partner } from '../partners/Partner.entity'

export enum BranchType {
  Restaurant = 'restaurant',
  Cleaner = 'cleaner',
  Warehouse = 'warehouse',
}

export interface Point {
  type: 'Point'
  coordinates?: number[]
}

export interface Location {
  country: string
  city: string
  address: string
  additionalInfo: string
  zipCode: string
  phoneNumbers: string[]
  point: Point
}

export interface Branch {
  id?: string
  name: string
  pin: string
  branchType: BranchType
  numOfAssets: number
  isMain: boolean
  isVisible: boolean
  createdAt: Date
  updatedAt: Date
  partner: Partner
  isArchived: boolean
  location: Location
  assetTypes: AssetTypeConfig[]
  coverageImageUrl?: string
}

export interface CreateBranchDto {
  name: string
  pin: string
  branchType: BranchType
  isMain: boolean
  isVisible: boolean
  createdAt: Date
  updatedAt: Date
  partner: Partner
  location: Location
  assetTypes: AssetTypeConfig[]
  coverageImageUrl?: string
  coverageImageFile?: any
}

export interface AssetTypeConfig {
  assetType?: AssetType
  min?: number
  max?: number
}

export function parseBranchTypeToAssetLocation(branchType: BranchType) {
  switch (branchType) {
    case BranchType.Restaurant:
      return AssetLocation.partner
    case BranchType.Cleaner:
      return AssetLocation.cleaner
    case BranchType.Warehouse:
      return AssetLocation.xiclo
    default:
      return AssetLocation.xiclo
  }
}

import { EmbeddedAsset } from './EmbeddedAsset'
import { UserRef } from './UserRef.entity'

export enum Action {
  Delivered = 'delivered',
  Bought = 'bought',
  Returned = 'returned',
  InTransit = 'inTransit',
}

export interface AssetLog {
  id: string
  action: Action
  assets: EmbeddedAsset[]
  source: UserRef
  target: UserRef
  createdAt: Date
}

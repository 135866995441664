import { Brand } from '../brands/Brand.entity'
import { QrDimension } from '../qr-dimension/QrDimension.entity'

export interface AssetType {
  id: string
  brand: Brand
  ticker: string
  providerPhoneNumber: string
  type: Type
  containerType: ContainerType
  volume: number
  dimension: AssetDimension
  materialType: MaterialType
  archived: boolean
  qrDimension: QrDimension
  icon: string
}

export enum Type {
  Container = 'container',
  Lid = 'lid',
  Bag = 'bag',
}

export enum ContainerType {
  Cup = 'cup',
  Bowl = 'bowl',
  Bag = 'bag',
  SushiTray = 'sushiTray',
  Box = 'box',
}

export interface AssetDimension {
  height: number
  width: number
  length: number
}

export enum MaterialType {
  InsulatingBag = 'insulating_bag',
  Plastic = 'plastic',
  StainlessSteel = 'stainless_steel',
  Aluminium = 'aluminium',
  Tyveck = 'tyveck',
  LaminatedPolypropyleneFabric = 'laminated_polypropylene_fabric',
}

import { Asset } from '../assets/Asset.entity'
import { Branch } from '../branches/Branch.entity'
import { User } from '../users/User.entity'

export enum DeliveryOrderStatus {
  Created = 'created',
  Delivered = 'delivered',
}

export interface DeliveryOrder {
  id: string
  warehouse: Branch
  targetBranch: Branch
  status: DeliveryOrderStatus
  signedBy: User
  assets: Asset[]
  createdAt: Date
  updatedAt: Date
}

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react'
import _ from 'lodash'
import moment from 'moment'
import React, { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Column } from 'react-table'
import { AssetLog } from '../asset-logs/AssetLog.entity'
import { useAssetLogListByIds } from '../asset-logs/queries'
import { Asset } from '../assets/Asset.entity'
import { useAssetsByIds } from '../assets/queries'
import SortableDataTable from '../common/SortableDataTable'
import { Order } from './Order.entity'

export type OrderModalProps = {
  order?: Partial<Order> | undefined
  isOpen: boolean
  onClose: () => void
}

const OrderModal = ({ order, isOpen, onClose }: OrderModalProps) => {
  const borrowedAssets = useAssetsByIds(order?.assetsBorrowed?.toString() ?? '')
  const returnedAssets = useAssetsByIds(order?.assetsReturned?.toString() ?? '')

  let assetsPendingReturn = _.filter(borrowedAssets?.data, function (a: Asset) {
    let returnedAssetReferences = _.map(
      returnedAssets?.data,
      function (a: Asset) {
        return a.reference
      }
    )
    return !_.includes(returnedAssetReferences, a.reference)
  })

  const assetsLogs = useAssetLogListByIds(order?.logs?.toString() ?? '')
  const intl = useIntl()

  const columnsAssetLogs = useMemo<Column<AssetLog>[]>(
    () => [
      {
        Header: intl.formatMessage({
          id: 'assetlogs.date',
          description: 'CreatedAt attribute of a assetlog',
          defaultMessage: 'date',
        }),
        accessor: (al) => formatDate(al.createdAt),
        id: 'date',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: intl.formatMessage({
          id: 'assetlogs.action',
          description: 'Action attribute of a assetlog',
          defaultMessage: 'Action',
        }),
        accessor: 'action',
      },
      {
        Header: intl.formatMessage({
          id: 'assetlogs.number.assets',
          description: 'Number of assets label attribute of a assetlog',
          defaultMessage: 'Number of assets',
        }),
        accessor: (al) => al.assets.length,
        id: 'numberAssets',
      },
      {
        Header: intl.formatMessage({
          id: 'assetlogs.source',
          description: 'Source label attribute of a assetlog',
          defaultMessage: 'Source',
        }),
        accessor: (al) => {
          return `(${al.source.role}) ${al.source.userId.email}`
        },
        id: 'userSource',
      },
      {
        Header: intl.formatMessage({
          id: 'assetlogs.target',
          description: 'Target label attribute of a assetlog',
          defaultMessage: 'Target',
        }),
        accessor: (al) => {
          return `(${al.target.role}) ${al.target.userId.email}`
        },
        id: 'userTarget',
      },
    ],
    []
  )

  const columnsAssets = useMemo<Column<Asset>[]>(
    () => [
      {
        Header: intl.formatMessage({
          id: 'assets.reference',
          description: 'Reference attribute of an asset',
          defaultMessage: 'Reference',
        }),
        accessor: 'reference',
        disableFilters: true,
      },
      {
        Header: intl.formatMessage({
          id: 'assets.brand',
          description: 'Brand attribute of an asset',
          defaultMessage: 'Brand',
        }),
        accessor: (asset) => asset.type.brand.name,
        disableFilters: true,
      },
      {
        Header: intl.formatMessage({
          id: 'assets.type',
          description: 'Type attribute of an asset',
          defaultMessage: 'Type',
        }),
        disableFilters: true,
        accessor: (asset) => asset.type.type,
      },
      {
        Header: intl.formatMessage({
          id: 'assets.type.container',
          description: 'Container type attribute of an asset',
          defaultMessage: 'Type of container',
        }),
        disableFilters: true,
        accessor: (asset) => asset.type.containerType,
      },
    ],
    []
  )

  const formatDate = (date: Date) => {
    let d = moment(date)
    return `${d.format('DD/MM/YYYY')} - ${d.format('H:mm:ss')}`
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'l'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage
            id="orders"
            description="Orders screen title"
            defaultMessage="Orders"
          />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack marginBottom={3}>
            <Box width="200px" padding="2">
              <Text>
                <FormattedMessage
                  id="orders.branch"
                  description="Branch attribute of a Order"
                  defaultMessage="Name"
                />
              </Text>
            </Box>
            <Box
              border="1px"
              borderRadius="5"
              borderColor="gray.600"
              padding="2"
              flex="1">
              {order?.branch?.name}
            </Box>
          </HStack>
          <HStack marginBottom={3}>
            <Box width="200px" padding="2">
              <Text>
                <FormattedMessage
                  id="orders.user"
                  description="User attribute of a Order"
                  defaultMessage="User"
                />
              </Text>
            </Box>
            <Box
              border="1px"
              borderRadius="5"
              borderColor="gray.600"
              padding="2"
              flex="1">
              {order?.user?.name}
            </Box>
          </HStack>
          <HStack marginBottom={3}>
            <Box width="200px" padding="2">
              <Text>
                <FormattedMessage
                  id="orders.email"
                  description="Email attribute of a Order"
                  defaultMessage="Email"
                />
              </Text>
            </Box>
            <Box
              border="1px"
              borderRadius="5"
              borderColor="gray.600"
              padding="2"
              flex="1">
              <Link
                to={{
                  pathname: '/users',
                  state: { email: order?.user?.email },
                }}>
                {order?.user?.email}
              </Link>
            </Box>
          </HStack>
          <HStack marginBottom={3}>
            <Box width="200px" padding="2">
              <Text>
                <FormattedMessage
                  id="orders.status"
                  description="Status attribute of an order"
                  defaultMessage="Status"
                />
              </Text>
            </Box>
            <Box
              border="1px"
              borderRadius="5"
              borderColor="gray.600"
              padding="2"
              flex="1">
              {order?.status}
            </Box>
          </HStack>
          <HStack marginBottom={3}>
            <Box width="200px" padding="2">
              <Text>
                <FormattedMessage
                  id="orders.createdAt"
                  description="CreatedAt attribute of an order"
                  defaultMessage="Created"
                />
              </Text>
            </Box>
            <Box
              border="1px"
              borderRadius="5"
              borderColor="gray.600"
              padding="2"
              flex="1">
              {formatDate(order?.createdAt!)}
            </Box>
          </HStack>
          <HStack marginBottom={3}>
            <Box width="200px" padding="2">
              <Text>
                <FormattedMessage
                  id="orders.updatedAt"
                  description="Updated attribute of an order"
                  defaultMessage="Updated"
                />
              </Text>
            </Box>
            <Box
              border="1px"
              borderRadius="5"
              borderColor="gray.600"
              padding="2"
              flex="1">
              {formatDate(order?.updateAt!)}
            </Box>
          </HStack>
          <Stack spacing="3">
            <Accordion allowMultiple>
              <AccordionItem
                marginY="3"
                borderColor="gray.600"
                border="1px"
                borderRadius="5">
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <FormattedMessage
                        id="orders.assetslogs"
                        description="Assets logs attribute of an order"
                        defaultMessage="Assets logs"
                      />
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel>
                  <SortableDataTable
                    data={assetsLogs?.data ?? []}
                    columns={columnsAssetLogs}
                    currentPage={0}
                    paginationAuto={false}
                    totalCount={assetsLogs?.data?.length}
                  />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Stack>
          <Stack spacing="3">
            <Accordion allowMultiple>
              <AccordionItem
                marginY="5"
                borderColor="gray.600"
                border="1px"
                borderRadius="5">
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <FormattedMessage
                        id="orders.assetsPendingReturn"
                        description="Borrowed Assets minus Returned Assets"
                        defaultMessage="Assets Pending Return"
                      />
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel>
                  <SortableDataTable
                    data={assetsPendingReturn ?? []}
                    columns={columnsAssets}
                    currentPage={0}
                    paginationAuto={false}
                    totalCount={borrowedAssets?.data?.length}
                    enableFilters
                  />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Stack>
          <Stack spacing="3">
            <Accordion allowMultiple>
              <AccordionItem
                marginY="5"
                borderColor="gray.600"
                border="1px"
                borderRadius="5">
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <FormattedMessage
                        id="orders.assetsborrowed"
                        description="Assets borrowed attribute of an order"
                        defaultMessage="Assets borrowed"
                      />
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel>
                  <SortableDataTable
                    data={borrowedAssets?.data ?? []}
                    columns={columnsAssets}
                    currentPage={0}
                    paginationAuto={false}
                    totalCount={borrowedAssets?.data?.length}
                    enableFilters
                  />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Stack>
          <Stack spacing="3">
            <Accordion allowMultiple>
              <AccordionItem
                marginY="5"
                borderColor="gray.600"
                border="1px"
                borderRadius="5">
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <FormattedMessage
                        id="orders.assetsreturned"
                        description="Assets returned attribute of an order"
                        defaultMessage="Assets returned"
                      />
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel>
                  <SortableDataTable
                    data={returnedAssets?.data ?? []}
                    columns={columnsAssets}
                    currentPage={0}
                    paginationAuto={false}
                    totalCount={returnedAssets?.data?.length}
                    enableFilters
                  />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default OrderModal

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal'
import { Button, FormControl, FormLabel, Input, Stack } from '@chakra-ui/react'
import { useToast } from '@chakra-ui/toast'
import React, { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { QrDimension } from './QRDimension.entity'
import { useCreateQrDimension, useUpdateQrDimension } from './queries'

export type QrDimensionModalProps = {
  qrDimension?: Partial<QrDimension> | undefined
  isOpen: boolean
  onClose: () => void
}

const QrDimensionModal = ({
  qrDimension,
  isOpen,
  onClose,
}: QrDimensionModalProps) => {
  const intl = useIntl()
  const toast = useToast()

  const createQrDimension = useCreateQrDimension()
  const updateQrDimension = useUpdateQrDimension()

  const newQrDimensionTitleMessage = intl.formatMessage({
    id: 'qr-dimensions.button.new',
    description: 'New QR dimension title message',
    defaultMessage: 'New QR dimension',
  })

  const createdSuccessfulMessage = intl.formatMessage({
    id: 'qr-dimensions.createdSuccessfully',
    description: 'QR dimension created successfully toast',
    defaultMessage: 'QR dimension created successfully',
  })

  const updatedSuccessfulMessage = intl.formatMessage({
    id: 'qr-dimensions.updatedSuccessfully',
    description: 'QR dimension updated successfully toast',
    defaultMessage: 'QR dimension updated successfully',
  })

  const title = qrDimension ? qrDimension.id : newQrDimensionTitleMessage

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<QrDimension>({ defaultValues: qrDimension })

  const onSubmit: SubmitHandler<QrDimension> = (data) => {
    if (qrDimension) {
      updateQrDimension.mutate(data)
    } else {
      createQrDimension.mutate(data)
    }
  }

  useEffect(() => {
    if (createQrDimension.isSuccess || updateQrDimension.isSuccess) {
      toast({
        title: updateQrDimension.isSuccess
          ? updatedSuccessfulMessage
          : createdSuccessfulMessage,
        status: 'success',
        isClosable: true,
        position: 'top-right',
      })
      onClose()
    } else if (createQrDimension.isError || updateQrDimension.isError) {
      toast({
        title: 'Error updating or creating QR Dimension, try again later',
        status: 'error',
        isClosable: true,
        position: 'top-right',
      })
    }
  }, [
    createQrDimension.isSuccess,
    updateQrDimension.isSuccess,
    createQrDimension.isError,
    updateQrDimension.isError,
  ])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Stack spacing="3">
              <FormControl
                id="name"
                isRequired
                isInvalid={errors.name !== undefined}>
                <FormLabel>
                  <FormattedMessage
                    id="qr-dimensions.name"
                    description="Name attribute of an QR dimension"
                    defaultMessage="Name"
                  />
                </FormLabel>
                <Input {...register('name')}></Input>
              </FormControl>
              <FormControl
                id="width"
                isRequired
                isInvalid={errors.width !== undefined}>
                <FormLabel>
                  <FormattedMessage
                    id="qr-dimensions.width"
                    description="Width attribute of an QR dimension"
                    defaultMessage="Width"
                  />
                </FormLabel>
                <Input {...register('width')}></Input>
              </FormControl>
              <FormControl
                id="height"
                isRequired
                isInvalid={errors.height !== undefined}>
                <FormLabel>
                  <FormattedMessage
                    id="qr-dimensions.height"
                    description="Height attribute of an QR dimension"
                    defaultMessage="Height"
                  />
                </FormLabel>
                <Input {...register('height')}></Input>
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              colorScheme="teal"
              fontSize="md"
              isLoading={
                createQrDimension.isLoading || updateQrDimension.isLoading
              }>
              <FormattedMessage
                id="qr-dimensions.button.save"
                description="QR dimensions save button"
                defaultMessage="Save"
              />
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default QrDimensionModal

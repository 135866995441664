import { Center, Heading, Spinner } from '@chakra-ui/react'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import PaymentsList from '../features/payments/PaymentsList'
import { usePaymentList } from '../features/payments/queries'

const Payments = () => {
  const { data, isLoading } = usePaymentList(0, 100)

  return (
    <>
      <Heading marginBottom="10">
        <FormattedMessage
          id="payments"
          description="Payment screen title"
          defaultMessage="Payments"
        />
      </Heading>
      {isLoading ? (
        <Center>
          <Spinner size="lg" />
        </Center>
      ) : (
        <PaymentsList payments={data?.data ?? []} />
      )}
    </>
  )
}

export default Payments

import { useDisclosure } from '@chakra-ui/hooks'
import moment from 'moment'
import React, { useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Column } from 'react-table'
import { Filter } from '../common/Filter.entity'
import SortableDataTable, {
  NumberColumnFilter,
  SelectColumnFilter,
} from '../common/SortableDataTable'
import { Role } from '../users/User.entity'
import { Action, AssetLog } from './AssetLog.entity'
import AssetLogModal from './AssetLogModal'
import { useAssetLogCount, useAssetLogList } from './queries'

const AssetLogsList = () => {
  const { isOpen, onOpen } = useDisclosure()
  const [selectedAssetLog, setSelectedAssetLog] = useState<AssetLog>()
  const [filters, setFilters] = useState<Filter[]>([])
  const [pageSize, setPageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)
  const assetLogCount = useAssetLogCount(filters)
  const intl = useIntl()

  const { data, isLoading } = useAssetLogList(filters, currentPage, pageSize)

  const actions = Object.values(Action)
  const roles = Object.values(Role)

  const setPage = useCallback(
    (pageIndex: number, pageSize: number) => {
      setCurrentPage(pageIndex)
      setPageSize(pageSize)
    },
    [currentPage, pageSize]
  )

  const columns = useMemo<Column<AssetLog>[]>(
    () => [
      {
        Header: intl.formatMessage({
          id: 'assetlogs.date',
          description: 'CreatedAt attribute of a assetlog',
          defaultMessage: 'date',
        }),
        accessor: (al) => {
          let d = moment(al.createdAt)
          return `${d.format('D/M/YY')} (${d.format('H:mm:ss')})`
        },
        id: 'date',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: intl.formatMessage({
          id: 'assetlogs.action',
          description: 'Action attribute of a assetlog',
          defaultMessage: 'Action',
        }),
        Filter: SelectColumnFilter,
        listOptions: actions,
        accessor: 'action',
      },
      {
        Header: intl.formatMessage({
          id: 'assetlogs.number.assets',
          description: 'Number of assets label attribute of a assetlog',
          defaultMessage: 'Number of assets',
        }),
        Filter: NumberColumnFilter,
        accessor: (al) => al.assets.length,
        id: 'numberAssets',
      },
      {
        Header: intl.formatMessage({
          id: 'assetlogs.source',
          description: 'Source label attribute of a assetlog',
          defaultMessage: 'Source',
        }),
        accessor: (al) => (al.source.userId ? al.source.userId.email : ''),
        id: 'userSource',
      },
      {
        Header: intl.formatMessage({
          id: 'assetlogs.source.role',
          description: 'Role label attribute of a source assetlog',
          defaultMessage: 'Role',
        }),
        Filter: SelectColumnFilter,
        accessor: (al) => al.source.role,
        listOptions: roles,
        id: 'roleSource',
      },
      {
        Header: intl.formatMessage({
          id: 'assetlogs.target',
          description: 'Target label attribute of a assetlog',
          defaultMessage: 'Target',
        }),
        accessor: (al) => al.target.userId.email,
        id: 'userTarget',
      },
      {
        Header: intl.formatMessage({
          id: 'assetlogs.target.role',
          description: 'Role label attribute of a target assetlog',
          defaultMessage: 'Role',
        }),
        Filter: SelectColumnFilter,
        accessor: (al) => al.target.role,
        listOptions: roles,
        id: 'roleTarget',
      },
    ],
    []
  )

  const detailAssetLog = (assetLog: AssetLog) => {
    setSelectedAssetLog(assetLog)
    onOpen()
  }

  const onClose = () => {
    setSelectedAssetLog(undefined)
  }

  return (
    <>
      <SortableDataTable
        data={data?.data ?? []}
        columns={columns}
        loading={isLoading}
        setPage={setPage}
        setFilters={setFilters}
        currentPage={currentPage}
        onRowSelected={detailAssetLog}
        totalCount={assetLogCount.data}
        sizePage={pageSize}
        fontSize="smaller"
        paginationAuto={true}
        filterAuto={true}
        enableFilters
      />
      {selectedAssetLog ? (
        <AssetLogModal
          assetLog={selectedAssetLog}
          isOpen={isOpen}
          onClose={onClose}
        />
      ) : null}
    </>
  )
}

export default AssetLogsList

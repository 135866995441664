import { useQuery } from 'react-query'
import { Filter } from '../common/Filter.entity'
import PaginatedResult from '../common/PaginatedResult.entity'
import useXicloClient, { XicloResource } from '../common/useXicloClient'
import { Order } from './Order.entity'

export const ordersKeys = {
  all: ['orders'] as const,
  count: (filters: Filter[]) =>
    [...ordersKeys.all, 'count', { filters }] as const,
  lists: () => [...ordersKeys.all, 'list'] as const,
  list: (filters: Filter[], page: number, limit: number) =>
    [...ordersKeys.lists(), { filters, page, limit }] as const,
}

const findAll = async (filters: Filter[], page: number, limit: number) => {
  const offset = limit * page

  let params: { [key: string]: any } = {
    limit,
    offset,
    filters,
  }

  filters.forEach(({ id, value }) => {
    params[id] = value
  })

  const client = await useXicloClient(XicloResource.Orders)
  const { data } = await client.get<PaginatedResult<Order>>('', {
    params,
  })

  return data
}

export const useOrdersList = (filters: Filter[], page: number, limit = 10) => {
  return useQuery<PaginatedResult<Order>, Error>(
    ordersKeys.list(filters, page, limit),
    () => findAll(filters, page ?? 0, limit),
    { keepPreviousData: true }
  )
}

const count = async (filters: Filter[]) => {
  let params: { [key: string]: any } = {}

  filters.forEach(({ id, value }) => {
    params[id] = value
  })

  const client = await useXicloClient(XicloResource.Orders)
  const { data } = await client.get<number>('/count/total', {
    params,
  })

  return data
}

export const useOrderCount = (filters: Filter[]) => {
  const countAllQuery = useQuery<number, Error>(ordersKeys.count(filters), () =>
    count(filters)
  )

  return countAllQuery
}

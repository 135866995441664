import { useQuery } from 'react-query'
import { AssetLocation } from '../assets/Asset.entity'
import useXicloClient, { XicloResource } from '../common/useXicloClient'
import { AssetByCurrentLocation } from './AssetByCurrentLocation.entity'

export const indicatorsKeys = {
  all: ['indicators'] as const,
  list: (type: string, reference: string) =>
    [...indicatorsKeys.all, { type, reference }] as const,
}

const calculateAssetsByBranch = async (type: string, reference: string) => {
  const client = await useXicloClient(XicloResource.Assets)
  const { data } = await client.get<AssetByCurrentLocation>(
    'count/current-location',
    {
      params: { type, reference },
    }
  )

  return data
}

export const useCalculateAssetsByBranch = (
  type: AssetLocation,
  reference: string
) => {
  return useQuery<AssetByCurrentLocation, Error>(
    indicatorsKeys.list(type, reference),
    () => calculateAssetsByBranch(type, reference),
    { keepPreviousData: false }
  )
}
